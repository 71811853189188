// EXTERNAL LIBRARIES
import React, { useContext, useState } from "react";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import IconSearch from "@cx/ui/Icons/IconSearch";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
// STYLES
import "./archive-ro-history.scss";
// SERVICES
import { getRoHistory } from "../../services/ro-history-search.service";
import { AppContext } from "../../../../state/app-context";
// CUSTOM COMPONENTS
import ArchivedRepairOrdersGrid from "./archived-repair-orders-grid.component";
import { formatHistoricalRepairOrders } from "./archived-repair-orders-grid.util";
import { mockRoHistoryRecord } from "../../utils/rohistory-mockdata";
const ArchiveRoHistory = () => {
  const appContext = useContext(AppContext);
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const [isValidSearch, setIsValidSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [roHistory, setRoHistory] = useState([]);
  const [searchStatus, setSearchStatus] = useState("initial"); // "initial" | "loading" | "success" | "error"
  const [noRowsOverlayComponentParams, setNoRowsOverlayComponentParams] =
    useState(null);
  /**
   * Handles changes in the search input value.
   * Don't use isValid param since there is an error when clear button is clicked. It always return isValid as true
   * https://ghe.coxautoinc.com/CoxAuto-UI/common-experience/blob/af5c0e67684e54e89164d33ce7aa8fc7ffa17f76/packages/ui/src/components/TextInput/TextInput.js#L146
   * @param {{target: {name, value}}} cxEvent
   */
  const onChangeSearch = cxEvent => {
    const searchValue = cxEvent.target.value;
    setSearchText(cxEvent.target.value);
    setIsValidSearch(searchValue.length > 3);
  };
  /**
   * Fetches a list of historical repair orders
   * @return {Promise<void>}
   */
  const onFetchRoHistory = async () => {
    try {
      setSearchStatus("loading");
      const response = await getRoHistory(searchText, dealerCode);
      // TODO (evillegas) Check if the endpoint is returning correct data and refactor it if it is necessary
      if (response.success && response.historicalRepairOrders) {
        setRoHistory(
          formatHistoricalRepairOrders(response.historicalRepairOrders)
        );
        setNoRowsOverlayComponentParams({
          noRowsMessage: "Zero results found.",
          apiStatus: "NODATA"
        });
        setSearchStatus("success");
      } else {
        // TODO: workaround to use mock RO history data
        // Delete this code block after testing - Start
        setRoHistory(
          formatHistoricalRepairOrders(
            mockRoHistoryRecord.historicalRepairOrders
          )
        );
        setNoRowsOverlayComponentParams({
          noRowsMessage: "Zero results found.",
          apiStatus: "NODATA"
        });
        setSearchStatus("success");
        console.log("Fetching historical repair orders failed - load mockdata");
        // End of code block
        // throw new Error("Fetching historical repair orders failed.");
      }
    } catch (e) {
      console.error(e);
      setNoRowsOverlayComponentParams({
        noRowsMessage: "Search failed, please try again in a few minutes.",
        apiStatus: "APIERROR"
      });
      setSearchStatus("error");
    }
  };
  /**
   * To trigger search when Enter is pressed. TODO: Consider adding this functionality to CX
   * @param event the keydown event
   */
  const handleKeyDown = event => {
    if (event.key === "Enter" && isValidSearch && searchStatus !== "loading") {
      onFetchRoHistory();
    }
  };

  const renderSearchContent = () => {
    switch (searchStatus) {
      case "loading":
        return (
          <LoadingIndicator
            htmlId="archive-ro-loading-indicator"
            size="large"
          />
        );
      case "success":
      case "error":
        return (
          <ArchivedRepairOrdersGrid
            repairOrders={roHistory}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="archive-ro-container">
      <div className="search-content">
        <TextInput
          className="search-input"
          appendChild={
            <Button
              htmlId="search-archive-ro-btn"
              disabled={!isValidSearch || searchStatus === "loading"}
              className="search-btn"
              onClick={onFetchRoHistory}
            >
              <IconSearch htmlId="Search-Icon" className="search-icon" />
            </Button>
          }
          htmlId="search-archive-ro-input"
          label="Search by VIN, RO #, or Customer Information"
          name="textInputAppendWithClear"
          onChange={onChangeSearch}
          onKeyDown={handleKeyDown}
          value={searchText}
          clearButton="icon"
          autoFocus
        />
      </div>
      {renderSearchContent()}
    </div>
  );
};

export default ArchiveRoHistory;
