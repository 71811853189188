import _isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { partStatus, stockLabels } from "../constants/parts.constants";

const PartFields = [
  "quoteServicePartId",
  "description",
  "oemPartNumber",
  "strippedPartNumber",
  "extPartId",
  "partPrice",
  "lastModTime",
  "lastModByUserId",
  "dmsOpcode",
  "partType",
  "adjustedUom",
  "adjustedQuantity",
  "oilType",
  "unitPrice",
  "dtDmsPartCode",
  "position",
  "manufacturer",
  "notes",
  "lifecycleState",
  "emergencyPurchase",
  "unitOfMeasure",
  "fluidQuantity",
  "tags",
  "displayOrder",
  "taxCode",
  "discounts",
  "fees",
  "approverUser",
  "approver",
  "priceSource",
  "isCorePart",
  "coreReturnId",
  "typeOfPurchase"
];

const freezeObject = obj => {
  const objKeys = Object.keys(obj);
  for (const objKey of objKeys) {
    if (_isObject(obj[objKey])) {
      obj[objKey] = Object.freeze(obj[objKey]);
    }
  }
  return Object.freeze(obj);
};

const transformValueIfUndefined = value => {
  return !value || value === "" ? "0" : value.toString();
};
// @todo: this fun() used to calci totalprice for all selected parts show as label in parts grid header
const calculateTotalPrice = parts => {
  const totalPrice = parts.reduce((acc, next) => {
    const currentPrice = !next.unitPrice ? 0 : next.unitPrice * next.quantity;
    return acc + currentPrice;
  }, 0);
  return totalPrice;
};

const findPart = (parts, selectedPart) => {
  const matches = parts.filter(part => {
    return part.partId === selectedPart.partId;
  });
  const transformPart = matches.length > 0 ? matches[0] : null;
  if (!isEmpty(transformPart)) {
    transformPart.selected = false;
    transformPart.rowId = transformPart.partId;
    transformPart.priceSource = "MSRP";
  }
  return transformPart;
};

// This regex to allow alpha-numeric with space
function validateAlphaNumSpace(name, value) {
  let error = "";
  // const regex = /^([a-zA-Z0-9 *]){0,}$/i;
  const regex = /^[a-zA-Z0-9]([a-zA-Z0-9 _\-*]){0,}$/i;
  if (value && !regex.test(value)) {
    error = `${name} is invalid`;
  }
  return error;
}

// This regex to allow alpha-numeric with space
function validatePartNumber(name, value) {
  let error = "";
  // const regex = /^([a-zA-Z0-9 *]){0,}$/i;
  const regex = /^[a-zA-Z0-9@#$]([a-zA-Z0-9 _\-@#$*]){0,}$/i;
  if (value && !regex.test(value)) {
    error = `${name} is invalid`;
  }
  return error;
}

function toEmptyStringIfUndefined(value) {
  return isEmpty(value) || value === undefined ? "" : value.toString();
}

function isDifferentValue(newVal, oldVal) {
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}

const generateRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generatePartId = () => {
  return generateRandomIntegerInRange(100, 9999);
};

const stripPropertiesFromObjectArray = (objArr, propArr) => {
  const objArrayCopy = cloneDeep(objArr);
  const newObjArr = objArrayCopy.map(obj => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (propArr.includes(key)) {
        delete obj[key];
      }
    });
    return obj;
  });
  return newObjArr;
};

/**
 * It returns the Y/N for isCorePart.
 * Notes that in the future, it will need to be return true/false as boolean.
 *
 * @param {*} isCorePart
 * @returns Y/N or in the future true/false
 */
function getIsCorePart(isCorePart) {
  return isCorePart === "Y" || isCorePart === true ? true : false;
}

/**
 * It returns a boolean true/false assuming that isCorePart can be a string or a boolean.
 *
 * @param {*} isCorePart
 * @returns boolean true/false
 */
function isItCorePart(isCorePart) {
  // TDB: once isCorePart parameter is converted to boolean, simply just return it.
  return isCorePart === "Y" || isCorePart === true;
}

function getPartAvailabilityLabel(part) {
  const { approver, isCorePart, inStock } = part;
  if (approver) {
    return "";
  }
  if (isItCorePart(isCorePart) || typeof inStock !== "boolean") {
    return "N/A";
  }
  return inStock ? stockLabels.IN_STOCK : stockLabels.OUT_OF_STOCK;
}

function isPartInstock(quantityNeeded, quantityAvailable) {
  if (!hasQuantityAvailable(quantityAvailable)) {
    return null;
  }
  if (quantityAvailable === 0 && Number(quantityNeeded) === 0) return false;
  return quantityAvailable >= Number(quantityNeeded);
}
// common util called for editing quote service, modify link flow from summary page
function buildPart(savedPart, lookupPart) {
  const {
    approver,
    extPartId,
    description: partName,
    adjustedQuantity: quantity,
    unitPrice,
    priceSource
  } = savedPart;

  const partId = isEmpty(lookupPart)
    ? !extPartId
      ? generatePartId().toString()
      : extPartId.toString()
    : lookupPart.partId;
  return {
    ...(!isEmpty(lookupPart) ? { ...lookupPart, ...savedPart } : savedPart),
    approver,
    partId,
    rowId: Number(partId),
    extPartId: !extPartId ? Number(partId) : extPartId,
    quoteServicePartId: savedPart?.quoteServicePartId || null,
    partName,
    quantity,
    unitPrice,
    priceSource,
    ...(priceSource === "MANUAL" && {
      partPriceSource: priceSource
    })
  };
}

// This method will eliminate non PartDto properties from given object/array
const filterToPartDtoFields = (objArr, validPartDtoFields) => {
  const objArrayCopy = cloneDeep(objArr);
  const newObjArr = objArrayCopy?.map(obj => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (!validPartDtoFields.includes(key)) {
        delete obj[key];
      }
    });
    return obj;
  });
  return newObjArr?.sort((part1, part2) => {
    if (!part1.quoteServicePartId) return 1;
    if (!part2.quoteServicePartId) return 1;
    return part1.quoteServicePartId - part2.quoteServicePartId;
  });
};

const isPartFieldEditable = (part, field) => {
  switch (field) {
    case "quantity":
      return (
        !part.approver &&
        !part.coreReturnId &&
        !part.purchaseOrderNo &&
        !part.lifecycleState === partStatus.OPEN &&
        !part.lifecycleState === partStatus.BACKORDERED
      );
    case "unitPrice":
      return !part.approver && !part.coreReturnId;
    default:
      break;
  }
  return true;
};

const hasQuantityAvailable = quantityAvailable => {
  return !(isNull(quantityAvailable) || isUndefined(quantityAvailable));
};

export {
  PartFields,
  buildPart,
  filterToPartDtoFields,
  hasQuantityAvailable,
  isItCorePart,
  isPartInstock,
  isPartFieldEditable,
  getIsCorePart,
  generatePartId,
  getPartAvailabilityLabel,
  transformValueIfUndefined,
  calculateTotalPrice,
  freezeObject,
  findPart,
  validateAlphaNumSpace,
  validatePartNumber,
  isDifferentValue,
  stripPropertiesFromObjectArray
};
