// useEffect to load customer, vehicle or quoteId then only it renders summary page
import React, { useContext } from "react";
import { func, object } from "prop-types";
import { AppContext } from "../../../state/app-context";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import EditServiceModule from "../../../EditServiceModule";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import globalOperationsService from "../services/global-operations.service";
import { getPayloadForPartsInventoryUsingQuoteService } from "../utils/data-util";
import axiosService from "../../../api/xmmAxios";
import { writeLogEntry } from "../../../services/log.service";
const EditServiceHOC = props => {
  const {
    currentEditingService,
    localeStrings,
    rawOperationDetails,
    onCancelHandler,
    onSaveHandler,
    onSaveAnotherHandler,
    onServiceChange
  } = props;
  const appContext = useContext(AppContext);
  const { dealer, dealerProperties } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const { vehicle, partsPricingAndInventory, debugMode, userPermissions } =
    state;
  const payTypes = state.payTypes;
  const serviceTypesList = state.serviceTypes;
  const vendorList = state.vendorList;
  const serviceContracts = state.serviceContracts;
  const payTypeSubTypes = state.payTypeSubTypes;
  const payTypeCostAllocationTypes = state.costAllocationTypes;

  useComponentDidMount(() => {
    axiosService.setupAxios(appContext);
    async function loadDeps() {
      // get dms parts for uniifed response format
      await asyncHandleGetPartsInventoryCommon(
        currentEditingService,
        currentEditingService.payTypeCode
      );
    }
    loadDeps();
  });

  // @todo-edit: event paytype - trigger parent handler to pass paytype value - verify this callback?
  const onUpdatePartsByPaytype = (payTypeCode, editedService) => {
    // @todo-edit: this call when fired from edit container for summary edit,
    // @todo-fix: read dynamic service type, paytype, service contract fields from editedService, to be updated to currentEditingService
    asyncHandleGetPartsInventoryCommon(
      {
        ...currentEditingService,
        parts: editedService.parts,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription,
        serviceContract: editedService?.serviceContract
      },
      payTypeCode
    );
  };
  const onUpdatePartsByServiceType = (serviceTypeCode, editedService) => {
    // @todo-edit: this call when fired from edit container for summary edit
    // @todo-fix: read dynamic service type, paytype, service contract fields from editedService, to be updated to currentEditingService
    asyncHandleGetPartsInventoryCommonServiceType(
      {
        ...currentEditingService,
        parts: editedService.parts,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceContract: editedService?.serviceContract
      },
      serviceTypeCode
    );
  };
  const onUpdatePartsByServiceContract = (serviceContract, editedService) => {
    // @todo-edit: this call when fired from edit container for summary edit
    // @todo-fix: read dynamic service type, paytype, service contract fields from editedService, to be updated to currentEditingService
    asyncHandleGetPartsInventoryCommonServiceContract(
      {
        ...currentEditingService,
        parts: editedService.parts,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription
      },
      serviceContract
    );
  };
  // @todo: parts API handler to return parts using open track api when paytype changed
  // refactor same logic like handleGetPartsInventoryCommon( same argu), use same util methods
  // use currentEditingService to create partsAPI payload
  const asyncHandleGetPartsInventoryCommon = async (
    currentEditingService,
    payType
  ) => {
    let partsPricingAndInventory = [];
    const { vehicle, customer } = state;
    try {
      const payTypeCode = payType || "";
      const postParams = getPayloadForPartsInventoryUsingQuoteService(
        currentEditingService,
        vehicle,
        payTypeCode,
        currentEditingService?.operationSource,
        // Note: send ccid when paytype = C only for DMS+, DTDMS dealers for Quote/RO
        payTypeCode == "C" ? customer?.commonConsumerId : "",
        currentEditingService?.serviceTypeCode || "MR",
        payTypeCode == "S"
          ? currentEditingService?.serviceContract?.internalProductId
          : null,
        payTypeCode == "S"
          ? currentEditingService?.serviceContract?.vendorId
          : null
      );
      if (currentEditingService?.parts?.length > 0)
        partsPricingAndInventory =
          await globalOperationsService.getPartsPricingAndInventory(
            null,
            postParams
          );
    } catch (error) {
      partsPricingAndInventory = [];
      console.log(
        "EditServiceHOC Call to opentrack API failed with error",
        error
      );
    }
    console.log(
      "asyncHandleGetPartsInventoryCommon parts",
      partsPricingAndInventory
    );
    dispatch({
      type: Actions.SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE,
      payload: partsPricingAndInventory
    });
    return partsPricingAndInventory;
  };

  const asyncHandleGetPartsInventoryCommonServiceType = async (
    currentEditingService,
    serviceTypeCode
  ) => {
    let partsPricingAndInventory = [];
    const { vehicle, customer } = state;
    try {
      const payTypeCode = currentEditingService.payTypeCode || "";
      const postParams = getPayloadForPartsInventoryUsingQuoteService(
        currentEditingService,
        vehicle,
        payTypeCode,
        currentEditingService?.operationSource,
        // Note: send ccid when paytype = C only for DMS+, DTDMS dealers for Quote/RO
        payTypeCode == "C" ? customer?.commonConsumerId : "",
        serviceTypeCode || "MR",
        payTypeCode == "S"
          ? currentEditingService?.serviceContract?.internalProductId
          : null,
        payTypeCode == "S"
          ? currentEditingService?.serviceContract?.vendorId
          : null
      );
      partsPricingAndInventory =
        await globalOperationsService.getPartsPricingAndInventory(
          null,
          postParams
        );
    } catch (error) {
      partsPricingAndInventory = [];
      console.log(
        "EditServiceHOC Call to opentrack API failed with error",
        error
      );
    }
    console.log(
      "asyncHandleGetPartsInventoryCommonServiceType parts",
      partsPricingAndInventory
    );
    dispatch({
      type: Actions.SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE,
      payload: partsPricingAndInventory
    });
    return partsPricingAndInventory;
  };

  const asyncHandleGetPartsInventoryCommonServiceContract = async (
    currentEditingService,
    serviceContract
  ) => {
    let partsPricingAndInventory = [];
    const { vehicle, customer } = state;
    try {
      const payTypeCode = currentEditingService.payTypeCode || "";
      const postParams = getPayloadForPartsInventoryUsingQuoteService(
        currentEditingService,
        vehicle,
        payTypeCode,
        currentEditingService?.operationSource,
        // Note: send ccid when paytype = C only for DMS+, DTDMS dealers for Quote/RO
        payTypeCode == "C" ? customer?.commonConsumerId : "",
        currentEditingService?.serviceTypeCode || "MR",
        payTypeCode == "S" ? serviceContract?.internalProductId : null,
        payTypeCode == "S" ? serviceContract?.vendorId : null
      );
      partsPricingAndInventory =
        await globalOperationsService.getPartsPricingAndInventory(
          null,
          postParams
        );
    } catch (error) {
      partsPricingAndInventory = [];
      console.log(
        "EditServiceHOC Call to opentrack API failed with error",
        error
      );
    }
    console.log(
      "asyncHandleGetPartsInventoryCommonServiceContract parts",
      partsPricingAndInventory
    );
    dispatch({
      type: Actions.SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE,
      payload: partsPricingAndInventory
    });
    return partsPricingAndInventory;
  };

  return (
    <EditServiceModule
      commonConsumerId={state.quoteSummary?.customer?.commonConsumerId ?? ""}
      debugMode={debugMode}
      appType={appContext.appType}
      userPermissions={userPermissions}
      // @Exceptional case: axios instance passed to call rest api's inside partsLookup or EditService modules for dynamic data callback
      axiosInstance={axiosService}
      rawOperationDetails={rawOperationDetails} // this props always be raw response of unified rest API even for summary case
      quoteSummary={state.quoteSummary}
      currentEditingService={currentEditingService} // for summary case, this prop is used to update service in editService context
      service={null} // for summary case - this props willbe null always
      vehicle={vehicle}
      localeStrings={localeStrings}
      writeLogEntry={writeLogEntry}
      onCancelHandler={onCancelHandler}
      onSaveHandler={onSaveHandler}
      onSaveAnotherHandler={onSaveAnotherHandler}
      onServiceChange={onServiceChange}
      payTypes={payTypes}
      serviceTypes={serviceTypesList}
      vendorList={vendorList}
      serviceContracts={serviceContracts}
      payTypeSubTypes={payTypeSubTypes}
      costAllocationTypes={payTypeCostAllocationTypes}
      partsPricingAndInventory={partsPricingAndInventory}
      onChangePaytype={onUpdatePartsByPaytype}
      onChangeServiceType={onUpdatePartsByServiceType}
      onChangeServiceContract={onUpdatePartsByServiceContract}
      dealerCode={dealer.dealerCode}
      dealerProperties={dealerProperties}
      confirmationId={state.quoteSummary.confirmationId}
    />
  );
};

EditServiceHOC.propTypes = {
  currentEditingService: object.isRequired,
  rawOperationDetails: object.isRequired,
  service: object,
  localeStrings: object.isRequired,
  onCancelHandler: func.isRequired,
  onSaveHandler: func.isRequired,
  onServiceChange: func.isRequired,
  onChangePaytype: func.isRequired,
  onChangeServiceType: func.isRequired,
  onChangeServiceContract: func.isRequired
};

EditServiceHOC.defaultProps = {
  currentEditingService: null,
  service: null,
  localeStrings: {},
  rawOperationDetails: {},
  payTypes: [],
  serviceTypes: [],
  vendorList: [],
  payTypeSubTypes: [],
  costAllocationTypes: [],
  partsPricingAndInventory: [],
  onCancelHandler: () => {},
  onSaveHandler: () => {},
  onSaveAnotherHandler: () => {},
  onServiceChange: () => {},
  onChangePaytype: () => {},
  onChangeServiceType: () => {},
  onChangeServiceContract: () => {}
};

export default EditServiceHOC;
