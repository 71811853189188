import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useEditServiceContext } from "../../state/edit-service.context";

const TechSelectDropdown = props => {
  const { state } = useEditServiceContext();
  const techniciansList = state.technicians || [];
  const [formattedTechList, setFormattedTechList] = useState([]);
  const [selectedTech, setSelectedTech] = useState(
    props?.params?.data?.techName || null
  );

  const {
    params,
    getTechnicianUserNameFormatter,
    getTechnicianUserName,
    buildPersonOptions,
    onCellValueChanged,
    canUpdateExistingTechRowTechnician
  } = props;

  const isNewRow = !params.data?.techTimeId;
  const dropdownEnable = isNewRow || canUpdateExistingTechRowTechnician;
  useEffect(() => {
    if (techniciansList?.length > 0) {
      const list = buildPersonOptions(techniciansList);
      setFormattedTechList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techniciansList]);

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const onClickDisabled =
    dropdownEnable === false ? hide : dropdownEnable && !visible ? show : hide;

  const dropDownContent = (
    <div className="tech-select-container">
      <div>
        {formattedTechList?.length > 0 ? (
          formattedTechList?.map(tech => {
            return (
              <div className="tech-select-item" key={tech?.techId}>
                <span
                  className="tech-item-label"
                  onClick={() => {
                    hide();
                    setSelectedTech(tech);
                    params.data.techName = tech;
                    onCellValueChanged(params);
                  }}
                >
                  {getTechnicianUserNameFormatter(tech)}
                </span>
              </div>
            );
          })
        ) : (
          <div className="tech-select-item" key="null">
            <span className=" tech-item-label disabled">
              No technician available
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom-start"
    >
      <span className="csr-tech-select-dropdown-span" onClick={onClickDisabled}>
        {getTechnicianUserName(selectedTech, params?.data?.isPrimary)}
      </span>
    </Tippy>
  );
};

export default TechSelectDropdown;

TechSelectDropdown.propTypes = {
  params: PropTypes.object,
  getTechnicianUserNameFormatter: PropTypes.func,
  getTechnicianUserName: PropTypes.func,
  buildPersonOptions: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  canUpdateExistingTechRowTechnician: PropTypes.bool
};
