import React, { useState } from "react";
import "./csr-details.scss";
import AddTechnician from "../../components/csr/add-technician.component";
import Row from "@cx/ui/Row";
import AccordionGroup from "@cx/ui/AccordionGroup";
import WarrantyFreeFieldsComponent from "../../components/csr/warranty-free-fields.component";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import WarrantyExpenseComponent from "../../components/csr/warranty-expense.components";
import {
  Actions,
  useEditServiceContext
} from "../../state/edit-service.context";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

const CSRWarrantyDetails = ({ dealerCode, quoteId }) => {
  const { dispatch, state } = useEditServiceContext();

  const [warrantyPayload, setWarrantyPayload] = useState(
    state?.service?.warranty || null
  );

  const updateWarrantyPayload = payload => {
    let finalPayload = cloneDeep(warrantyPayload);
    finalPayload = {
      ...finalPayload,
      warrantyDetails: payload
    };
    dispatch({
      type: Actions.SET_WARRANTY_DETAILS,
      payload: finalPayload
    });
    // * checking whether there is change in warranty Data
    let changed = !isEqual(
      finalPayload,
      state?.currentEditingService?.warranty
    );
    if (
      state?.currentEditingService?.warranty?.warrantyDetails === null &&
      finalPayload?.warrantyDetails?.expenseCodes?.length === 0
    )
      changed = false;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "warranty",
        value: changed
      }
    });
    setWarrantyPayload(finalPayload);
  };
  return (
    <AccordionGroup.Container htmlId="accordionGroupSingle" independent>
      <AccordionGroup.Item
        header="Warranty details"
        eventKey="1"
        htmlId="accordionGroupSingle1"
        className="test-class"
        defaultExpanded
      >
        <Row className="m-t-md">
          <WarrantyFreeFieldsComponent
            warrantyPayload={warrantyPayload?.warrantyDetails}
            updateWarrantyPayload={updateWarrantyPayload}
            dealerCompanies={state?.dealerCompanies}
          />
        </Row>
        <Row className="m-t-md m-l-0 m-r-0">
          <Tabs htmlId="tabsDefault" className="warranty-tabs">
            <Tab label="Correction codes & technicians" htmlId="item1">
              <Row className="m-t-md m-l-0 m-r-0">
                <AddTechnician dealerCode={dealerCode} quoteId={quoteId} />
              </Row>
            </Tab>
            {state?.dealerCompanies.includes("NISSANUSA") ? (
              <Tab label="Expense codes" htmlId="item2">
                <Row className="m-t-md m-l-0 m-r-0">
                  <WarrantyExpenseComponent
                    warrantyPayload={warrantyPayload?.warrantyDetails}
                    updateWarrantyPayload={updateWarrantyPayload}
                  />
                </Row>
              </Tab>
            ) : null}
          </Tabs>
        </Row>
      </AccordionGroup.Item>
    </AccordionGroup.Container>
  );
};

export default CSRWarrantyDetails;
