import React from "react";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import "./special-parts.scss";
import PropTypes from "prop-types";

const ParentRoBanner = ({ parentRoNumber, onRedirection, isPreRo }) => {
  const message = isPreRo
    ? "This Pre RO is a continuation of "
    : "This RO is a continuation of ";

  return (
    <Alert type="info" htmlId="parentRoBanner">
      <div className="special-parts-banner-content">
        <span>
          {message}
          <Button
            htmlId="parentRoNumberLink"
            buttonStyle="link"
            onClick={onRedirection}
          >
            {`RO ${parentRoNumber}`}
          </Button>
        </span>
      </div>
    </Alert>
  );
};

ParentRoBanner.propTypes = {
  parentRoNumber: PropTypes.number,
  onRedirection: PropTypes.func,
  isPreRo: PropTypes.bool
};

export default ParentRoBanner;
