import { roStatusOptions } from "../../../constants/app.constants";
import { paymentStatus } from "../../../constants/quote-status.constants";
import { QuoteServiceTypes } from "../../page-wrapper/constants/page-wrapper.constants";

/**
 * Transforms 3PP data to a quote summary format.
 * @param {Object} data - The data from the 3PP API response.
 * @returns {Object} The transformed quote summary.
 */
export const transform3PPToQuoteSummary = data => {
  const { roSeqId, roId, repairOrder } = data;
  const {
    status,
    hangtag,
    promisedDate,
    openDate,
    closeDate,
    customer,
    mileageIn,
    mileageOut,
    services,
    menu,
    payers,
    vehicle,
    eventLogs,
    transfers = []
  } = repairOrder;
  const quoteServices = menu ? buildMenuServices(menu, services) : services;
  console.log("quoteServices from menu", quoteServices);
  return {
    confirmationId: roSeqId.toString(),
    quoteId: roSeqId,

    // Header
    roNumber: +roId,
    quoteStatus: status ?? roStatusOptions.FINALIZED,
    hangTag: hangtag ?? "--",
    pickUpDateTime: promisedDate,
    transportation: getTransportation(repairOrder),

    // Sub Header
    advisor: getAdvisor(repairOrder),
    counterPartsPerson: getPartsPerson(repairOrder),
    checkedInDateTime: openDate,
    closedDateTime: closeDate,

    // Customer / Vehicle
    customer: getCustomer(customer),
    mileageIn: mileageIn.mileage,
    mileageOut: mileageOut.mileage,
    vehicle: getVehicle(vehicle),

    // Service Details
    quoteServices: quoteServices.map(getService),

    // Checkout Summary
    payers: getPayers(payers),
    eventLogs,
    transfers
  };
};

/**
 * Retrieves the transportation type.
 * @param {Object} param - An object containing transportation information.
 * @returns {Object} The transportation type details.
 */
const getTransportation = ({ transportationType }) => ({
  start: {
    transportType: transportationType
  }
});

/**
 * Retrieves advisor details.
 * @param {Object} param - An object containing advisor's information.
 * @returns {Object} Advisor's first and last name.
 */
const getAdvisor = ({ advisorFirstName, advisorLastName }) => ({
  firstName: advisorFirstName ?? "--",
  lastName: advisorLastName ?? "--"
});

/**
 * Retrieves parts counter person details.
 * @param {Object} param - An object containing parts counter person information.
 * @returns {Object} Parts counter person's first and last name.
 */
const getPartsPerson = ({
  partsCounterPersonFirstName,
  partsCounterPersonLastName
}) => ({
  firstName: partsCounterPersonFirstName ?? "--",
  lastName: partsCounterPersonLastName ?? "--"
});

/**
 * Retrieves customer details.
 * @param {Object} customer - The customer data.
 * @returns {Object} Transformed customer information.
 */
export const getCustomer = customer => {
  const { emails, phones } = customer;

  return {
    personId: customer.customerId ?? "123456789", // personId IS REQUIRED or this RO gets treated as SQ
    firstName: customer.name?.firstName ?? "",
    lastName: customer.name?.lastName || customer.name || "--",
    contactInfo: {
      phoneNumbers:
        phones && Array.isArray(phones) && phones.length > 0
          ? phones.reduce((prev, curr) => {
              if (!curr.phoneNumber) return prev; // Without a phoneNumber this item is unusable, skip it

              if (curr.type === "CELL") {
                // 3PP has a CELL type but CSR recognizes MOBILE
                prev["MOBILE"] = curr.phoneNumber;
              } else {
                // Sometimes type can be null. If we have a phoneNumber, set the type to HOME as fallback
                prev[curr.type ?? "HOME"] = curr.phoneNumber;
              }
              return prev;
            }, {})
          : {}
    },
    email: emails?.[0].email ?? "--"
  };
};

/**
 * Retrieves vehicle details.
 * @param {Object} vehicle - The vehicle data.
 * @returns {Object} Transformed vehicle information.
 */
export const getVehicle = vehicle => {
  const { description, vin, specifications } = vehicle;

  return {
    metaVehicleId: 987654321, // metaVehicleId IS REQUIRED or no vehicle details will render
    make: description?.make ?? "--",
    model: description?.model?.marketingName ?? "--",
    year: description?.year ?? "--",
    trim: description?.trim?.marketingName ?? "--",
    engineSize: specifications?.engineSize ?? "--",
    driveType: specifications?.driveType ?? "--",
    transmissionType: specifications?.transmissionType ?? "--",
    color: specifications?.colors?.exterior?.normalizedName,
    vin: vin ?? "--"
  };
};

/**
 * Builds menu services by combining menu inspections and matching services.
 * @param {Object} menu - The menu data containing inspections and details.
 * @param {Array} services - The array of services from the repair order.
 * @returns {Array} An array of transformed services including menu items.
 */
const buildMenuServices = (menu, services) => {
  // Safely map inspections and ensure it's an array
  const inspections =
    menu?.inspections?.map(({ ...rest }) => ({
      ...rest,
      serviceCategoryName: "Inspect"
    })) || [];
  console.log("RO level services", services);
  console.log("menu inspections", inspections);
  // Filter matching services based on IDs in menu.services
  const matchedMenuServices =
    services?.filter(service => menu?.services?.includes(service.id)) || [];
  console.log("matched MenuServices", matchedMenuServices);
  return [
    {
      ...menu?.details,
      serviceName: menu?.details?.name ?? "--",
      quoteServiceType: QuoteServiceTypes.MENU,
      menuServices: [
        ...inspections.map(getService),
        ...matchedMenuServices.map(getService)
      ]
    }
  ];
};

/**
 * Retrieves service details.
 * @param {Object} service - The service data.
 * @returns {Object} Transformed service information.
 */
export const getService = service => {
  const {
    payTypeCode,
    completionDate,
    name,
    sequenceNumber,
    retailAmount,
    finalPartsPrice,
    servicePrice,
    lineServicePrice,
    id,
    labor,
    parts,
    sublets,
    technicians,
    cause,
    concern,
    correction,
    serviceType,
    vendorName,
    warrantySubmissionState,
    asrNotes,
    notes,
    payTypeDescription,
    departmentSubType,
    costAllocationSubType,
    xtServiceType,
    discounts,
    fees,
    quoteServiceType
  } = service;
  return {
    serviceName: name ?? "--",
    serviceLineNumber: sequenceNumber ?? "--",
    // finalLaborPrice: retailAmount?.amount ?? labor?.laborAmount?.amount ?? 0,
    finalLaborPrice:
      (quoteServiceType === QuoteServiceTypes.MENU
        ? labor?.laborAmount?.amount ?? retailAmount?.amount
        : retailAmount?.amount ?? labor?.laborAmount?.amount) ?? 0,
    finalPartsPrice: finalPartsPrice?.amount ?? 0,
    servicePrice: servicePrice?.amount ?? 0,
    lineServicePrice: lineServicePrice?.amount ?? 0,
    extServiceId: id,
    labor: labor
      ? {
          laborTime: (labor.laborHours * 60).toString(), // convert to minutes
          laborPrice: labor.laborAmount?.amount ?? 0
        }
      : null,
    parts: getParts(parts),
    catalogFees: getCatalogFees(fees),
    catalogDiscounts: getCatalogDiscounts(discounts),
    sublets:
      sublets && Array.isArray(sublets) && sublets.length > 0
        ? sublets.map(sublet => {
            const { vendorName, poNumber, total } = sublet;
            return {
              vendor: vendorName ?? "--",
              poNumber: poNumber ?? "--",
              poPricing: {
                subletTotalCost: total?.amount ?? 0
              }
            };
          })
        : [],
    technicians: getTechnicians(technicians),
    cause,
    complaint: concern,
    correction,
    completedTime: completionDate,
    paymentStatus: paymentStatus.READY,
    warranty: {
      warrantySubmissionState
    },
    asrNotes,
    dealershipNotes: notes,
    payTypeCode,
    quoteRawService: {
      rawService: '{"defaultServiceType": "MR"}'
    },
    serviceTypeCode: serviceType?.code,
    vendorName,
    payTypeGroup: payTypeDescription, // This is supposed to be payTypeGroup but it does not match
    payTypeDescription,
    subType: {
      subType: departmentSubType
    },
    allocationSubType: {
      subType: costAllocationSubType
    },
    quoteServiceType: xtServiceType,
    ...(service?.menuServices && { menuServices: service?.menuServices }),
    ...(service?.quoteServiceType && {
      quoteServiceType: service?.quoteServiceType
    }),
    ...(service?.serviceCategoryName && {
      serviceCategoryName: service?.serviceCategoryName
    })
  };
};

/**
 * Retrieves technician details.
 * @param {Array} technicians - The array of technician data.
 * @returns {Array} Transformed technician information.
 */
const getTechnicians = technicians => {
  if (!technicians) return [];

  return technicians
    .filter(
      ({ technicianId, firstName, lastName }) =>
        technicianId || firstName || lastName
    )
    .map(
      ({
        firstName,
        lastName,
        flagTime,
        duration,
        laborRate,
        workType,
        status
      }) => ({
        techUser: {
          firstName,
          lastName
        },
        flagTimeEnd: flagTime?.toString() || null,
        actualTimeDuration: duration || 0,
        laborRateCode: laborRate || null,
        workType: workType || null,
        status: status || null
      })
    );
};

/**
 * Retrieves part details.
 * @param {Array} parts - The array of part data.
 * @returns {Array} Transformed part information.
 */
const getParts = parts => {
  if (!parts) return [];

  return parts.map(
    ({
      partType,
      uom,
      partNumber,
      partDescription,
      quantity,
      approverID,
      isCorePart,
      oilType,
      unitPrice,
      netPrice
    }) => ({
      partType,
      adjustedUom: uom,
      extPartId: "HARDCODE",
      oemPartNumber: partNumber,
      approver: approverID || "HARDCODE", // Can be anything
      isCorePart,
      description: partDescription,
      oilType,
      adjustedQuantity: quantity,
      unitPrice: unitPrice?.amount,
      partPrice: netPrice?.amount
    })
  );
};

/**
 * Retrieves payer details.
 * @param {Array} payers - The array of payer data.
 * @returns {Array} Transformed payer information.
 */
export const getPayers = payers => {
  if (!payers) return [];

  return payers.map(
    ({
      payTypeCode,
      payTypeGroup,
      fees,
      discounts,
      closeDate,
      subTotalLineLaborBasePrice,
      subTotalLinePartsBasePrice,
      subTotalLineLaborFees,
      subTotalHazmat,
      miscChargesAndFees,
      subTotalLineDiscounts,
      subTotalRODiscounts,
      subTotalSublets,
      subTotalBeforeTaxes,
      salesTax,
      finalPrice,
      serviceContract,
      warranties,
      payerId
    }) => ({
      catalogDiscounts: getCatalogDiscounts(discounts),
      catalogFees: getCatalogFees(fees),
      payType: payTypeCode ?? payTypeGroup?.substring(0, 1),
      closedDate: closeDate,
      subTotalLineLaborBasePrice: subTotalLineLaborBasePrice?.amount ?? "--",
      subTotalLinePartsBasePrice: subTotalLinePartsBasePrice?.amount ?? "--",
      subTotalLineLaborFees: subTotalLineLaborFees?.amount ?? "--",
      feesHazardousMaterials: subTotalHazmat?.amount ?? "--",
      miscChargesAndFees: miscChargesAndFees?.amount ?? "--",
      subTotalLineDiscounts: subTotalLineDiscounts?.amount ?? "--",
      subTotalRODiscounts: subTotalRODiscounts?.amount ?? "--",
      subTotalSublets: subTotalSublets?.amount ?? "--",
      subTotalBeforeTaxes: subTotalBeforeTaxes?.amount ?? "--",
      salesTax: salesTax?.amount ?? "--",
      finalPrice: finalPrice?.amount ?? "--",
      ...(serviceContract && { serviceContract }),
      warranties: warranties ?? null,
      payerId: payerId ?? null
    })
  );
};

/**
 * Retrieves catalog fees details.
 * @param {Array} fees - The array of fees data.
 * @returns {Array} Transformed catalog fees information.
 */
const getCatalogFees = fees => {
  if (!fees) return [];

  return fees.map(
    ({
      feeDescription,
      amountType,
      applyFeeSvcLine,
      feesAmountCollection,
      maximumAmount,
      validFrom
    }) => {
      return {
        description: feeDescription,
        feesType: amountType,
        applyFeeSvcLine: applyFeeSvcLine ? 1 : 0,
        appliedFee: feesAmountCollection?.[0].feeAmount?.amount ?? 0,
        feeMax: maximumAmount?.amount,
        fromDate: validFrom
      };
    }
  );
};

/**
 * Retrieves catalog discounts details.
 * @param {Array} discounts - The array of discounts data.
 * @returns {Array} Transformed catalog discounts information.
 */
const getCatalogDiscounts = discounts => {
  if (!discounts) return [];

  return discounts.map(({ discountAmount, discountDescription }) => {
    return {
      appliedDiscount: discountAmount?.amount,
      description: discountDescription ?? "--"
    };
  });
};
