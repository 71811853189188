/**
 * The objective of this function is to apply the calculation logic to following service types{dealer pub, global repair, declined, recall, diagnosis}
 * call this util functions when ever to re-calculate service price fields
 *
 */

import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import {
  EditServiceTemplate,
  operationSources,
  dealerPublishedCategories,
  serviceTypes,
  payTypeGroups,
  actionTypes
} from "./edit-service.constants";
import { roundedNumber } from "./format.util";
/**
 * This function receives the catalog API service as a parameter
 * and will apply the calculation logic fields to catalog service
 */
// search - non-global service (props.service) - call this
// search - global service - read service from laborApp{} - call this
// search - menu service (props.service) - passed from Menu reducer (menu service already does calculated prices); try calling this util and see if any difference in field values
// summary - edit global/non-global service - No need call this util, since context.Action CURRENT_EDITING_SERVICE will update service with calculation logic fields.
// summary - edit Menu service case - Menu reducer returns calculated fields in delta logic, skip calling this util and menu serivce passed as props.service
const initCalculationForService = service => {
  const serviceObj = cloneDeep(service);
  const withFlags = setOverriddenFlags(serviceObj);
  const withCalculation = addCalculation(withFlags);
  const withOverriddenPrices =
    service?.operationSource === "MENU"
      ? setOverridenPricesForMenu(withCalculation)
      : setOverridenPrices(withCalculation);
  const finalService = setFinalPrices(withOverriddenPrices);
  console.log(
    "%c Apply Calculation to Service",
    "color: orange;",
    service,
    finalService
  );
  return finalService;
};

/**
 * This function checks if catlog API service has overridden flags {totalPriceOverridden, laborPriceOverridden, partsPriceOverridden},
 * IF NOT, set default {false} to these fields { partsPriceOverridden, laborPriceOverridden,  totalPriceOverridden}
 * @note: global/non-global API returns override price values in {laborPrice}, {partsPrice}, {totalPrice}
 */
const setOverriddenFlags = service => {
  const { totalPriceOverridden, laborPriceOverridden, partsPriceOverridden } =
    service;
  return {
    ...service,
    totalPriceOverridden: totalPriceOverridden || false,
    laborPriceOverridden: laborPriceOverridden || false,
    partsPriceOverridden: partsPriceOverridden || false
  };
};

/**
 * This function adds final price fields for given service,
 * Business logic used to calculate the final price
 * If "Total Price Overridden" field is true, then use Override price.
 * Else, use calculated "Subtotal" of labor or parts
 * @param {Object} service - service object with overrides, calculated fields.
 * @returns {Object} service object with final price fields.
 */
const setFinalPrices = service => {
  const finalLaborPriceLocal = setFinalLaborPrice(service);
  const finalPartsPriceLocal = setFinalPartsPrice(service);
  let finalTotalPriceLocal = service.totalPriceOverridden
    ? service.totalPriceOverride
    : roundedNumber(finalLaborPriceLocal, 2) +
      roundedNumber(finalPartsPriceLocal, 2);
  // FIX: NEVER UPDATE service.partsPrice {treat as source field from catalog API partsPrice value}
  // finalPartsPrice - Update this field for any parts changes
  // RECALL case: Always return totalPrice = 0;
  if (service.operationSource === operationSources.RECALL) {
    finalTotalPriceLocal = 0;
  }
  return {
    ...service,
    finalLaborPrice: roundedNumber(finalLaborPriceLocal, 2),
    finalPartsPrice: roundedNumber(finalPartsPriceLocal, 2),
    totalPrice: roundedNumber(finalTotalPriceLocal, 2)
  };
};

/**
 * Calculate Final Labor Price following logic
 * If "Total Price Override"" is true, then use ("Total Price Override" - "Final Parts Price")
 * Else, if "Labor Price Override" is true, then use it.
 * Else, use "Labor SubTotal"
 * @returns {number} The final labor price.
 */
const setFinalLaborPrice = service => {
  if (service.totalPriceOverridden) {
    return service.totalPriceOverride - setFinalPartsPrice(service);
  } else if (service.laborPriceOverridden) {
    return service.totalLaborPriceOverride;
  }
  return service.calculatedLaborPrice;
};

/**
 * Calculate Final Part Price following logic:
 * If "Total Price Override" is true, then use "Parts SubTotal".
 * Else, if "Parts Price Override" is true, then use it.
 * Else, use "Parts SubTotal"
 * @param {Object} service
 * @returns {Number} The final parts price.
 */
const setFinalPartsPrice = service => {
  // deprecated - return default calculated parts price
  if (service.totalPriceOverridden) {
    return service.calculatedTotalPartsPrice;
  } else if (service.partsPriceOverridden) {
    return service.totalPartsPriceOverride;
  }
  return service.calculatedTotalPartsPrice;
};

/**
 * @note: service data mapper will read API returned {price overridden} fields and
 * added these new fields {totalLaborPriceOverride, totalPartsPriceOverride and totalPriceOverride} to service object
 * store the scheduledLaborPrice coming from the Catalog API as totalLaborPriceOverride in Quote
 * only if them respective flags are true
 * @param {Service} service
 * @returns {Service} service with overriden prices
 */
const setOverridenPrices = service => {
  const {
    labor,
    laborPriceOverridden,
    partsPriceOverridden,
    totalPriceOverridden,
    totalPrice,
    partsPrice
  } = service;
  const laborPriceVal = !has(service, "labor") ? null : Number(labor.price);
  // Issue - When menu service is edited, if menu service has overrides how to apply logic here ?
  return {
    ...service,
    totalLaborPriceOverride: laborPriceOverridden ? laborPriceVal : null,
    // TODO: debug first partsPrice passed to totalPartsPriceOverride - KEY STEP
    totalPartsPriceOverride: partsPriceOverridden ? partsPrice : null,
    totalPriceOverride: totalPriceOverridden ? totalPrice : null
  };
};
// Note: when Menu service is edited, price override fields are set in service-data-mapper,
// set override fields if mapper passing override fields
const setOverridenPricesForMenu = service => {
  const {
    laborPriceOverridden,
    partsPriceOverridden,
    totalPriceOverridden,
    totalPriceOverride: existingTotalPriceOverride,
    totalPartsPriceOverride: existingTotalPartsPriceOverride,
    totalLaborPriceOverride: existingTotalLaborPriceOverride
  } = service;

  return {
    ...service,
    totalLaborPriceOverride: laborPriceOverridden
      ? existingTotalLaborPriceOverride
      : null,
    totalPartsPriceOverride: partsPriceOverridden
      ? existingTotalPartsPriceOverride
      : null,
    totalPriceOverride: totalPriceOverridden ? existingTotalPriceOverride : null
  };
};

/**
 * Apply calculations to a service, determine labor and parts subtotals
 * Calculate "Total price " as "Calculated Labor Price" + "Calculated Total Parts Price"
 */
const addCalculation = service => {
  const updatedService = { ...service };
  Object.assign(updatedService, calculateLaborPrice(updatedService));
  Object.assign(updatedService, calculatePartsPrice(updatedService));

  const servicePrice =
    updatedService.calculatedTotalPartsPrice +
    updatedService.calculatedLaborPrice;

  updatedService.calculatedServicePrice = parseFloat(servicePrice.toFixed(2));
  // console.log("calci service price", updatedService.calculatedServicePrice);
  return updatedService;
};

/**
 * Calculates parts subtotal for a given service.
 * The Parts Subtotal is determined by summing up each part's (unit price × quantity).
 *
 * @param {Object} service - The service object containing the parts to be calculated.
 * @returns {Object} The service object with the calculated parts subtotal.
 */
const calculatePartsPrice = service => {
  const parts = Array.isArray(service.parts) ? service.parts : [];
  const partsTotal = parts
    .filter(part => part.selected)
    .reduce((total, part) => {
      const unitPrice = parseFloat(part.unitPrice) || 0;
      const quantity = parseFloat(part.quantity) || 0;
      return total + unitPrice * quantity;
    }, 0);

  console.log("util> calculated parts price:", partsTotal.toFixed(2));

  // Assign the calculated price to the service object, rounded to two decimals.
  service.calculatedTotalPartsPrice = parseFloat(partsTotal.toFixed(2));

  return service;
};

/**
 * Calculates the labor subtotal for a service. Uses the {defaultLaborRate X laborHours} if
 * there's no override, otherwise sets it to zero.
 *
 * @param {Object} service - The service object containing the labor details.
 * @returns {Object} The service object with the calculated labor subtotal.
 */
const calculateLaborPrice = service => {
  const laborTime = !has(service, "labor") ? 0 : Number(service.labor.time);
  const laborRate = !has(service, "defaultLaborRate")
    ? 0
    : Number(service.defaultLaborRate);
  const calculatedLaborPriceVal = Number(laborRate * laborTime);
  service.calculatedLaborPrice =
    parseFloat(calculatedLaborPriceVal.toFixed(2)) || 0;
  return service;
};

/**
 * Below common methods used to execute dependency data related to edited service
 *
 */
const getServiceType = (operationSource, serviceKind) => {
  let serviceType = "";
  if (!isEmpty(operationSource)) {
    if (operationSource === operationSources.GLOBALCATALOG) {
      serviceType = serviceTypes.GLOBALREPAIR;
    } else if (
      operationSource === operationSources.DEALERCATALOG &&
      !isEmpty(serviceKind)
    ) {
      if (serviceKind === dealerPublishedCategories.REPAIR) {
        serviceType = serviceTypes.DIAGNOSIS;
      } else {
        serviceType = serviceTypes.DEALERPUBLISHED;
      }
    } else {
      if (operationSource === operationSources.DECLINED) {
        serviceType = serviceTypes.DECLINED;
      }
      if (operationSource === operationSources.RECALL) {
        serviceType = serviceTypes.RECALL;
      }
      // @fix - verify if operationSource value is inconsistent rawOps vs menuservice in data wrapper files?
      if (operationSource.toUpperCase() === operationSources.MENU) {
        serviceType = serviceTypes.MENU;
      }
    }
  }
  console.info("%c SERVICE TYPE", "color: green;", serviceType);
  return serviceType;
};

// Case1: declined and recall c and w are assigned as defaultpayTypeCode
// Case2: Other servicetypes, read defaultpayTypeCode from catalog API response
/**
 *
 * @param {object} service - pass either propService or currentEditingService {summary flow}
 * @param {array} payTypes
 * @param {string} make
 * @param {string} operationSource
 * @returns selectedDefault
 */
const getDefaultPayTypeForService = (
  service,
  payTypes,
  make,
  operationSource
) => {
  // Always expect payTypeCode in service. other search & summary cases - be specific menu vs other services
  // For Menus, Menu will change paytype, which is passed as payTypeCode in menu service {propService} (both flows)
  const defaultPayTypeCode =
    service?.payTypeCode || service?.defaultPayTypeCode || "";

  // declare default object
  let selectedDefault = {
    payCode: "",
    description: "",
    payTypeGroup: "",
    dealerPayTypeId: "-1"
  };
  if (!isEmpty(payTypes)) {
    let customerPayGroupFound = null;
    customerPayGroupFound = payTypes.findIndex(
      e =>
        e?.payTypeGroup?.toLowerCase() ===
        payTypeGroups.CUSTOMERPAY.toLowerCase()
    );
    // If the record is not found, findIndex returns -1 so we need to reset it to null
    customerPayGroupFound =
      customerPayGroupFound < 0 ? null : customerPayGroupFound;
    // case: dealer pub, global ops, diagnosis
    if (
      operationSource !== operationSources.RECALL &&
      operationSource !== operationSources.DECLINED
    ) {
      let tempSelectedDefault = null;
      tempSelectedDefault = payTypes.find(
        pt =>
          pt.payCode === defaultPayTypeCode &&
          pt.make === make &&
          pt.defaultPayType
      );
      if (!tempSelectedDefault) {
        tempSelectedDefault = payTypes.find(
          pt => pt.payCode === defaultPayTypeCode && pt.defaultPayType
        );
      }
      if (!tempSelectedDefault) {
        tempSelectedDefault = payTypes.find(
          pt => pt.payCode === defaultPayTypeCode && pt.make === make
        );
      }
      if (!tempSelectedDefault) {
        tempSelectedDefault = payTypes.find(
          pt => pt.payCode === defaultPayTypeCode
        );
      }
      selectedDefault = !isEmpty(tempSelectedDefault) && tempSelectedDefault;
      // Case: Recall, we first check if we have matching payGroup and specific make, otherwise, we select matching paygroup and ANY MAKE
    } else if (operationSource === operationSources.RECALL) {
      let recallDefaultPayTypeOption = null;
      let warrantyGroupFound = null;
      warrantyGroupFound = payTypes.findIndex(
        e =>
          e?.payTypeGroup?.toLowerCase() ===
          payTypeGroups.WARRANTY.toLowerCase()
      );
      const selectedPayType =
        payTypes.find(pt => pt.payCode === defaultPayTypeCode) || "";
      // If the record is not found, findIndex returns -1 so we need to reset it to null
      warrantyGroupFound = warrantyGroupFound < 0 ? null : warrantyGroupFound;
      if (!isNull(warrantyGroupFound)) {
        // first precedence, check for both payGroup warranty and specific make and defaultPayType true
        recallDefaultPayTypeOption = payTypes.find(
          pt =>
            pt?.payTypeGroup?.toLowerCase() ===
              payTypeGroups.WARRANTY.toLowerCase() &&
            pt.make === make &&
            pt.defaultPayType
        );
        // else, select the first one matching the payTypeGroup warranty
        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes.find(
            pt =>
              pt?.payTypeGroup?.toLowerCase() ===
                payTypeGroups.WARRANTY.toLowerCase() && pt.defaultPayType
          );
        }
        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes.find(
            pt =>
              pt?.payTypeGroup?.toLowerCase() ===
                payTypeGroups.WARRANTY.toLowerCase() && pt.make === make
          );
        }

        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes[warrantyGroupFound];
        }
      } else if (!isNull(customerPayGroupFound)) {
        // first precedence, check for both payGroup warranty and specific make and defaultPayType true
        recallDefaultPayTypeOption = payTypes.find(
          pt =>
            pt?.payTypeGroup?.toLowerCase() ===
              payTypeGroups.CUSTOMERPAY.toLowerCase() &&
            pt.make === make &&
            pt.defaultPayType
        );
        // else, select the first one matching the payTypeGroup warranty
        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes.find(
            pt =>
              pt?.payTypeGroup?.toLowerCase() ===
                payTypeGroups.CUSTOMERPAY.toLowerCase() && pt.defaultPayType
          );
        }
        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes.find(
            pt =>
              pt?.payTypeGroup?.toLowerCase() ===
                payTypeGroups.CUSTOMERPAY.toLowerCase() && pt.make === make
          );
        }

        if (!recallDefaultPayTypeOption) {
          recallDefaultPayTypeOption = payTypes[customerPayGroupFound];
        }
      }
      selectedDefault =
        selectedPayType ||
        (!isEmpty(recallDefaultPayTypeOption) && recallDefaultPayTypeOption);
      // Case: Declined - follows same logic as Recall but instead of Warranty we check for Customer pay
    } else if (operationSource === operationSources.DECLINED) {
      let declinedDefaultPayTypeOption = payTypes.find(
        pt =>
          pt?.payTypeGroup?.toLowerCase() ===
            payTypeGroups.CUSTOMERPAY.toLowerCase() &&
          pt.make === make &&
          pt.defaultPayType == true
      );
      const selectedPayType =
        payTypes.find(pt => pt.payCode === defaultPayTypeCode) || "";
      if (!declinedDefaultPayTypeOption) {
        declinedDefaultPayTypeOption = payTypes.find(
          pt =>
            pt?.payTypeGroup?.toLowerCase() ===
              payTypeGroups.CUSTOMERPAY.toLowerCase() &&
            pt.defaultPayType == true
        );
      }
      if (!declinedDefaultPayTypeOption) {
        declinedDefaultPayTypeOption = payTypes.find(
          pt =>
            pt?.payTypeGroup?.toLowerCase() ===
              payTypeGroups.CUSTOMERPAY.toLowerCase() && pt.make === make
        );
      }
      if (!declinedDefaultPayTypeOption) {
        declinedDefaultPayTypeOption = payTypes[customerPayGroupFound];
      }
      selectedDefault =
        selectedPayType ||
        (!isEmpty(declinedDefaultPayTypeOption) &&
          declinedDefaultPayTypeOption);
    }
  }
  return selectedDefault;
};

/**
 *
 * @param {object} service - pass propService when component didmount
 * @param {array} payTypes
 * @param {object} rawOperationDetails
 * @returns {object} update paytype values to service
 */
const updateServiceWithPayTypeValues = (
  service,
  payTypes,
  rawOperationDetails
) => {
  // declare default object
  let selectedDefault = {
    payCode: "",
    description: "",
    payTypeGroup: "",
    dealerPayTypeId: "-1"
  };
  const defaultPayTypeCode = service?.defaultPayTypeCode || "";
  if (!isEmpty(payTypes) && !isEmpty(service)) {
    selectedDefault = getDefaultPayTypeForService(
      service,
      payTypes,
      rawOperationDetails.make,
      rawOperationDetails.operationSource,
      defaultPayTypeCode
    );
    const defaultPayTypeOption = !selectedDefault
      ? ""
      : selectedDefault.dealerPayTypeId.toString();
    const {
      payCode: selectedPayCode,
      description: selectedDescription,
      payTypeGroup: selectedPayTypeGroup
    } = selectedDefault;
    if (!isEmpty(defaultPayTypeOption)) {
      service.payTypeCode = selectedPayCode;
      service.payTypeDescription = selectedDescription;
      service.payTypeGroup = selectedPayTypeGroup;
    }
    return service;
  } else {
    const {
      payCode: selectedPayCode,
      description: selectedDescription,
      payTypeGroup: selectedPayTypeGroup
    } = selectedDefault;
    service.payTypeCode = selectedPayCode;
    service.payTypeDescription = selectedDescription;
    service.payTypeGroup = selectedPayTypeGroup;
  }
  return service;
};

// GLOBAL REPAIR case:
// Method extract selected laborApp when SET_LABOR_APP action triggered, read paytypes, parts from laborApp
// returns service object to update context
const initCalculationForGlobalService = (
  rawOperationDetails,
  selectedLaborApp,
  payTypes,
  filteredParts
) => {
  const service = rawOperationDetails;
  service.laborApps = [];
  service.laborApps.push(selectedLaborApp);
  // IMPORTANT: mapper returns serviceObject same datamodel of editService context.service
  let serviceObject = cloneDeep(EditServiceTemplate);
  if (!isEmpty(service.laborApps)) {
    const laborAppsObj = service?.laborApps[0];
    serviceObject = {
      name: laborAppsObj.name || "",
      totalPrice: laborAppsObj?.totalPrice || 0,
      // @todo-edit: fix - read laborAppId from unified API and pass in quote payload as labor.extLaborId
      laborAppId: get(laborAppsObj, "laborAppId", null),
      labor: {
        catalogLabor: service?.catalogLabor ?? null,
        serviceId: service?.operationId || laborAppsObj?.laborAppId || "",
        price: laborAppsObj?.laborPrice || 0,
        time: laborAppsObj?.laborHours || 0,
        laborCostMethod: service?.laborCostMethod || null,
        laborCostOverride: service?.laborCostOverride || null,
        notes: "" // @fix - this field is always empty for global repair case
      },
      defaultLaborRate: service?.defaultLaborRate || 0,
      laborRateOverride: service?.laborRateOverride ?? null,
      laborRate: service?.laborRate || 0,
      dealerLaborRateId: laborAppsObj?.dealerLaborRateId || null,
      asrNotes: service.asrNotes || "",
      dealershipNotes: "", // default
      commentsRequired: has(service, "commentsRequired")
        ? Boolean(service.commentsRequired)
        : false,
      catalogLabor: service?.catalogLabor ?? null,
      opCode: formatOpcode(laborAppsObj.opCode) || "",
      subTypeId: service?.subTypeId,
      allocationSubTypeId: service?.allocationSubTypeId,
      internalAccount: service?.internalAccount,
      description: service.description || "",
      operationSource: service.operationSource || "",
      serviceKind: service.serviceKind || "",
      partsPrice: laborAppsObj?.partsPrice || 0,
      // add parts from argu - filteredParts
      parts: filteredParts || [],
      // price override fields added, based on getOperationDetails API response has overridden flags
      totalLaborPriceOverride: laborAppsObj.laborPriceOverridden
        ? laborAppsObj.laborPrice
        : null,
      totalPartsPriceOverride: laborAppsObj.partsPriceOverridden
        ? laborAppsObj.partsPrice
        : null,
      totalPriceOverride: laborAppsObj.totalPriceOverridden
        ? laborAppsObj.totalPrice
        : null,
      laborPriceOverridden: laborAppsObj.laborPriceOverridden || false,
      partsPriceOverridden: laborAppsObj.partsPriceOverridden || false,
      totalPriceOverridden: laborAppsObj.totalPriceOverridden || false,
      // @note - add calculated fields as default
      calculatedLaborPrice: null,
      calculatedTotalPartsPrice: null,
      calculatedServicePrice: null,
      finalLaborPrice: null,
      finalPartsPrice: null,
      // @note: always read defaultPayTypeCode coming from catalog unified api response for non-global ops
      defaultPayTypeCode: get(service, "defaultPayTypeCode", ""),
      defaultServiceTypeCode: service?.defaultServiceType || "",
      serviceTypeCode:
        service?.serviceTypeCode || service?.defaultServiceType || "",
      skillLevel: service?.dispatchSkillLevel || "",
      make: service?.make || "",
      payTypeGroup: "",
      payTypeCode: "",
      payTypeDescription: "",
      serviceContract: service.serviceContract || null,
      cause: service?.cause || null,
      complaint: service?.complaint || null,
      correction: service?.correction || null,
      defaultTaxCode: service?.defaultTaxCode || null
    };
    if (service?.payTypeCode === "W") {
      serviceObject.technicians = service?.technicians || [];
      serviceObject.warranty = service?.warranty || null;
    }
  }
  // Update service with payType values using priceCalculationUtil
  const serviceWithPaytypeCode = updateServiceWithPayTypeValues(
    serviceObject,
    payTypes,
    rawOperationDetails
  );
  const serviceWithCalculation = initCalculationForService(
    serviceWithPaytypeCode
  );

  console.info("util>initCalculationForGlobalService", serviceWithCalculation);
  return serviceWithCalculation;
};

const formatOpcode = opCode => {
  if (opCode) {
    const opCodes = !isEmpty(opCode) ? opCode.split(";") : [];
    const opCodeSelected = !isEmpty(opCodes) ? opCodes[0] : opCode;
    return opCodeSelected;
  }
};
// Important: Util method to update calculated, final fields based on change {labor time, labor price, parts price, parts add/delete}
const recalculatePrices = (
  service,
  actionType,
  fieldValue,
  modifiedParts = null
) => {
  const newService = cloneDeep(service);
  const updatedService = { ...newService };
  // @note: Trigger when labor time, paytype is changed or getLaborPrice API called
  if (actionType === actionTypes.LABOR) {
    newService.calculatedLaborPrice = fieldValue;
    updateCalculatedFields(newService);
    // @note: if catalog API has labor override; DONOT over write totalLaborPriceOverride
    // @note: Trigger when user add/delete parts and parts price changed from UI
  } else if (actionType === actionTypes.PARTS) {
    // total parts price passed as fieldValue
    newService.calculatedTotalPartsPrice = fieldValue;
    updateCalculatedFields(newService, modifiedParts);
    // @note: Donot overwrite service.totalPartsPriceOverride when parts are add/deleted {called calculated parts total}
    // @note: Trigger when user overwrite laborprice field from UI
  } else if (actionType === actionTypes.LABOR_OVERRIDE) {
    // when labor price modified in UI, force change as laborPriceOverride
    newService.laborPriceOverridden = true;
    newService.totalLaborPriceOverride = fieldValue;
  }

  Object.assign(updatedService, setFinalPrices(newService));
  console.log(
    "%c RECALCULATE Prices for " + actionType,
    "color: orange;",
    updatedService
  );
  return updatedService;
};

// @note: Update calculation fields when any field modified (laborTime, laborPrice, parts, parts price etc)
const updateCalculatedFields = (service, modifiedParts = null) => {
  console.log("util> calci labor price", service.calculatedLaborPrice);
  // @note: modified parts passed when parts added/deleted; parts price changed
  service.parts = isArray(modifiedParts) ? modifiedParts : service.parts;
  calculatePartsPrice(service);

  const calculatedTotalPriceVal = Number(
    service.calculatedTotalPartsPrice + service.calculatedLaborPrice
  );
  service.calculatedServicePrice = isNull(calculatedTotalPriceVal)
    ? 0
    : calculatedTotalPriceVal;
};

/**
 *
 * @param {object} service - pass either propService or currentEditingService {summary flow}
 * @param {array} serviceTypesList
 * @param {string} operationSource
 * @returns {selectedDefault} object with servicetype details
 */
const getDefaultServiceType = (
  service,
  serviceTypesList,
  operationSource
  // appType
) => {
  let defaultServiceTypeCode =
    service?.serviceTypeCode || service?.defaultServiceTypeCode || "";
  // const isSQ = appType === appTypes.SQ;
  // declare default object
  let selectedDefault = {
    serviceTypeCode: "",
    description: "",
    dealerServiceTypeId: "-1"
  };
  if (!isEmpty(serviceTypesList)) {
    let tempSelectedDefault = null;
    if (
      !defaultServiceTypeCode &&
      (operationSource === operationSources.RECALL ||
        operationSource === operationSources.DECLINED)
    ) {
      defaultServiceTypeCode = "MR";
    }
    tempSelectedDefault = serviceTypesList.find(
      pt => pt.serviceTypeCode === defaultServiceTypeCode
    );
    selectedDefault = !isEmpty(tempSelectedDefault) && tempSelectedDefault;
  }
  return selectedDefault;
};
export default {
  getServiceType,
  getDefaultPayTypeForService,
  getDefaultServiceType,
  updateServiceWithPayTypeValues,
  initCalculationForGlobalService,
  recalculatePrices,
  initCalculationForService,
  setOverriddenFlags,
  setFinalPrices
};
