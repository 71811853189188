/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import TruncateText from "@cx/ui/TruncateText";
import Badge from "@cx/ui/Badge";
import { UnitOfMeasureMap } from "../constants";
import {
  doesEmpty,
  convertMinutesToHours
} from "../../page-wrapper/utils/quote-util";
import {
  isQuoteStatusWorkFinishedOrLater,
  priceValueFormatter
} from "../../utils/format";
import { AppContext } from "../../../state/app-context";
import { appTypes } from "../../../constants/app.constants";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";
import {
  roStatusOptions,
  paymentStatus,
  payTypeCodes,
  warrantySubmissionStates,
  invoiceBadgeLabels,
  serviceLineBadgeLabels,
  warrantyBadgeLabels,
  warrantyBadgeLabelsNew,
  partStatus,
  badgeColors
} from "../../../constants/quote-status.constants";
import ServiceDetailsSection from "../../repair-order/components/service-details-section/service-details-section.component";
import csrService from "../../quote-summary/services/csr.service";
import { getPartAvailabilityLabel } from "../../../PartsLookupModule/utils/helper.util";

const MenuServiceDetails = props => {
  const { localeStrings, service, expandDetails, isPackagePriceOverriden } =
    props;
  const appContext = useContext(AppContext);
  const { appType, userPermissions, dealerProperties } = appContext;
  const isCSR = appType === appTypes.CSR;
  const { state } = useNewQuoteContext();
  const { quoteSummary } = state;
  const partsExist =
    Array.isArray(service.part) && service.part.length > 0 ? true : false;
  const laborExist =
    !isNull(service.scheduledLaborPrice) || !isNull(service.durationMins)
      ? true
      : false;
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;

  const showPartLabor = partsExist || laborExist ? true : false;
  const clsRow = expandDetails && showPartLabor ? "pkg-quote-details" : "hide";
  let laborHtml = null;
  let partsHtml = null;
  const clsLabor = laborExist ? "pkg-quote-labor no-border" : "hide";
  const clsParts = partsExist ? "pkg-quote-parts no-border" : "hide";
  const clsPartsTable = partsExist ? "pkg-parts-box" : "hide";
  const clsPartShowHide = expandDetails ? "pkg-parts-container" : "hide";

  const laborServiceId = service.id;
  // fix: read calculated fields added to service{} like finalLaborPrice, partsPrice from menuService
  const laborPrice = Number(service?.finalLaborPrice || 0);
  // read laborTime as durationMins from menuService
  let laborTime = Number(service?.durationMins || 0);
  if (!doesEmpty(laborTime)) {
    laborTime = convertMinutesToHours(laborTime);
  }
  if (!doesEmpty(laborTime) || laborPrice) {
    const laborPriceVal = priceValueFormatter(laborPrice);

    laborHtml = (
      <div
        className={[clsLabor, "margin-left-18"].join(" ")}
        key={"labor-" + laborServiceId}
      >
        <div className="labor-title">
          <span>
            {localeStrings["sq.search.common.edit_service.summary.labor_lbl"]} -{" "}
          </span>
          <span className="labor-time">{laborTime}</span>
          <span className="labor-hours">
            {localeStrings["sq.newquote.summary.hours_lbl"]}
          </span>
        </div>
        {isPackagePriceOverriden ? (
          ""
        ) : (
          <div className="labor-price">{laborPriceVal}</div>
        )}
      </div>
    );
  }
  // Parts Table
  const styleLine = {
    textDecoration: "none"
  };
  // fix: always read partsPrice from service has with/without parts[]
  // TODO: With util calculation logic, parts price returned in finalPartsPrice- check this
  const totalPrice = service?.finalPartsPrice;
  if (service.hasOwnProperty("part") && service.part.length > 0) {
    const childHtml = service.part.map((partObj, index) => {
      const unitQty =
        partObj.partType === "fluid" && partObj.adjustedQuantity > 0
          ? UnitOfMeasureMap[partObj.adjustedUom]
          : "";
      const keyId = "part-" + partObj.extPartId + "-" + index;
      const partNumber = partObj.oemPartNumber || "";
      const showPartNumTip =
        partNumber && partNumber.trim().length > 10 ? true : false;
      const inStock = getPartAvailabilityLabel(partObj);
      return (
        <div className="parts-row" key={keyId}>
          <div className="part-desc">
            {partObj.description || ""}
            <span className="padding-left-4">{partObj.oilType || ""}</span>
          </div>
          <TruncateText htmlId="partNumberText" displayToolTip={showPartNumTip}>
            <div className="part-number">{partNumber || ""} </div>
          </TruncateText>
          {partObj.hasOwnProperty("adjustedQuantity") ? (
            <div className="qty">
              {localeStrings["sq.search.common.edit_service.summary.qty_lbl"]}:
              <span className="padding-left-4">
                {partObj.adjustedQuantity > 0 ? partObj.adjustedQuantity : ""}
              </span>
              <span className="padding-left-2">{unitQty}</span>
            </div>
          ) : (
            <div className="qty" />
          )}
          {isCSR ? (
            <div className="partStatus">
              {csrService.buildPartStatusBadge(
                partObj.approver ? partStatus.APPROVED : partStatus.REQUESTED
              )}
            </div>
          ) : null}
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <div className="unit-price" style={styleLine}>
              {partObj.unitPrice >= 0
                ? priceValueFormatter(partObj.unitPrice)
                : ""}
              {partObj.partType === "part" ? "/item" : ""}
            </div>
          )}
          <div className="stock" />
          {!partObj.dmsPending && isCSR ? (
            <div className={partObj.inStock ? "stock" : "no-stock"}>
              {inStock}
            </div>
          ) : null}
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <div className="part-price" style={styleLine}>
              {partObj.partsPrice >= 0
                ? priceValueFormatter(partObj.partsPrice)
                : ""}
            </div>
          )}
        </div>
      );
    });
    partsHtml = (
      <>
        <div className="pkg-quote-labor no-border">
          <div className="labor-title">
            {" "}
            {localeStrings["sq.search.common.edit_service.summary.parts_lbl"]} (
            {service.part.length})
          </div>
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <div className="labor-price">
              {totalPrice >= 0 ? priceValueFormatter(totalPrice) : ""}
            </div>
          )}
        </div>
        <div className={[clsPartShowHide, "margin-left-22"].join(" ")}>
          <div className={clsPartsTable}>{childHtml}</div>
        </div>
      </>
    );
  } else if (service.hasOwnProperty("part") && service.part.length === 0) {
    partsHtml = (
      <div className={clsParts}>
        <div className="labor-title">Parts ({service.part.length})</div>
        <div className="labor-price" />
      </div>
    );
  }
  let serviceDetailsSection = null;
  let servicelineBadges = null;
  const isServiceCompleted = !!service?.completedTime;
  if (isCSR && !isEmpty(service)) {
    serviceDetailsSection =
      isServiceCompleted && expandDetails ? (
        <div className="pkg-quote-service-details">
          <ServiceDetailsSection
            userPermissions={userPermissions}
            service={service}
          />
        </div>
      ) : null;

    servicelineBadges = (
      <div className="service-details-section-badge">
        {isServiceCompleted ? (
          <Badge
            htmlId="serviceCompletedBadge"
            color={badgeColors.PURPLE}
            className="m-r"
          >
            {serviceLineBadgeLabels.COMPLETED}
          </Badge>
        ) : null}
        {isServiceCompleted &&
        quoteSummary?.quoteStatus === roStatusOptions.PRE_INVOICE ? (
          <Badge
            htmlId="readyForInvoicingBadge"
            color={
              service?.paymentStatus === paymentStatus.READY
                ? badgeColors.PURPLE
                : badgeColors.GRAY
            }
            className="m-r"
          >
            {service?.paymentStatus === paymentStatus.READY
              ? invoiceBadgeLabels.READY
              : invoiceBadgeLabels.PENDING}
          </Badge>
        ) : null}
        {service?.payTypeCode === payTypeCodes.WARRANTY &&
        isQuoteStatusWorkFinishedOrLater(quoteSummary.quoteStatus) ? (
          isUseOEMWarrantyEnabled ? (
            <Badge
              htmlId="warrantyReadyBadge"
              color={
                service?.warranty?.warrantySubmissionState ===
                warrantySubmissionStates.READY
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.READY
                ? warrantyBadgeLabelsNew.WARRANTY_READY
                : warrantyBadgeLabelsNew.PENDING_WARRANTY_REVIEW}
            </Badge>
          ) : (
            <Badge
              htmlId="warrantyReadyBadge"
              color={
                service?.warranty?.warrantySubmissionState ===
                warrantySubmissionStates.SUBMITTED
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.SUBMITTED
                ? warrantyBadgeLabels.SUBMITTED_TO_OEM
                : warrantyBadgeLabels.PENDING_SUBMISSION}
            </Badge>
          )
        ) : null}
      </div>
    );
  }

  return (
    <div className={[clsRow, "margin-top-8 margin-right-13"].join(" ")}>
      {laborHtml}
      {partsHtml}
      {serviceDetailsSection}
      {servicelineBadges}
    </div>
  );
};

export default MenuServiceDetails;

MenuServiceDetails.propTypes = {
  localeStrings: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  expandDetails: PropTypes.bool.isRequired,
  isPackagePriceOverriden: PropTypes.bool.isRequired
};
