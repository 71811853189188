export const getMoveToPreRoOption = (
  canMoveServiceToPreRo,
  onMoveServiceToPreRo,
  moveToPreRoLabel,
  disableOption
) => {
  if (!canMoveServiceToPreRo) return [];
  return [
    {
      label: moveToPreRoLabel,
      onSelect: onMoveServiceToPreRo,
      disabled: disableOption
    }
  ];
};
