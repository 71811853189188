export const serviceTypes = {
  GLOBALREPAIR: "globalRepair",
  DEALERPUBLISHED: "dealerPublished",
  DIAGNOSIS: "diagnosis",
  DECLINED: "declined",
  RECALL: "recall",
  MENU: "menu",
  TELLUSMORE: "tellusMore"
};

export const mockPart = {
  partName: "",
  partType: "part",
  oemPartNumber: "",
  position: null,
  quantity: 0,
  adjustedQuantity: 0,
  exactQuantity: null,
  unitOfMeasure: null,
  unitPrice: 0,
  dmsPrice: null,
  dmsSource: null,
  partsPrice: 0,
  priceSource: "MSRP",
  partPriceSource: null,
  notes: null,
  status: null,
  vehicleAttributes: null,
  alternateParts: [],
  partNumberDefinedByDealerFlag: null,
  dtDmsPartCode: "",
  relationship: null,
  selected: true,
  oilType: null,
  gradeCode: null,
  specification: null,
  qualifiers: null,
  rowId: null,
  partId: null
};

// We may need to add and/or change some of these types later
export const actionTypes = {
  EDIT_NEW: "EDIT_NEW",
  EDIT_SUMMARY: "EDIT_SUMMARY",
  MODIFY_PARTS: "MODIFY_PARTS"
};

export const applications = {
  QUOTING: "SQ",
  CSR: "CSR"
};

export const OperationSources = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DECLINED: "DECLINED",
  MENU: "MENU",
  RECALL: "RECALL"
};

export const priceSourceLabels = {
  MSRP: "MSRP",
  DMS: "DMS",
  MANUAL: "MANUAL"
};

export const stockLabels = {
  IN_STOCK: "In stock",
  OUT_OF_STOCK: "Out of stock"
};

export const partStatus = {
  OPEN: "OPEN",
  BACKORDERED: "BACKORDERED",
  APPROVED: "APPROVED"
};

export const partStatusLabel = {
  OPEN: "Open",
  BACKORDERED: "Backordered",
  APPROVED: "Approved",
  REQUESTED: "Requested"
};
