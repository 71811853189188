import React, { useEffect, useState } from "react";
import SelectInput from "@cx/ui/SelectInput";
import {
  useEditServiceContext,
  Actions
} from "../../../EditServiceModule/state/edit-service.context";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";

const LaborPartsFields = () => {
  const { dispatch, state } = useEditServiceContext();

  // TODO: list to be replaced with list from API
  const taxCodeList = [
    { value: "SA090700", label: "Repair & Maintenance" },
    { value: "ON030000", label: "Non-Taxable" },
    { value: "SA090101", label: "Car Detailing" },
    { value: "SA090102", label: "Car Washing" },
    { value: "SA090400", label: "Install Accessory - with part purchase" },
    { value: "SA090500", label: "Install Accessory - without part purchase" },
    { value: "PR100300", label: "Rental Car" }
  ];

  const [laborTaxCode, setLaborTaxCode] = useState(
    state?.service?.laborTaxCode ||
      state?.service?.defaultTaxCode ||
      taxCodeList[0].value
  );

  useEffect(() => {
    // This useEffect is triggered whenever the laborTaxCode changes.
    // It is responsible for updating the labor tax code in the application state.

    // Adding new service
    // If the laborTaxCode changes, update the labor tax code in the state.
    // This ensures that the UI reflects the latest labor tax code selected by the user.
    dispatch({
      type: Actions.SET_LABOR_TAX_CODE,
      payload: laborTaxCode
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborTaxCode]);

  const buildTaxCodeOptions = () => {
    return taxCodeList.map(p => {
      return { label: p.label, value: p.value };
    });
  };

  const onChangeInput = event => {
    setLaborTaxCode(event.target.value);
  };

  return (
    <>
      <div>
        <span className="tax-code-text">Tax code</span>
        <Tooltip
          htmlId="tooltipTop"
          tooltipContent="Taxes Combined and calculated on service total"
          position="bottom"
        >
          <IconInfoOutline
            htmlId="IconNoteExample"
            className="tax-code-icon-info"
          />
        </Tooltip>
      </div>
      <SelectInput
        className="taxCodeField"
        data-testid="taxCode"
        htmlId="taxCode"
        displayLabel={false}
        name="laborTaxCode"
        options={buildTaxCodeOptions()}
        onChange={e => onChangeInput(e)}
        value={laborTaxCode}
        displayDeselectOption={false}
      />
    </>
  );
};

export default LaborPartsFields;
