import {
  GlobalOpsServiceType,
  IncludedInspectionTags
} from "../constants/search.constants";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import operationDetailsSchema from "../schemas/operation-details.schema.js";
import { convertHoursToMinutes } from "./quote.util";
import { OperationSources } from "../../features/page-wrapper/constants/page-wrapper.constants";

/**
 * Builds the rawService JSON string based on operation source.
 *
 * @param {boolean} isDeclined - Indicates if the service is declined.
 * @param {Object} globalOps - Global operation details.
 * @param {Object} record - Raw record details.
 * @returns {string} - JSON string of the rawService.
 */
const buildRawService = (isDeclined, globalOps, record) =>
  JSON.stringify(isDeclined ? { ...globalOps, ...record } : globalOps);

/**
 * Processes DEALERCATALOG services and updates the laborApps field.
 *
 * @param {Object} globalOpsService - The global operation service object.
 * @param {Object} record - Raw record details.
 * @returns {Object} - Updated global operation service.
 */
const processDealerCatalogService = (globalOpsService, record) => {
  if (!globalOpsService?.laborApps?.length) return globalOpsService;

  const operation = globalOpsService.laborApps[0];
  operation.laborMinutes = convertHoursToMinutes(operation?.laborHours || 0);
  globalOpsService.laborApps[0] = updateOperationObject(operation, record);

  return globalOpsService;
};

/**
 * Processes general services and adds operation details.
 *
 * @param {Object} globalOpsService - The global operation service object.
 * @param {Object} record - Raw record details.
 * @returns {Object} - Updated global operation service with parts and name.
 */
const processGeneralService = (globalOpsService, record) => {
  return {
    ...updateOperationObject(globalOpsService, record),
    id: record.operationId,
    name: record.operationName,
    parts: globalOpsService?.parts || []
  };
};

/**
 * Formats a service object with additional details based on operation source.
 *
 * @param {Object} service - The service object to format.
 * @param {Object} globalOperationDetails - Details about the global operation schema.
 * @returns {Object|null} - Formatted service object or null if rawRecord is missing.
 */
const formatService = (service, globalOperationDetails) => {
  console.log(
    "search formatService - service/globalOperationDetails",
    service,
    globalOperationDetails
  );
  const rawRecord = service?.rawRecord;

  if (!rawRecord) {
    console.warn("Invalid service format: missing rawRecord");
    return null;
  }

  let formattedService = null;

  // If operationSource exists, handle specific formats
  if (rawRecord.operationSource) {
    let globalOpsService = operationDetailsSchema.cast(globalOperationDetails);
    const isDeclined = rawRecord.operationSource === OperationSources.DECLINED;
    const rawService = buildRawService(isDeclined, globalOpsService, rawRecord);

    if (rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG) {
      globalOpsService = processDealerCatalogService(
        globalOpsService,
        rawRecord
      );
    } else {
      globalOpsService = processGeneralService(globalOpsService, rawRecord);
    }

    formattedService = {
      ...globalOpsService,
      id: rawRecord.operationId,
      serviceKind: rawRecord.serviceKind,
      operationSource: rawRecord.operationSource,
      quoteRawService: { rawService }
    };
  }
  // Fallback for services without operationSource
  else {
    formattedService = {
      ...rawRecord,
      serviceId: rawRecord?.id?.toString() || "",
      quoteRawService: { rawService: JSON.stringify(rawRecord) }
    };
  }

  console.log("search - formattedService", formattedService);
  return formattedService;
};

const updateOperationObject = (operation, rawRecord) => {
  const operationService = {
    ...operation,
    categoryId: rawRecord.categoryId,
    categoryName: rawRecord.categoryName,
    categoryGroupId: rawRecord.categoryGroupId,
    categoryGroupName: rawRecord.categoryGroupName,
    serviceKind: rawRecord.serviceKind,
    operationSource: rawRecord.operationSource
  };
  return operationService;
};

const isIncludedInspection = service => {
  const { price, serviceCategoryName } = service;
  if (price && price !== 0) {
    return false;
  }
  if (!serviceCategoryName) {
    return false;
  }
  const serviceCategoryLowercase = serviceCategoryName.toLowerCase();
  for (let index = 0; index < IncludedInspectionTags.length; index++) {
    if (
      serviceCategoryLowercase.indexOf(IncludedInspectionTags[index]) !== -1
    ) {
      return true;
    }
  }
  return false;
};

export default {
  formatService,
  isIncludedInspection
};
