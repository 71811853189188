import React, { useEffect, useContext, useState } from "react";
import { getVariantForVehicle, updateVin } from "../utils/data-transformer";
import VehicleCard from "./vehicle-card.component";
import { getVehicleImage } from "../../api/xmmAxios";
import { hideBodyMask, showBodyMask } from "../../utils/page-mask.util";
import {
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SERVICE_SUMMARY,
  SEARCH_SERVICE
} from "../../constants/pages.constants";
import ConfirmPopup from "../ui/modals/ConfirmPopup";
import { AppContext } from "../../state/app-context";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import vehicleService from "./services/vehicle.service";
import vehicleSchema from "./schemas/vehicle.schema";
import {
  getPayTypeDetails,
  addMetaVehicle,
  loadDrivingConditionsData,
  getServiceTypeDetails
} from "../page-wrapper/services/customer-vehicle.service";
import isEmpty from "lodash/isEmpty";
import * as gtmEvent from "../utils/gtag/gtag-event.util";
import { doesServiceContractExists } from "../../utils/quote.util";
import {
  getServiceContractsInfo$,
  getVendorListTransformed
} from "../service-contract/utils/service-contracts.util";
import { appTypes } from "../../constants/app.constants";

const VehicleResults = () => {
  const { localeStrings, locale, dealer, appType, dealerProperties } =
    useContext(AppContext);
  const { state, dispatch } = useNewQuoteContext();
  const { customer, makes, quoteSummary } = state;
  const { confirmationId, quoteServices } = quoteSummary;
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const isCSRApp = appType === appTypes.CSR;

  useEffect(() => {
    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload: localeStrings["sq.newquote.customer.select_customer_vehicle_lbl"]
    });
    // eslint-disable-next-line
  }, []);

  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  // @change:  when search by VIN case, page should auto select vehicle with matching VIN and land on service search page
  const itemSelector = async vehicle => {
    gtmEvent.trackGAEventWithParam(
      "ga.newquote.select_customer_vehicle_click",
      {
        result: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        location: "vehicle-results"
      }
    );
    setSelectedVehicle(vehicle);
    const hasAttributes = vehicleSchema.vehicleCardSchema.isValidSync(vehicle);
    console.log("vehicle hasAttr", hasAttributes);
    if (hasAttributes) {
      callbackSearchServices(vehicle);
    } else {
      setShowDiscardPopup(true);
    }
  };

  const callbackSearchServices = async (selectedVehicle, nextAction) => {
    showBodyMask();
    showPageLoading(true);
    try {
      const payTypesData = await getPayTypeDetails(
        dealer.dealerCode,
        selectedVehicle.make
      );
      dispatch({
        type: Actions.SET_PAY_TYPES,
        payload: payTypesData
      });
      const serviceTypesData = await getServiceTypeDetails(
        dealer.dealerCode,
        selectedVehicle.make,
        dealer.dmsType
      );
      dispatch({
        type: Actions.SET_SERVICE_TYPES,
        payload: serviceTypesData
      });
      if (doesServiceContractExists(payTypesData)) {
        if (isCSRApp) {
          const serviceContracts = await getServiceContractsInfo$(
            dealer.dealerCode,
            selectedVehicle?.vin,
            customer?.commonConsumerId
          );

          dispatch({
            type: Actions.SET_SERVICE_CONTRACTS,
            payload: serviceContracts || []
          });

          dispatch({
            type: Actions.SET_VENDOR_LIST,
            payload: getVendorListTransformed(serviceContracts)
          });
        }
        // TODO: uncomment this lines once vendors API is working
        // const vendorListData = await getVendorListDetails(
        //   dealer.dealerCode,
        //   selectedVehicle.make
        // );
        // dispatch({
        //   type: Actions.SET_VENDOR_LIST,
        //   payload: vendorListData || []
        // });
      }
      let updatedVehicle = await addMetaVehicle(selectedVehicle, dealer);
      if (!isEmpty(updatedVehicle)) {
        updatedVehicle.vin = updateVin(selectedVehicle);
        updatedVehicle.variant = getVariantForVehicle(selectedVehicle, makes);
        updatedVehicle = await loadDrivingConditionsData(
          updatedVehicle,
          dealer.dealerCode,
          locale
        );
      }
      dispatch({
        type: Actions.SET_VEHICLE,
        payload: updatedVehicle
      });
      dispatch({
        type: Actions.CLEAR_ALACARTE_SERVICES
      });
      // @note: redirect to edit vehicle page based on speed bump selection
      if (nextAction === "EDIT_VEHICLE") {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: EDIT_VEHICLE
        });
      } else {
        const futureAppointment = await vehicleService.getHasFutureAppointments(
          {
            customer,
            vehicle: selectedVehicle,
            appConfig: dealer
          }
        );
        dispatch({
          type: Actions.SET_VEHICLE_FUTURE_APPOINTMENT,
          payload: futureAppointment
        });
        if (quoteServices.length === 0) {
          console.log(
            `quoteServices.length: ${quoteServices.length} ${confirmationId}`
          );
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: SEARCH_SERVICE
          });
        } else {
          if (!isEmpty(confirmationId)) {
            dispatch({
              type: Actions.SET_CURRENT_PAGE,
              payload: SERVICE_SUMMARY
            });
          }
        }
      }
    } catch (error) {
      const msg = !error ? "Error occurred while loading data" : error.message;
      console.error(msg);
    } finally {
      hideBodyMask();
      showPageLoading(false);
    }
  };
  const showNewVehicle = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: ADD_VEHICLE
    });
  };

  const closeDiscardPopup = () => {
    setShowDiscardPopup(false);
  };
  const proceedToServices = () => {
    setShowDiscardPopup(false);
    if (!isEmpty(selectedVehicle)) {
      callbackSearchServices(selectedVehicle);
    }
  };
  const redirectToEditVehicle = () => {
    setShowDiscardPopup(false);
    if (!isEmpty(selectedVehicle)) {
      callbackSearchServices(selectedVehicle, "EDIT_VEHICLE");
    }
  };
  // TODO: can we move this logic inside the context?
  // so we have the "image" mapped at the beggining
  const updateImageUrl = record => {
    const { make, model, year } = record;
    const countryCode = locale.split("_")[1];
    record.image = getVehicleImage(make, model, year, countryCode);
    return record;
  };
  const vehiclePopup = (
    <ConfirmPopup
      title={localeStrings["sq.common.alert_lbl"]}
      message={localeStrings["sq.newquote.vehicle.ymm_warning"]}
      show={showDiscardPopup}
      okText={localeStrings["sq.newquote.vehicle.proceed_services_lbl"]}
      cancelText={localeStrings["sq.newquote.vehicle.select_vehicle_attr_lbl"]}
      okAction={proceedToServices}
      cancelAction={redirectToEditVehicle}
      hideCancel={false}
      hideOk={false}
      specialCase={true}
      callbackClose={closeDiscardPopup}
      cancelBtnStyle="primary"
      buttonStyle="primary"
    />
  );
  return (
    <div className="sq-flex-container">
      <div className="vehicle-outer">
        {customer.vehicles.length === 0 ? (
          <div className="sq-no-results">
            <b>{localeStrings["sq.common.no_results_found_lbl"]}</b>
          </div>
        ) : null}

        <div className="vehicle-grid">
          {customer.vehicles.map(vehicle => {
            const keycode = `key-${vehicle.vehicleId.toString()}`;
            const vehicleRecord = updateImageUrl(vehicle);
            return (
              <VehicleCard
                key={keycode}
                vehicleRow={vehicleRecord}
                showActionIcon={true}
                clicked={() => itemSelector(vehicleRecord)}
              />
            );
          })}
        </div>

        <div className="compact-hr-style" />

        <div className="sq-links-container">
          <span
            className="hand-cursor"
            id="addNewVehicleLink"
            onClick={showNewVehicle}
          >
            {localeStrings["sq.newquote.customer.add_new_vehicle_lbl"]}
          </span>
        </div>
      </div>
      {vehiclePopup}
    </div>
  );
};

export default VehicleResults;
