export const mockRoHistoryRecord = {
  success: true,
  historicalRepairOrders: [
    {
      roSeqId: 601941,
      dealerCode: "COXAUTODMSPLUSFSXX1",
      dmsId: 1,
      roId: "4000000142",
      vin: "1N4AL3AP4FC109485",
      customerId: "58058974396",
      createDate: "2025-01-06",
      finalDate: "2025-01-06",
      modDateTime: [2025, 1, 6, 13, 53, 14, 525586000],
      repairOrder: {
        audit: {
          createdOn: "2025-01-06T21:49:29Z",
          updatedOn: "2025-01-06T21:53:09Z",
          createdBy: null,
          createdFor: null,
          updatedBy: null,
          updatedFor: null,
          lastCorrelationId: null,
          sourceSystem: "DMS+"
        },
        href: null,
        id: "4000000142",
        advisor: "FAF",
        advisorFirstName: "Full Schedule",
        advisorLastName: "User Advisor",
        technician: null,
        promisedDate: "2025-01-07T06:30:00Z",
        openDate: "2025-01-06T21:49:29Z",
        closeDate: "2025-01-06T21:53:09Z",
        finalCloseDate: "2025-01-06T21:53:09Z",
        waiter: false,
        transportationType: "DROPOFF",
        status: "FINALIZED",
        hangtag: "3",
        towedinindicator: null,
        totalPrice: {
          amount: 45.58,
          currency: "USD"
        },
        totalGross: null,
        appointmentDate: null,
        partsCounterPerson: null,
        partsCounterPersonFirstName: null,
        partsCounterPersonLastName: null,
        vehicle: {
          href: null,
          vehicleId: null,
          vin: "1N4AL3AP4FC109485",
          stockNumber: null,
          plateNumber: null,
          description: {
            model: {
              marketingName: "ALTIMA",
              modelCode: null
            },
            trim: {
              marketingName: "Sedan",
              subTrim: null
            },
            make: "NISSAN",
            manufacturer: null,
            marketingName: null,
            title: null,
            year: 2015
          },
          odometer: {
            status: null,
            mileage: 3,
            unitOfMeasure: "mile",
            date: null
          },
          dealerAttributes: null,
          owners: null,
          specifications: {
            colors: {
              exterior: {
                normalizedName: "Red",
                oemName: null,
                oemOptionCode: null
              },
              interior: null
            },
            keyCodes: null,
            bodyStyle: null,
            fuelType: null,
            cityEfficiency: null,
            highwayEfficiency: null,
            combinedEfficiency: null,
            cylinders: null,
            isTruck: null,
            isFourWheelDrive: null,
            engineDescription: null,
            transmissionDescription: null,
            grossWeight: null,
            equipmentOptions: null,
            packageOptions: null,
            engineSize: "2.5L",
            driveType: "FWD",
            transmissionType: "CVT"
          },
          notes: null,
          audit: null
        },
        customer: {
          audit: null,
          href: null,
          customerId: null,
          name: {
            title: null,
            firstName: "Test",
            alias: null,
            lastName: "Csr",
            middleName: null,
            preferredName: null,
            legalName: null,
            suffix: null
          },
          legacyDMSId: null,
          taxExemptId: null,
          allowDataSharing: null,
          addresses: [
            {
              id: null,
              type: "CURRENT",
              street1: null,
              street2: "US",
              city: null,
              stateOrProvince: "UT",
              postalCode: null,
              countyOrRegion: null,
              country: "US"
            }
          ],
          phones: [
            {
              id: null,
              type: "HOME",
              phoneNumber: "9898980000",
              extension: null,
              textEnabled: null,
              doNotCallList: null,
              preferred: null
            },
            {
              id: null,
              type: "CELL",
              phoneNumber: "7038610000",
              extension: null,
              textEnabled: null,
              doNotCallList: null,
              preferred: null
            }
          ],
          emails: [
            {
              id: null,
              type: "PRIMARY",
              email: "kristine.neghban@coxautoinc.com.ss",
              preferred: null
            }
          ],
          privacy: null,
          personal: null,
          ownedVehicles: null,
          note: null
        },
        payers: [
          {
            payTypeGroup: "CUSTOMERPAY",
            payTypeCode: "C",
            payerId: 76342,
            payerCode: null,
            payments: [
              {
                paymentMethod: "CASH",
                checkNumber: null,
                amount: 45.58
              }
            ],
            total: {
              amount: 45.58,
              currency: "USD"
            },
            totalFees: null,
            totalDiscounts: null,
            subTotalHazmat: null,
            deductible: null,
            shopSupplies: null,
            subTotalLinePartsBasePrice: null,
            subTotalLineLaborBasePrice: {
              amount: 42.5,
              currency: "USD"
            },
            subTotalLineLaborFees: null,
            serviceContract: null,
            warranties: null,
            miscChargesAndFees: null,
            subTotalLineDiscounts: null,
            subTotalRODiscounts: null,
            subTotalBeforeTaxes: {
              amount: 42.5,
              currency: "USD"
            },
            salesTax: {
              amount: 3.08,
              currency: "USD"
            },
            finalPrice: {
              amount: 45.58,
              currency: "USD"
            },
            subTotalServiceContracts: null,
            subTotalDeposits: null,
            subTotalSublets: null,
            tax: [
              {
                type: "Sales",
                finalAmount: {
                  amount: 2.06,
                  currency: "USD"
                },
                jurisdictionCode: "49",
                jurisdictionName: "UTAH"
              },
              {
                type: "Sales",
                finalAmount: {
                  amount: 0.57,
                  currency: "USD"
                },
                jurisdictionCode: "035",
                jurisdictionName: "SALT LAKE"
              },
              {
                type: "Sales",
                finalAmount: null,
                jurisdictionCode: "20120",
                jurisdictionName: "DRAPER"
              },
              {
                type: "Sales",
                finalAmount: {
                  amount: 0.45,
                  currency: "USD"
                },
                jurisdictionCode: "BTST",
                jurisdictionName: "SALT LAKE CO TR"
              }
            ],
            discounts: null,
            fees: null,
            closeDate: "2025-01-06T21:53:09Z"
          }
        ],
        transfers: null,
        internalAuthBy: null,
        franchiseCode: null,
        totals: null,
        mileageIn: {
          status: null,
          mileage: 3,
          unitOfMeasure: "mile",
          date: null
        },
        mileageOut: {
          status: null,
          mileage: 3,
          unitOfMeasure: "mile",
          date: null
        },
        checkNumber: null,
        purchaseOrderNumber: null,
        receiptNumber: null,
        services: [
          {
            audit: {
              createdOn: null,
              updatedOn: "2025-01-06T21:52:26Z",
              createdBy: null,
              createdFor: null,
              updatedBy: null,
              updatedFor: null,
              lastCorrelationId: null,
              sourceSystem: "DMS+"
            },
            href: null,
            concern: "Testing Concern from Catalog US1366823",
            cause: "Testing Cause from CAtalog US1366823",
            correction: "Testing correction from catalog US1366823",
            notes: "",
            name: "Rotate tires",
            kind: null,
            id: "3b316699-4645-cdcf-8002-914e28b5651c",
            operationCode: "OCM",
            transDate: null,
            completionDate: "2025-01-06T21:52:17Z",
            payTypeCode: "C",
            payTypeDescription: "Customer Pay",
            payTypeGroup: "CUSTOMERPAY",
            departmentSubType: null,
            costAllocationSubType: null,
            technicians: null,
            xServiceType: null,
            retailAmount: {
              amount: 20,
              currency: "USD"
            },
            finalPartsPrice: null,
            warrantySubmissionState: null,
            servicePrice: {
              amount: 20,
              currency: "USD"
            },
            lineServicePrice: {
              amount: 20,
              currency: "USD"
            },
            estimatedPrice: null,
            serviceType: {
              audit: null,
              href: null,
              code: "MR",
              description: null,
              active: null
            },
            status: "C",
            totalPriceOverridden: null,
            sequenceNumber: 3,
            comments: null,
            parts: null,
            policyAdjustment: null,
            labor: {
              laborHours: 0.4,
              laborAmount: {
                amount: 20,
                currency: "USD"
              },
              laborRate: 0,
              laborCostOverride: false,
              oem: null,
              warrantyType: null,
              code: null
            },
            sublets: null,
            fees: null,
            discounts: null
          },
          {
            audit: {
              createdOn: null,
              updatedOn: "2025-01-06T21:52:26Z",
              createdBy: null,
              createdFor: null,
              updatedBy: null,
              updatedFor: null,
              lastCorrelationId: null,
              sourceSystem: "DMS+"
            },
            href: null,
            concern: "Concern test",
            cause: "Cause test",
            correction: "Correction test",
            notes: "",
            name: "Replace engine oil and filter",
            kind: null,
            id: "6aeef559-cdd5-ac56-2c6e-36b42dd22221",
            operationCode: "OCM",
            transDate: null,
            completionDate: "2025-01-06T21:52:13Z",
            payTypeCode: "C",
            payTypeDescription: "Customer Pay",
            payTypeGroup: "CUSTOMERPAY",
            departmentSubType: null,
            costAllocationSubType: null,
            technicians: null,
            xServiceType: null,
            retailAmount: {
              amount: 22.5,
              currency: "USD"
            },
            finalPartsPrice: null,
            warrantySubmissionState: null,
            servicePrice: {
              amount: 22.5,
              currency: "USD"
            },
            lineServicePrice: {
              amount: 22.5,
              currency: "USD"
            },
            estimatedPrice: null,
            serviceType: {
              audit: null,
              href: null,
              code: "MR",
              description: null,
              active: null
            },
            status: "C",
            totalPriceOverridden: null,
            sequenceNumber: 2,
            comments: null,
            parts: null,
            policyAdjustment: null,
            labor: {
              laborHours: 0.5,
              laborAmount: {
                amount: 22.5,
                currency: "USD"
              },
              laborRate: 0,
              laborCostOverride: false,
              oem: null,
              warrantyType: null,
              code: null
            },
            sublets: null,
            fees: null,
            discounts: null
          }
        ],
        eventLogs: [
          {
            serviceLineId: "10",
            timestamp: "2025-01-06T21:50:06Z",
            description: "RO has been updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:50:07Z",
            description: 'Mileage in set to "3"',
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:50:07Z",
            description: 'Hang tag set to "3"',
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:50:07Z",
            description: "RO checked in",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: "10",
            timestamp: "2025-01-06T21:50:46Z",
            description: "RO has been updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:50:47Z",
            description: "RO updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: "10",
            timestamp: "2025-01-06T21:52:26Z",
            description: "RO has been updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:52:27Z",
            description: "RO updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:53:02Z",
            description: "Mileage out updated",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:53:10Z",
            description: "Customer split payment CASH - $45.58.",
            user: "Gupta,vaibhav",
            event: "Payment",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:53:10Z",
            description: "Closed Customer pay by Split",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:53:10Z",
            description: "All payers closed",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: null,
            timestamp: "2025-01-06T21:53:10Z",
            description: "Status updated to Finalized",
            user: "Gupta,vaibhav",
            event: "Repair order",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: null,
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: "170803",
            timestamp: "2025-01-06T21:50:33Z",
            description: "Part removed",
            user: "Gupta,vaibhav",
            event: "Parts",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: "2",
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: "170803",
            timestamp: "2025-01-06T21:50:33Z",
            description: "Part removed",
            user: "Gupta,vaibhav",
            event: "Parts",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: "2",
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          },
          {
            serviceLineId: "170803",
            timestamp: "2025-01-06T21:50:33Z",
            description: "Part removed",
            user: "Gupta,vaibhav",
            event: "Parts",
            enterpriseCode: null,
            headerOperation: null,
            companyNumber: null,
            repairOrderNumber: null,
            updateTimeString: null,
            estimateTotal: null,
            convertFrom: null,
            miscType: null,
            roLineStatus: null,
            updateVendor: null,
            sequenceNumber: "2",
            laborCost: null,
            amount: null,
            serviceWriter: "false",
            laborNet: null,
            payMethod: null,
            odometer: null,
            repairOrderStatus: null,
            updateProgram: null,
            laborHours: null,
            tagNumber: null,
            lineType: null,
            technicianId: null,
            updateUser: null,
            counterPerson: null,
            laborCode: null,
            printer: null,
            printedCopies: null,
            estimateHours: null,
            serviceType: null,
            teamId: null,
            createdTZString: null,
            updatedTZ: null,
            updatedTZString: null,
            journalSequence: null,
            updatedUser: null
          }
        ],
        menu: {
          details: {
            name: "5,000 miles - Severe  (2014-Current)",
            id: "b6cfc054-81b1-d9cc-46ab-70295ee4153c",
            payTypeCode: "C",
            technicians: null,
            sublets: null,
            fees: null,
            discounts: null,
            retailAmount: {
              amount: 42.5,
              currency: "USD"
            },
            labor: {
              laborAmount: {
                amount: 42.5,
                currency: "USD"
              },
              laborCostOverride: null
            },
            finalPartsPrice: {
              amount: null,
              currency: "USD"
            },
            sequenceNumber: 1
          },
          services: [
            "3b316699-4645-cdcf-8002-914e28b5651c",
            "6aeef559-cdd5-ac56-2c6e-36b42dd22221"
          ],
          inspections: [
            {
              name: "Inspect brake pads",
              id: "aca4d5cf-deb0-270a-a079-66141a595372"
            },
            {
              name: "Inspect axle & suspension parts",
              id: "f7418f1d-9692-df81-3b96-d4da911c268a"
            },
            {
              name: "drums & linings",
              id: "e11d7ab6-5dba-75e0-d658-f6d6bf20780b"
            },
            {
              name: "Inspect drive shaft boots",
              id: "c870071e-fe6b-075c-e2d9-5abf4922a7e0"
            },
            {
              name: "Inspect front suspension ball joints",
              id: "47184db8-1342-3123-0cbb-2b90ca6f853d"
            },
            {
              name: "rotors",
              id: "82eb2603-788c-8642-ef03-05cee8ee0263"
            },
            {
              name: "Inspect exhaust system",
              id: "93d8692c-8bdc-4622-e8ac-997c60c41c31"
            }
          ]
        }
      }
    }
  ]
};
