/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { Actions, useEditServiceContext } from "../state/edit-service.context";
import { AppContext } from "../../state/app-context";
import isEmpty from "lodash/isEmpty";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import { updateEditSettings } from "../state/edit-service.config";
import priceCalculationUtil from "../utils/service-calculation.util";
import {
  isCSRAppAndNotWithAdvisor,
  isDealerTireService,
  isDMSPlus,
  isEditingServiceOrMenu,
  isSaveButtonDisabled,
  isMenuServiceSelected,
  isDTDMS
} from "../utils/service.util";
import { transformPartsWithDMSParts } from "../../PartsLookupModule/utils/parts.util";
import * as gtmEvent from "../../features/utils/gtag/gtag-event.util";
import {
  catalogSources,
  operationSources,
  payTypeCodes,
  serviceTypes
} from "../utils/edit-service.constants";
import { appSources, appTypes } from "../../constants/app.constants";
import ServiceHeader from "../components/ServiceHeader";
import { VehicleAttributes } from "../containers/VehicleAttributes";
import { ServiceOptions } from "../containers/ServiceOptions";
import ServiceLineCompleteComponent from "../components/csr/service-line-complete.component";
import {
  handleGlobalCatalogOperation,
  handleMenuOperation,
  handlePropService,
  loadAdvisorTechnicianList,
  loadCatalogFeesForService,
  loadExpenseCodeForWarranty,
  validateAttributes,
  validateGlobalCatalogAttributes
} from "../service/edit-service-module.service";
import { isDMSPInternalAccAvailable } from "../utils/labor-calculation.util";
import { setCacheValue } from "../../utils/cache.util";
import { CACHE_NAMES } from "../../constants/cache-names.constants";
import { filterServiceContracts } from "../../features/service-contract/utils/service-contracts.util";

export default function useEditServiceModule(props) {
  const {
    appType,
    dealerCode,
    // prop {rawOperationDetails} passed in both search, summary flows
    rawOperationDetails,
    commonConsumerId,
    debugMode,
    // prop {service} - Regular service/MENU service is passed for search flow;
    // prop {service} - MENU service passed for summary flow
    // prop {currentEditingService} is NULL for search flow(Regular/MENU service);
    service: propService, // this props.service have edit service datamodel, this used to update context.service for search flow; null for summary flow
    payTypes,
    serviceTypes: serviceTypesList,
    serviceTypesList: updatedServiceTypeList,
    vendorList,
    serviceContracts,
    payTypeSubTypes,
    costAllocationTypes,
    partsPricingAndInventory,
    localeStrings,
    username,
    writeLogEntry,
    onCancelHandler,
    onSaveHandler,
    onSaveAnotherHandler,
    onServiceChange,
    quoteSummary,
    // prop {currentEditingService} is NULL for search flow; prop {currentEditingService} passed with "quoteService" for summary flow
    currentEditingService,
    confirmationId,
    vehicle,
    userPermissions,
    onChangePaytype,
    dealerProperties
  } = props;
  const { state, dispatch } = useEditServiceContext();
  // for schemaName
  const appContext = useContext(AppContext);
  const {
    dealer: { dmsType }
  } = appContext;
  const isDMSPlusDealer = isDMSPlus(dmsType);

  const newCostAllocationAccounts =
    appContext?.dealerProperties?.DMSP_INTERNAL_ACCOUNTS;
  const isDMSPInternalAccountsAvailable = isDMSPInternalAccAvailable(
    newCostAllocationAccounts
  );
  /**
   * @note !important:
   * All the constants declarations should be located under this comment block
   */

  // laborApps will be available only for dealer pub, global repair services
  const {
    settings,
    service: stateService,
    errors,
    changed,
    laborApp,
    filteredParts,
    vehicleAttributes
  } = state;

  const [serviceType, setServiceType] = useState("");
  const [serviceIsValid, setServiceIsValid] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const [showNotesWarning, setShowNotesWarning] = useState(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [make, setMake] = useState("");
  const errorExists = Object.values(errors).some(Boolean);
  const [payCodeProp, setPayCodeProp] = useState("");
  const [serviceCodeProp, setServiceCodeProp] = useState("");

  let serviceLineWidget = null;

  const quoteServices = props.quoteSummary?.quoteServices;
  const currentServiceId = props.service?.labor?.serviceId;

  const isSaveDisabled = isSaveButtonDisabled(
    stateService,
    serviceType,
    laborApp,
    currentEditingService,
    dealerProperties,
    isDMSPlusDealer,
    isDMSPInternalAccountsAvailable,
    props
  );

  const isEngageRO =
    appContext.appType === appTypes.CSR &&
    appContext.appSource === appSources.ENG;

  useComponentDidMount(() => {
    dispatch({ type: Actions.SET_APP_TYPE, payload: appType });
    dispatch({
      type: Actions.SET_DEALER_PROPERTIES,
      payload: dealerProperties
    });
    dispatch({
      type: Actions.SET_DEALER_COMPANIES,
      payload: appContext.dealerCompanies
    });

    if (appType === appTypes.CSR) {
      loadAdvisorTechnicianList(
        dispatch,
        dealerCode,
        appContext.schemaName,
        localeStrings
      );
      loadExpenseCodeForWarranty(
        dispatch,
        dealerCode,
        vehicle?.make,
        appContext?.dealer?.dealerCountryCode
      );

      dispatch({
        type: Actions.SET_EDIT_SETTINGS,
        payload: updateEditSettings(appType)
      });
    }

    //* NOTE: added for getting latest discounts and fees to update catalogFess when payType changes in edit/summary service flow
    if (!isEmpty(currentEditingService) && !isDTDMS(dealerProperties)) {
      loadCatalogFeesForService(dispatch, currentEditingService, vehicle);
    }

    dispatch({
      type: Actions.SET_COMMON_CONSUMER_ID,
      payload: commonConsumerId
    });
    dispatch({ type: Actions.SET_DEBUG_MODE, payload: debugMode });
    dispatch({ type: Actions.SET_USERNAME, payload: username });
    dispatch({ type: Actions.SET_LOGGED_IN_USER, payload: appContext.user });
    dispatch({
      type: Actions.SET_USER_PERMISSIONS,
      payload: userPermissions
    });
    dispatch({ type: Actions.SET_VEHICLE, payload: vehicle });
    dispatch({
      type: Actions.SET_RAWOPERATION_DETAILS,
      payload: rawOperationDetails
    });

    if (!isEmpty(rawOperationDetails)) {
      setMake(rawOperationDetails.make);
      const { operationSource, catalogSource, serviceKind } =
        rawOperationDetails;
      const isDeclinedService = operationSource === operationSources.DECLINED;
      const serviceTypeName = priceCalculationUtil.getServiceType(
        isDeclinedService ? catalogSource ?? operationSource : operationSource,
        isDeclinedService ? serviceTypes.DECLINED : serviceKind
      );

      setServiceType(serviceTypeName);
      dispatch({ type: Actions.SET_SERVICE_TYPE, payload: serviceTypeName });

      if (
        operationSource === operationSources.GLOBALCATALOG ||
        catalogSource === operationSources.GLOBALCATALOG
      ) {
        handleGlobalCatalogOperation(
          writeLogEntry,
          rawOperationDetails,
          laborApp,
          vehicle?.make
        );
      }
    }

    if (
      rawOperationDetails.operationSource !== operationSources.GLOBALCATALOG ||
      rawOperationDetails?.catalogOperationSource !==
        operationSources.GLOBALCATALOG
    ) {
      dispatch({
        type: Actions.SET_LOOKUP_PARTS,
        payload: rawOperationDetails
      });
    }
    // First time, update context.service by applying calculated logic - {MENU, Dealer Publish, Recall, Declined } cases
    // For Global repair case: service is updated using action SET_LABOR_APP when service option is selected from UI
    if (!isEmpty(propService)) {
      handlePropService(dispatch, rawOperationDetails, propService, payTypes);
    }

    if (!isEmpty(quoteSummary)) {
      dispatch({ type: Actions.SET_QUOTE_SUMMARY, payload: quoteSummary });
    }

    validateAttributes(
      dispatch,
      currentEditingService,
      stateService,
      rawOperationDetails
    );

    if (!isEmpty(currentEditingService)) {
      dispatch({
        type: Actions.SET_CURRENT_EDITING_SERVICE,
        payload: currentEditingService
      });
    }

    if (
      isEmpty(currentEditingService) &&
      (rawOperationDetails.operationSource === operationSources.GLOBALCATALOG ||
        rawOperationDetails?.catalogOperationSource ===
          operationSources.GLOBALCATALOG) &&
      !isEmpty(rawOperationDetails)
    ) {
      validateGlobalCatalogAttributes(
        dispatch,
        stateService,
        rawOperationDetails
      );
    }

    if (rawOperationDetails.operationSource === catalogSources.MENU) {
      // Method used to read payTypeCode when PayType is changed at MENU level (both flows)
      handleMenuOperation(
        currentEditingService,
        stateService,
        propService,
        onChangePaytype
      );
    }
    // End of the useComponentDidMount hook
  });

  /**
   * @dms-parts: Add/Edit Quote flows - when partsPricingAndInventory returned from DMS open track api call,
   * we update filteredParts with DMS parts pricing which contains both catalog parts + manually added parts.
   */
  useEffect(() => {
    if (
      isEmpty(partsPricingAndInventory) ||
      !Array.isArray(partsPricingAndInventory)
    ) {
      return; // Exit early if partsPricingAndInventory is not valid
    }

    // Set Parts Pricing Inventory in the state
    dispatch({
      type: Actions.SET_PARTS_PRICING_INVENTORY,
      payload: partsPricingAndInventory
    });

    // Check if rawOperationDetails is available
    if (!isEmpty(rawOperationDetails)) {
      const { catalogSource } = rawOperationDetails;

      if (!isEmpty(filteredParts)) {
        /**
         * Get correct parts from current quote context
         * both flows: globalops, dealer publish
         */
        const parts = filteredParts?.map(part => ({ ...part }));

        if (!isEmpty(parts) && Array.isArray(parts)) {
          const hasDealerTire = isDealerTireService(rawOperationDetails);

          // Transform parts with DMS part API values
          const rawParts = transformPartsWithDMSParts(
            partsPricingAndInventory,
            parts,
            catalogSource,
            hasDealerTire
          );

          // Update state if the transformed parts are different
          if (
            !isEmpty(parts) &&
            !isEmpty(rawParts) &&
            parts.length < rawParts.length
          ) {
            dispatch({
              type: Actions.SET_SERVICE_PARTS,
              payload: rawParts
            });
          }

          // Set DMS Lookup Parts in the state
          dispatch({
            type: Actions.SET_DMS_LOOKUP_PARTS,
            payload: rawParts
          });
        }
      }
    }
  }, [props.partsPricingAndInventory]);

  // @todo-edit: both flows - WIPE_STATE action called in return() when comp is destroyed
  useEffect(() => {
    console.log("mouted.");
    return () => {
      console.log("WIPE_STATE unmounting..");
      dispatch({
        type: Actions.WIPE_STATE
      });
    };
  }, []);

  // @csr-logic
  useEffect(() => {
    if (!isEmpty(payTypeSubTypes)) {
      dispatch({
        type: Actions.SET_PAY_TYPE_SUB_TYPES,
        payload: payTypeSubTypes
      });
    }
    if (!isEmpty(costAllocationTypes)) {
      dispatch({
        type: Actions.SET_PAY_TYPE_COST_ALLOCATION_TYPES,
        payload: costAllocationTypes
      });
    }
  }, [payTypeSubTypes, costAllocationTypes, currentEditingService]);

  // step2: read paytypes and prepare defaultPayTypeOption for labor comp field (paytype pre-selected)
  // read defaultPayTypeOption used payTypes[] and pass this state.dealerPayTypeId via Details, Labor component
  // search flow only - update context.service with payTypes[], paytypecode, description
  useEffect(() => {
    // Determine the service to use: currentEditingService or propService
    const serviceToUse = propService || currentEditingService;

    // Check if payTypes and serviceToUse are available
    if (!isEmpty(payTypes) && !isEmpty(serviceToUse)) {
      // Dispatch action to set payTypes in the state
      dispatch({
        type: Actions.SET_PAY_TYPES,
        payload: payTypes
      });
      // Get the default pay type using the utility function
      const selectedDefault = priceCalculationUtil.getDefaultPayTypeForService(
        serviceToUse,
        payTypes,
        rawOperationDetails.make,
        rawOperationDetails.operationSource
      );
      // when default pay type found
      if (selectedDefault) {
        const {
          payCode: selectedPayCode,
          description: selectedDescription,
          payTypeGroup: selectedPayTypeGroup
        } = selectedDefault;

        // Set the payCodeProp with dealerPayTypeId
        const defaultPayTypeOption = selectedDefault
          ? selectedDefault?.dealerPayTypeId.toString()
          : "";
        setPayCodeProp(defaultPayTypeOption);
        dispatch({
          type: Actions.SET_PAYTYPE_DETAILS,
          payload: {
            payTypeCode: selectedPayCode,
            payTypeDescription: selectedDescription,
            payTypeGroup: selectedPayTypeGroup
          }
        });
      }
    } else {
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "payTypes",
          value: Array.isArray(payTypes) && payTypes.length === 0
        }
      });
    }
  }, [payTypes, currentEditingService]);
  // step3: read "serviceTypesList" and prepare defaultServiceTypeOption for labor comp field (serviceType pre-selected)
  // read defaultServiceTypeOption used "serviceTypesList[]" and pass this state.dealerServiceTypeId via Details, Labor component
  // search flow only - update context.service with "serviceTypesList[]", serviceTypeCode, description
  useEffect(() => {
    if (!isDMSPlusDealer) return;
    // Determine the service to use: currentEditingService or propService
    const serviceToUse = propService || currentEditingService;
    const serviceList = serviceTypesList || updatedServiceTypeList;

    // Check if serviceTypes and serviceToUse are available
    if (!isEmpty(serviceList) && !isEmpty(serviceToUse)) {
      // Dispatch action to set serviceTypes in the state
      dispatch({
        type: Actions.SET_SERVICE_TYPES,
        payload: serviceList
      });

      // Get the default servicetype using the util function
      const selectedDefault = priceCalculationUtil.getDefaultServiceType(
        serviceToUse,
        serviceList,
        rawOperationDetails.operationSource
        // appType
      );
      // when default service type found
      if (selectedDefault) {
        const {
          serviceTypeCode: selectedServiceTypeCode,
          description: selectedServiceTypeDescription
        } = selectedDefault;
        // Set the serviceCodeProp with dealerServiceTypeId
        const defaultServiceTypeOption = selectedDefault
          ? selectedDefault?.dealerServiceTypeId.toString()
          : "";
        setServiceCodeProp(defaultServiceTypeOption);
        dispatch({
          type: Actions.SET_SERVICETYPE_DETAILS,
          payload: {
            serviceTypeCode: selectedServiceTypeCode,
            serviceTypeDescription: selectedServiceTypeDescription
          }
        });
      }
    } else {
      // @note: For DMS+ dealers, serviceType field is required
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "serviceTypes",
          value: false
          // TODO: Once API working, Add validation check if serviceTypes list is empty for DMS+ dealers
          // value : Array.isArray(serviceTypesList) && serviceTypesList.length === 0
        }
      });
    }
  }, [serviceTypesList, currentEditingService, updatedServiceTypeList]);

  useEffect(() => {
    // Determine the service to use: currentEditingService or propService
    const serviceToUse = propService || currentEditingService;

    // Check if vendorList and serviceToUse are available
    if (!isEmpty(vendorList) && !isEmpty(serviceToUse)) {
      // Dispatch action to set vendorList in the state
      dispatch({
        type: Actions.SET_VENDOR_LIST,
        payload: vendorList
      });
    } else {
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "vendorList",
          value: false
        }
      });
    }
  }, [vendorList, currentEditingService]);

  useEffect(() => {
    // Determine the service to use: currentEditingService or propService
    const serviceToUse = propService || currentEditingService;

    // Check if vendorList and serviceToUse are available
    if (!isEmpty(serviceContracts) && !isEmpty(serviceToUse)) {
      // Dispatch action to set vendorList in the state
      dispatch({
        type: Actions.SET_SERVICE_CONTRACTS,
        payload: filterServiceContracts(serviceContracts)
      });
    } else {
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "serviceContracts",
          value: false
        }
      });
    }
  }, [serviceContracts, currentEditingService]);
  // step-4: Global ops case:
  // @search-flow - call price util method to add price-related fields {overrides, calculated, final } to context.service
  // @summary-flow, currentEditingService dispatch action will set price-related fields {overrides, calculated, final } to context.service
  // @note context.service will be updated using action SET_LABOR_APP

  // verify both flows
  useEffect(() => {
    const anyErrors = Object.keys(errors).some(key => errors[key]);
    const anyChanged = Object.keys(changed).some(key => changed[key]);
    if (anyErrors) {
      setServiceIsValid(false);
    } else {
      setServiceIsValid(true);
    }
    if (anyChanged) {
      setServiceHasChanged(true);
    } else {
      setServiceHasChanged(false);
    }
  }, [errors, changed]);

  // verify both flows
  useEffect(() => {
    onServiceChange(serviceHasChanged);
  }, [serviceHasChanged]);

  // @search-flow: Global ops case: when service had vehicle attr and service options; user need to complete these steps to see Details component fields
  useEffect(() => {
    if (isEmpty(currentEditingService)) {
      if (
        !isEmpty(rawOperationDetails) &&
        isEmpty(rawOperationDetails.selectableVehicleAttributes)
      ) {
        dispatch({
          type: Actions.SET_STEP_COMPLETED,
          payload: { step: "vehicleAttr", completed: true }
        });
      }
      if (
        !isEmpty(rawOperationDetails) &&
        isEmpty(rawOperationDetails.laborApps)
      ) {
        dispatch({
          type: Actions.SET_STEP_COMPLETED,
          payload: { step: "serviceOptions", completed: true }
        });
      }
    }
  }, [rawOperationDetails]);

  /**
   * @param {string} serviceType
   * @returns {array} ex: [<VehicleAttributes />, <ServiceOptions />,]
   */
  const getComponentStack = serviceType => {
    const sections = settings[serviceType];
    const compMap = {
      searchHeader: !isEmpty(stateService) && (
        <ServiceHeader key="serviceHeaderCmp" /> // service={stateService}
      ),
      vehicleAttr: !isEmpty(rawOperationDetails) &&
        !isEmpty(rawOperationDetails.selectableVehicleAttributes) &&
        !isEmpty(rawOperationDetails.vehicleAttributes) && (
          <VehicleAttributes key="vehicleAttributesCmp" />
        ),
      serviceOptions: !isEmpty(rawOperationDetails) &&
        !isEmpty(rawOperationDetails.laborApps) && (
          <ServiceOptions key="serviceOptionsCmp" />
        )
    };
    return Object.values(
      Object.fromEntries(
        Object.entries(compMap).filter(([key]) => sections[key])
      )
    );
  };

  /**
   * Fixes circular references caused by parts that
   * have been added to their own alternateParts array.
   * @param service Service to fix.
   * @returns {object} Service with alternateParts circular references removed.
   */
  const removeCircularReferenceParts = service => {
    service?.parts?.forEach(part => {
      part?.alternateParts?.forEach(alternatePart => {
        if (alternatePart && alternatePart.alternateParts) {
          alternatePart.alternateParts = [];
        }
      });
    });
    return service;
  };

  const handleSave = addAnother => {
    if (serviceIsValid) {
      const cleanedService = removeCircularReferenceParts(stateService);
      if (addAnother) {
        onSaveAnotherHandler({
          ...{ ...cleanedService, vehicleAttributes },
          laborApp,
          filteredParts
        });
        gtmEvent.trackGAEventWithParam(
          "ga.newquote.save_another_service_click",
          {
            result: `${cleanedService.operationSource} - ${cleanedService.name}`,
            location: "edit-service"
          }
        );
      } else {
        onSaveHandler({
          ...{ ...cleanedService, vehicleAttributes },
          laborApp,
          filteredParts
        });

        const serviceHasServiceContract =
          !isEmpty(cleanedService?.serviceContract) &&
          cleanedService?.payTypeCode === payTypeCodes.SERVICE_CONTRACT;

        if (serviceHasServiceContract) {
          const quoteId = quoteSummary?.quoteId;
          const cacheData = quoteId
            ? {
                isCreating: false,
                numberOfTransfers: quoteSummary?.transfers?.length || 0
              }
            : {
                isCreating: true,
                numberOfTransfers: null
              };

          setCacheValue(
            CACHE_NAMES.DEDUCTIBLES,
            quoteId || CACHE_NAMES.DEDUCTIBLES,
            cacheData
          );
        }

        gtmEvent.trackGAEventWithParam("ga.newquote.save_service_click", {
          result: `${cleanedService.operationSource} - ${cleanedService.name}`,
          location: "edit-service"
        });
      }
    } else {
      if (errorExists) {
        setShowNotesWarning(true);
      }
    }
  };

  const handleCancel = e => {
    e.preventDefault();
    onCancelHandler(true);
  };

  if (
    isCSRAppAndNotWithAdvisor(props) &&
    isEditingServiceOrMenu(serviceType, currentEditingService, propService)
  ) {
    serviceLineWidget = (
      <ServiceLineCompleteComponent
        isMenuServiceSelected={isMenuServiceSelected(
          serviceType,
          quoteServices,
          currentServiceId
        )}
      />
    );
  }

  return {
    appContext,
    localeStrings,
    commonConsumerId,
    currentEditingService,
    stateService,
    confirmationId,
    errors,
    serviceType,
    rawOperationDetails,
    settings,
    partsPricingAndInventory,
    payCodeProp,
    showNotesWarning,
    errorExists,
    isEngageRO,
    isSaveDisabled,
    serviceLineWidget,
    serviceCodeProp,
    getComponentStack,
    handleSave,
    handleCancel,
    isMenuServiceSelected
  };
}
