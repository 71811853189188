import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import isEmpty from "lodash/isEmpty";
import ConfirmPopup from "../../../components/ui/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import serviceDataMapper from "../../../utils/service-data-mapper";
import { saveActionTypes } from "../../../constants/search.constants";

const EditGlobalRepair = props => {
  const { service: globalrepairAPIService, EditServiceModule } = props;
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const payTypes = state.payTypes;
  const backToService = () => {
    props.updateParentState("GlobalCatalog", false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
  };
  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };
  const handleServiceUpdate = updatedService => {
    const newService = serviceDataMapper.updateServiceGlobalRepair(
      globalrepairAPIService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };
  const saveServiceAndGoBack = updatedService => {
    const newService = serviceDataMapper.updateServiceGlobalRepair(
      globalrepairAPIService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    backToService();
  };
  // @todo-paytype: trigger parent handler to pass paytype value
  const asyncUpdatePartsByPaytype = async (payType, editedService) => {
    // globalrepairAPIService.payTypeCode = paytype;
    // ctxRequestPartsInventoryCommon(globalrepairAPIService, editedService);
    ctxRequestPartsInventoryCommon(
      {
        ...globalrepairAPIService,
        payTypeCode: payType,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  // @todo-serviceType: trigger parent handler to pass servicetype value
  const asyncUpdatePartsByServiceType = async (serviceType, editedService) => {
    ctxRequestPartsInventoryCommon(
      {
        ...globalrepairAPIService,
        serviceTypeCode: serviceType,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  const asyncUpdatePartsByServiceContract = async (
    serviceContract,
    editedService
  ) => {
    ctxRequestPartsInventoryCommon(
      {
        ...globalrepairAPIService,
        serviceContract,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription
      },
      editedService
    );
  };
  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  const clonedEditServiceModule = !isEmpty(globalrepairAPIService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        rawOperationDetails: globalrepairAPIService,
        service: serviceDataMapper.editModulePropsFromService(
          globalrepairAPIService
        ),
        localeStrings,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        // @todo: events trigger callback handlers of ServiceSearchWrapper
        onChangePaytype: asyncUpdatePartsByPaytype,
        onChangeServiceType: asyncUpdatePartsByServiceType,
        onChangeServiceContract: asyncUpdatePartsByServiceContract,
        onServiceChange: handleServiceChange
      })
    : null;
  const editPage = !isEmpty(globalrepairAPIService)
    ? clonedEditServiceModule
    : "";
  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );
  return (
    <>
      <div id="editGlobalRepair" className="search-flex-grid-container">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        <div className="edit-page-wrapper">{editPage}</div>
      </div>
      {discardEditPopup}
    </>
  );
};

export default memo(EditGlobalRepair);

EditGlobalRepair.defaultProps = {
  service: null,
  updateParentState: () => {},
  EditServiceModule: null
};
EditGlobalRepair.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
