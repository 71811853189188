// These constants used across new quote flow to check quote status values
const QuoteStatusConstant = {
  STARTED: "STARTED",
  PROGRESS: "IN_PROGRESS",
  REQUEST_ASSISTANCE: "REQUEST_ASSISTANCE",
  READY_TO_SEND: "READY_TO_SEND",
  SENT: "SENT",
  EXPIRED: "EXPIRED",
  CONVERTED_TO_APPOINTMENT: "CONVERTED_TO_APPOINTMENT",
  PRE_RO: "PRE_RO"
};

export default QuoteStatusConstant;

export const roStatusOptions = {
  WITH_ADVISOR: "WITH_ADVISOR",
  IN_PROCESS: "IN_PROCESS",
  WORK_FINISHED: "WORK_FINISHED",
  PRE_INVOICE: "PRE_INVOICE",
  FINALIZED: "FINALIZED"
};
export const partStatus = {
  APPROVED: "Approved",
  REQUESTED: "Requested"
};

export const payTypeCodes = {
  WARRANTY: "W",
  CUSTOMER: "C",
  INTERNAL: "I",
  SERVICE_CONTRACT: "S"
};

export const paymentStatus = {
  READY: "ready",
  NOTREADY: "notReady",
  CLOSED: "closed"
};

export const warrantySubmissionStates = {
  SUBMITTED: "SUBMITTED",
  READY: "READY"
};

export const warrantyBadgeLabels = {
  SUBMITTED_TO_OEM: "Submitted to OEM",
  PENDING_SUBMISSION: "Pending submission to OEM"
};
export const warrantyBadgeLabelsSubmit = {
  READY_TO_SUBMIT_TO_OEM: "Ready to submit to OEM",
  SUBMITTED_TO_OEM: "Submitted To OEM"
};
export const warrantyBadgeLabelsNew = {
  WARRANTY_READY: "Warranty ready",
  PENDING_WARRANTY_REVIEW: "Pending warranty review"
};

export const invoiceBadgeLabels = {
  READY: "Invoice ready",
  PENDING: "Invoice pending"
};

export const serviceLineBadgeLabels = {
  COMPLETED: "Service completed"
};

export const badgeColors = {
  PURPLE: "purple",
  GRAY: "gray"
};

export const serviceContractBadgeLabels = {
  PENDING: "Service Contract pending approval",
  APPROVED: "Service Contract approved"
};

export const QuoteServiceTypes = {
  DEALER_PUB_MAINT_OPS: "DEALER_PUB_MAINT_OPS",
  DEALER_PUB_REPAIR_OPS: "DEALER_PUB_REPAIR_OPS",
  GLOBAL_REPAIR_OPS: "GLOBAL_REPAIR_OPS",
  MENU: "MENU",
  DECLINED: "DECLINED",
  RECALL: "RECALL"
};
