export const operationSources = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DECLINED: "DECLINED",
  MENU: "MENU",
  RECALL: "RECALL"
};

export const catalogSources = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  MENU: "MENU"
};

export const serviceTypes = {
  GLOBALREPAIR: "globalRepair",
  DEALERPUBLISHED: "dealerPublished",
  DIAGNOSIS: "diagnosis",
  DECLINED: "declined",
  RECALL: "recall",
  MENU: "menu",
  TELLUSMORE: "tellusMore"
};
export const payTypeGroups = {
  CUSTOMERPAY: "Customer Pay",
  INTERNAL: "Internal",
  SERVICECONTRACT: "Service Contract",
  WARRANTY: "Warranty"
};

export const dealerPublishedCategories = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance"
};

export const saveActionTypes = {
  NA: null,
  SAVE: "SAVE",
  SAVEANOTHER: "SAVEANOTHER"
};

export const roStatusOptions = {
  WITH_ADVISOR: "WITH_ADVISOR",
  IN_PROCESS: "IN_PROCESS",
  WORK_FINISHED: "WORK_FINISHED",
  PRE_INVOICE: "PRE_INVOICE",
  FINALIZED: "FINALIZED"
};

export const TECH_TIME_STATUSES = {
  NOT_STARTED: "NOT_STARTED",
  WORKING: "WORKING",
  BREAK: "BREAK",
  HOLD_PARTS: "HOLD_PARTS",
  HOLD_APPROVAL: "HOLD_APPROVAL",
  OTHER_WORK: "OTHER_WORK",
  STOP: "STOP"
};

export const payTypeCodes = {
  WARRANTY: "W",
  CUSTOMER: "C",
  INTERNAL: "I",
  SERVICE_CONTRACT: "S"
};

export const actionTypes = {
  LABOR: "LABOR",
  PARTS: "PARTS",
  LABOR_OVERRIDE: "LABOR_OVERRIDE"
};

// @note: same template used in this context and service-data-mapper (editModuleProps..() method return same object)
export const EditServiceTemplate = {
  quoteServiceId: null,
  quoteId: null,
  name: "",
  totalPrice: 0,
  // For MENU, declined, recall cases - laborAppId = null
  // For dealer-pub case - laborAppId updated from laborApps[0].laborAppId; laborAppId is same as operationId, extServiceId
  // For global ops case - state.laborApp gets updated, read laborAppId passed in quote payload
  laborAppId: null,
  labor: {
    serviceId: "", // all cases - this value should be service.id/operationId/extServiceId
    catalogLabor: null,
    notes: "", // dont bind this field with catalog API; always read modified value from UI field {notes}
    price: 0,
    time: 0,
    laborRateOverride: null,
    laborRate: null
  },
  // required for menus and all services
  defaultLaborRate: 0,
  laborRateOverride: null,
  laborRate: 0,
  dealerLaborRateId: null, // This value returned in getOperationDetails API for dealer publish, global repair, menu case {mileage api} only
  asrNotes: "", // asrNotes value exists for declined service only
  dealershipNotes: "", // Expected always string value for all services and menu services in all data mapper files
  commentsRequired: false,
  catalogLabor: null,
  opCode: "",
  subTypeId: null,
  allocationSubTypeId: null,
  internalAccount: "",
  description: "",
  operationSource: "",
  serviceKind: "",
  defaultPayTypeCode: "", // this is used to identify default paytype in Detail.js
  defaultServiceTypeCode: "", // this is used to identify default serviceType in Detail.js
  skillLevel: "",
  payTypeGroup: "",
  payTypeCode: "",
  payTypeDescription: "",
  serviceTypeCode: "",
  serviceTypeDescription: "",
  serviceContract: null,
  parts: [],
  // For search flow, when only partsPriceOverridden = true, totalPartsPriceOverride saved in partsPrice field;
  // case2: when totalPriceOverridden = true & partsPriceOverridden = true; calculatedTotalPartsPrice saved in partsPrice field;
  // For summary flow, update partsPrice field using "finalPartsPrice" value coming in get quote API
  partsPrice: 0, // FIX - NEVER UPDATE this field in context actions; treat {partsPrice} as source field of catalogAPI holds original parts price
  totalPriceOverride: null,
  totalLaborPriceOverride: null,
  totalPartsPriceOverride: null,

  calculatedLaborPrice: null, // dervied field only used within context service
  calculatedTotalPartsPrice: null,
  calculatedServicePrice: null,

  finalLaborPrice: null,
  // FIX - new field {final parts price} updated from context when any field is modified in edit service page
  finalPartsPrice: null,
  // @todo-edit: price overridden flags added to context
  laborPriceOverridden: false,
  partsPriceOverridden: false,
  totalPriceOverridden: false,
  // @csr-fields
  laborTaxCode: null,
  cause: null,
  complaint: null,
  correction: null,
  warranty: null
};
