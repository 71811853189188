import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import ConfirmPopup from "../../components/ui/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import serviceDataMapper from "../../utils/service-data-mapper";
// import { convertHoursToMinutes } from "../../utils/quote.util";
import { saveActionTypes } from "../../constants/search.constants";

const EditRecallServicePage = props => {
  const { service, updateParentState, EditServiceModule } = props;
  const { dispatch, state, ctxRequestPartsInventoryCommon, ctxOnEditService } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [recallAPIService, setRecallAPIService] = useState(null);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;

  useEffect(() => {
    setRecallAPIService(service);
  }, [service]);

  const backToService = () => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setRecallAPIService(null);
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    ctxOnEditService(false);
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const handleServiceUpdate = updatedService => {
    const newRecallService = serviceDataMapper.updateRecallService(
      recallAPIService,
      updatedService
    );
    console.log("recall service to be saved", newRecallService);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_RECALL_SERVICE,
      payload: newRecallService
    });
  };

  const saveServiceAndGoBack = updatedService => {
    const newRecallService = serviceDataMapper.updateRecallService(
      recallAPIService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_RECALL_SERVICE,
      payload: newRecallService
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (payType, editedService) => {
    ctxRequestPartsInventoryCommon(
      {
        ...recallAPIService,
        payTypeCode: payType,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  const asyncUpdatePartsByServiceType = async (serviceType, editedService) => {
    ctxRequestPartsInventoryCommon(
      {
        ...recallAPIService,
        serviceTypeCode: serviceType,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  const asyncUpdatePartsByServiceContract = async (
    serviceContract,
    editedService
  ) => {
    ctxRequestPartsInventoryCommon(
      {
        ...recallAPIService,
        serviceContract,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription
      },
      editedService
    );
  };
  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  const clonedEditServiceModule = !isEmpty(recallAPIService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        // @todo-beta: rawOperationDetails - pass recallAPIService to be converted as same datamodel like dealer publish record
        rawOperationDetails:
          serviceDataMapper.editModulePropsFromRecall(recallAPIService),
        service: serviceDataMapper.editModulePropsFromRecall(recallAPIService),
        localeStrings,
        payTypes,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        // @todo: events trigger callback handlers of ServiceSearchWrapper
        onChangePaytype: asyncUpdatePartsByPaytype,
        onChangeServiceType: asyncUpdatePartsByServiceType,
        onChangeServiceContract: asyncUpdatePartsByServiceContract,
        onServiceChange: handleServiceChange
      })
    : null;
  const editPage = !isEmpty(recallAPIService) ? clonedEditServiceModule : "";

  return (
    <>
      <div id="editRecallService" className="search-flex-grid-container">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back to results
        </span>
        <div className="edit-page-wrapper">{editPage}</div>
      </div>
      {discardEditPopup}
    </>
  );
};

export default EditRecallServicePage;

EditRecallServicePage.defaultProps = {
  service: null,
  updateParentState: () => {},
  onSaveRecall: () => {},
  EditServiceModule: null
};

EditRecallServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
