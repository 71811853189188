import React from "react";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import "./special-parts.scss";
import PropTypes from "prop-types";

const ChildrenRoBanner = ({ childrenRoNumber, onRedirection }) => {
  return (
    <Alert htmlId="childrenRoBanner" type="info">
      <div className="special-parts-banner-content">
        <span>This is the original RO of</span>
        <Button
          htmlId="childrenRoNumberLink"
          buttonStyle="link"
          onClick={onRedirection}
        >
          {childrenRoNumber ? `RO ${childrenRoNumber}` : "Pre-RO"}
        </Button>
      </div>
    </Alert>
  );
};

ChildrenRoBanner.propTypes = {
  childrenRoNumber: PropTypes.number,
  onRedirection: PropTypes.func
};

export default ChildrenRoBanner;
