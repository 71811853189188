import { appSources, appTypes } from "../../../../../constants/app.constants";

export const canUseSpecialOrderParts = appContext => {
  const isAppSourceEngage =
    appContext.appSource && appContext.appSource === appSources.ENG;
  const isCSR = appContext.appType && appContext.appType === appTypes.CSR;
  const isHistoricalRO = appContext.sourceSystem;
  const isFeatureEnabled = appContext.isCreateSpecialPartsEnabled;

  return isFeatureEnabled && isCSR && !isAppSourceEngage && !isHistoricalRO;
};

export const canMoveServiceToPreRo = (appContext, quoteSummary) => {
  const hasRoNumber = !!quoteSummary.roNumber;
  const hasChildrenRO = !!quoteSummary.preRoConfirmationId;
  return canUseSpecialOrderParts(appContext) && hasRoNumber && !hasChildrenRO;
};
