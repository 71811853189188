import format from "../../features/repair-order/utils/format";
import { NO } from "../../features/repair-order/constants/adjustment.constant";
import isEmpty from "lodash/isEmpty";
import { appTypes } from "../../constants/app.constants";
import { payTypeCodes } from "./edit-service.constants";

/**
 * Builds an array of pay type sub-type options based on the provided payTypeSubTypes array.
 * @param {Array} payTypeSubTypes - The array of pay type sub-types to filter and map.
 * @returns {Array} An array of pay type sub-type options with value and label properties.
 */
const buildPayTypeSubTypeOptions = (payTypeSubTypes = []) => {
  return (
    payTypeSubTypes
      ?.filter(
        subType =>
          (subType.type === "Department" || subType.type === undefined) &&
          subType.payTypeGroup === "Internal"
      )
      ?.map(({ subTypeId, subType }) => ({
        value: subTypeId.toString(),
        label: subType
      })) ?? []
  );
};

/**
 * Builds an array of pay type cost allocation options based on the provided costAllocationTypes array.
 * @param {Array} costAllocationTypes - The array of cost allocation types to filter and map.
 * @returns {Array} An array of pay type cost allocation options with value and label properties.
 */
const buildPayTypeCostAllocationOptions = (costAllocationTypes = []) => {
  return (
    costAllocationTypes
      ?.filter(subType => subType.payTypeGroup === "Internal")
      ?.map(({ subTypeId, subType }) => ({
        value: subTypeId.toString(),
        label: subType
      })) ?? []
  );
};

/**
 * Builds options for the warranty plan dropdown.
 * @param {Array<object>} warrantyPlans Available warranty plans.
 * @returns {Array<SelectInputOptions>} Dropdown options.
 */
const buildWarrantyPlanOptions = (warrantyPlans = []) => {
  return (
    warrantyPlans?.map(plan => {
      return {
        value: `${plan.warrantyType} - ${plan.planName}`,
        label: `${plan.warrantyType} - ${plan.planName}`
      };
    }) ?? []
  );
};

/**
 * Builds an array of service type options based on the provided list.
 *
 * @param {Array} serviceTypesList - The list of service types to be processed.
 * @returns {Array} An array of objects representing service type options with 'value' and 'label' properties.
 */
const buildServiceTypeOption = (serviceTypesList = []) => {
  /**
   * Checks if the given array is empty.
   *
   * @param {Array} arr - The array to be checked.
   * @returns {boolean} True if the array is empty, false otherwise.
   */
  const isEmpty = arr => arr.length === 0;

  return (
    (!isEmpty(serviceTypesList) &&
      serviceTypesList.map(serviceType => ({
        value: serviceType.dealerServiceTypeId.toString(),
        label: serviceType.serviceTypeCode
      }))) ||
    []
  );
};

/**
 * Builds an array of service contract options based on the provided list.
 *
 * @param {Array} serviceContractList - The list of service contracts to be processed.
 * @returns {Array} An array of objects representing service contract options with 'value' and 'label' properties.
 */
const buildServiceContractOptions = (serviceContractList = []) => {
  /**
   * Checks if the given array is empty.
   *
   * @param {Array} arr - The array to be checked.
   * @returns {boolean} True if the array is empty, false otherwise.
   */
  const isEmpty = arr => arr.length === 0;

  return (
    (!isEmpty(serviceContractList) &&
      serviceContractList.map(serviceContract => ({
        value: serviceContract?.vendor.toString(),
        label: serviceContract?.vendor || "",
        disabled: serviceContract?.disabled
      }))) ||
    []
  );
};

/**
 * Return a param from rawSerice or currentEditingService for add or edit flow.
 * @param {String} rawServiceParam - property name on rawServiceParam
 * @param {Object} stateService - StateService contains rawService node.
 * @param {String} currentEditingServiceParam - property name on currentEditingService.
 * @param {Object} currentEditingService - currentEditingService Object.
 * @returns {String} property value.
 */
const getOperationDetailsParam = (
  rawServiceParam,
  stateService,
  currentEditingServiceParam,
  currentEditingService
) => {
  let param = "";
  if (currentEditingService) {
    param = JSON.parse(currentEditingService.quoteRawService.rawService)[
      rawServiceParam
    ];
  } else {
    param = stateService[currentEditingServiceParam] || "";
  }
  return param;
};

/**
 * Returns cost allocation accounts by splitting them with pipe(|) symbol
 * @param {String} accounts - cost Allocation accounts coming as pipe separated values
 * @returns {Array<Object>} Cost Allocation accounts (containing label and value) in an array
 */
const generateCostAllocationOptions = accounts => {
  const costAllocationOptions = [];
  const costAllocationArr = accounts?.split("|");
  costAllocationArr?.map(item => {
    costAllocationOptions.push({
      value: format.toPascalCase(item),
      label: format.toPascalCase(item)
    });
  });
  return costAllocationOptions;
};

/**
 * Generates an array of pay type options with a value and a label.
 *
 * @param {Array<{ dealerPayTypeId: number, payCode: string, description: string }>} payTypes - The array of pay types to process.
 * @param {string} appType - Current appType { SQ, CSR }
 * @returns {Array<{ value: string, label: string }>} An array of objects each with a value and label representing a pay type.
 */
const buildPayTypeOption = (
  payTypes,
  appType = "",
  isMenu = false,
  isDmsPlusDealer = false
) => {
  const isCSR = appType === appTypes.CSR;

  if (isEmpty(payTypes)) {
    return [];
  }

  const filteredPayTypes = payTypes.filter(payType => {
    if (isCSR) {
      if (isMenu) {
        return payType.payCode !== payTypeCodes.SERVICE_CONTRACT;
      }
    }
    return true;
  });

  const mappedPayTypes = filteredPayTypes.map(
    ({ dealerPayTypeId, payCode }) => ({
      value: dealerPayTypeId.toString(),
      label: payCode
    })
  );

  if (isMenu && isDmsPlusDealer) {
    return mappedPayTypes.filter(payType => payType.label !== "W");
  }

  return mappedPayTypes;
};

/**
 * Returns true if accounts param is not null, false otherwise
 *
 * @param {String} accounts - cost Allocation accounts coming as pipe separated values
 * @returns {boolean} - Boolean value representing if DMSP_INTERNAL_ACCOUNTS is empty or not
 */
function isDMSPInternalAccAvailable(accounts) {
  return (
    accounts !== null &&
    accounts !== "" &&
    accounts !== NO &&
    accounts !== undefined
  );
}
export {
  buildPayTypeSubTypeOptions,
  buildPayTypeCostAllocationOptions,
  buildWarrantyPlanOptions,
  buildServiceTypeOption,
  buildServiceContractOptions,
  getOperationDetailsParam,
  generateCostAllocationOptions,
  isDMSPInternalAccAvailable,
  buildPayTypeOption
};
