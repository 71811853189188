import React, { forwardRef, useState, useRef, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import Button from "@cx/ui/Button";
import { PartsGridExt } from "../../previewGrids";
import SelectedPartsGrid from "./selected-parts-grid.component";
import { usePartsLookupContext, Actions } from "../state/parts-lookup.context";
import StatusBox from "./reusable/statusbox.component";
import { findPart, generatePartId } from "../utils/helper.util";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import Alert from "@cx/ui/Alert";
import useComponentDidMount from "../hooks/useComponentDidMount";
// import partsService from "../services/parts-service";
import AddPartComponent from "./add-part.component";
import CorePartReturnModal from "./core-return-modal.component";
import {
  transformPartsWithDMSParts,
  resetDmsPending
} from "../utils/parts.util";
import { actionTypes } from "../constants/parts.constants";
import { toast } from "@cx/ui/Toast";

const ResultsPaneWrapper = forwardRef((props, ref) => {
  const partsGridProps = {
    showHeader: false,
    disableQtyEdit: true,
    maxRows: 10,
    noRowMessage: "All service parts have been added.",
    ...props
  };
  delete partsGridProps.lookupParts;
  delete partsGridProps.service;
  const { dispatch, state, ctxGtmEvent } = usePartsLookupContext();
  const { serviceParts, lookupPartsList, menuServiceOriginalParts } = state;
  const [selected, setSelected] = useState([]);
  const [msg, setMsg] = useState("");
  const timeoutIdRef = useRef();
  const [active, setActive] = useState("0");
  const [newPartError, setNewPartError] = useState(0);
  const [showRecommendedTab, setShowRecommendedTab] = useState(true);
  // const [addedParts, setAddedParts] = useState(serviceParts);
  const [showCorePartReturnModal, setShowCorePartReturnModal] = useState(false);
  const [coreReturnPart, setCoreReturnPart] = useState(null);
  const [addPartError, setAddPartError] = useState("");

  useComponentDidMount(() => {
    if (!isEmpty(lookupPartsList)) {
      setShowRecommendedTab(true);
    } else {
      setShowRecommendedTab(false);
    }
    const timeoutId = timeoutIdRef;
    return () => {
      clearTimeout(timeoutId);
    };
  });

  useEffect(() => {
    if (showRecommendedTab) {
      setActive("0");
    } else {
      setActive("1");
    }
  }, [showRecommendedTab]);
  // @todo: read deleted part from selectedPartGrid removePart click and update parts context with latest parts
  // selected-parts-grid returns parts object {undeleted parts, deletedItem, totalParts}
  const updatePartsInContext = (partsResult, removing = false) => {
    // update context to add back deleted part to first grid
    if (!isEmpty(partsResult)) {
      const { deletedItem, parts } = partsResult;
      if (!isEmpty(partsResult.deletedItem)) {
        deletedItem.selected = false;
        // @todo-poc: Since deleted part has modified values, don't push it in lookupPartsList context,
        // instead get same part record from props.lookupParts and push this original part to lookupPartsList context
        const rawParts = cloneDeep(lookupPartsList);
        // Currently, only menu services are being passed originalParts. Other services can use lookupParts as reference.
        const referenceParts = !isEmpty(menuServiceOriginalParts)
          ? menuServiceOriginalParts
          : props.lookupParts;
        const orgPart = findPart(referenceParts, deletedItem);
        if (orgPart) rawParts.push(orgPart);
        resetDmsPending(rawParts, false);
        dispatch({
          type: Actions.SET_LOOKUP_PARTS_LIST,
          payload: rawParts
        });
      }
      // Note: If we are removing a part we don't want to set service parts again
      if (!removing) {
        dispatch({
          type: Actions.SET_SERVICE_PARTS,
          payload: parts
        });
      }
      setMsg("");
      if (props.isEditService) {
        props.getPartsCallback(partsResult); // inspect this callback finally partsResult.parts to be saved in service
      }
    }
  };
  // @todo-poc: handler to push selected parts to context here
  const onSelectPartRow = selectedList => {
    setSelected(selectedList);
  };
  const recommendedPartsGrid = (
    <PartsGridExt
      showPartId={false}
      onSelectRow={onSelectPartRow}
      {...partsGridProps}
    />
  );

  const handleShowCorePartReturnModal = data => {
    const rowId = generatePartId();
    const partId = rowId.toString();
    setCoreReturnPart({
      ...data,
      rowId,
      partId,
      quoteServicePartId: null,
      origPartPrice: data.partPrice,
      origQuantity: data.quantity
    });
    setShowCorePartReturnModal(true);
  };

  const onCancelCoreReturnPart = () => setShowCorePartReturnModal(false);
  const onSaveCoreReturnPart = coreReturnPart => {
    const { partName, partsPrice, quantity } = coreReturnPart;
    const unitPrice = (partsPrice / quantity).toFixed(2);
    const costPrice = null;
    const newCoreReturnPart = {
      ...coreReturnPart,
      costPrice,
      partName: `${partName} Return`,
      partsPrice: -partsPrice,
      unitPrice: -unitPrice,
      quantity
    };
    setCoreReturnPart(null);
    const updatedparts = [...serviceParts, newCoreReturnPart];
    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: updatedparts
    });
    setShowCorePartReturnModal(false);
  };

  const selectedPartsGrid = (
    <div className="parts-preview-grid">
      <SelectedPartsGrid
        title="Service parts"
        parts={serviceParts}
        showPartId={false}
        enableEdit={true}
        service={props.service}
        getParts={updatePartsInContext}
        showCorePartReturnModal={handleShowCorePartReturnModal}
        actionType={partsGridProps?.actionType}
        showActions={
          // eslint-disable-next-line react/jsx-no-leaked-render
          partsGridProps?.isPartsView && !partsGridProps?.isCsrFinalized
        }
        updatePartsPricing={props.getEmergencyPartsPricing}
        isPartsModal={partsGridProps.actionType === actionTypes.MODIFY_PARTS}
        isEmergencyPartsFlagOn={props?.isEmergencyPartsFlagOn}
        isCreateSpecialOrderFlagOn={props?.isCreateSpecialOrderFlagOn}
        supersededPartsEnabled={props?.supersededPartsEnabled}
        specialOrderPriorityList={props?.specialOrderPriorityList}
        getEmergencyPartsPricing={props?.getEmergencyPartsPricing}
      />
    </div>
  );
  // @note: Handler to add individual part for all service types and menus
  const addIndividualPart = async (newPart, isSuperseded = false) => {
    ctxGtmEvent?.trackGAEvent("ga.newquote.add_individual_part_click");
    const keyId = generatePartId();
    newPart.rowId = keyId;
    newPart.partId = keyId.toString(); // check if we need to pass this sequence as partId
    newPart.extPartId = keyId;
    newPart.recordType = "INDIVIDUAL";
    newPart.dmsPending = true;
    if (isSuperseded) {
      newPart.isSuperseded = true;
    }
    let mergedList = [...serviceParts];
    setNewPartError(0);

    const remainingQuantities = serviceParts
      .filter(p => !p.approver)
      ?.map(p => ({
        partNumber: p.oemPartNumber,
        quantityAvailable: p.quantityAvailable
      }));

    mergedList.forEach(item => {
      if (!item.approver) {
        const quantityAvailable = remainingQuantities.find(
          quantity => quantity.partNumber === item.oemPartNumber
        );

        if (quantityAvailable) {
          quantityAvailable.quantityAvailable -= item.quantity;
        }
      }
    });

    const partAvailability = remainingQuantities.find(
      q => q.partNumber === newPart.oemPartNumber
    );

    if (props.onPartPricingLookup) {
      dispatch({
        type: Actions.SET_API_CALL_PENDING,
        payload: true
      });
      const partsPricingAndInventory = await props.onPartPricingLookup(newPart);

      // update selected parts grid with dms api values
      if (!isEmpty(partsPricingAndInventory)) {
        // check if new part exists before adding it to the grid.
        const partExists = await checkIfPartExists(
          partsPricingAndInventory && partsPricingAndInventory[0]
        );
        if (!partExists) {
          dispatch({
            type: Actions.SET_API_CALL_PENDING,
            payload: false
          });
          return false;
        }
        if (props?.supersededPartsEnabled) {
          const supersededPartsData = await isPartSuperseded(newPart);
          const updatedSupersededParts = supersededPartsData?.map(
            newSupersededPart => {
              if (
                newSupersededPart?.supersededPart?.manufacturerPartNo &&
                !isSuperseded
              ) {
                const part = partSupersession(newPart, newSupersededPart);
                return part;
              }
            }
          );
          if (
            supersededPartsData?.length > 0 &&
            supersededPartsData[0]?.supersededPart?.manufacturerPartNo &&
            !isSuperseded
          ) {
            addSupersededParts(updatedSupersededParts);
          } else {
            dispatch({
              type: Actions.SET_API_CALL_PENDING,
              payload: false
            });
          }
        }

        if (partAvailability) {
          partsPricingAndInventory[0].quantityAvailable =
            partAvailability.quantityAvailable > 0
              ? partAvailability.quantityAvailable
              : 0;
        }
        const newParts = transformPartsWithDMSParts(
          partsPricingAndInventory,
          [newPart],
          state.serviceType
        );
        newParts.forEach(p => {
          p.recordType = "INDIVIDUAL";
          if (p.dmsPrice) {
            p.unitPrice = p.dmsPrice;
          }
        });
        mergedList = mergedList.concat(newParts);
      } else {
        mergedList.push(newPart);
      }
      console.log(
        "addIndividualPart updated parts",
        keyId,
        typeof keyId,
        mergedList
      );
    }
    resetDmsPending(mergedList, false);
    dispatch({
      type: Actions.SET_API_CALL_PENDING,
      payload: false
    });
    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: mergedList
    });
    // note: after saving individual part, remain in current tab
    showRecommendedTab ? setActive("0") : setActive("1");
    return true;
  };

  const isPartSuperseded = async partData => {
    const { service, getEmergencyPartsPricing } = props;
    const formattedPartData = {
      cost: partData.unitCost || 0,
      isEmergencyPart: true,
      manufacturer: partData.dtDmsPartCode,
      partSequence: partData.roPartNum || "",
      oemPartNumber: partData.oemPartNumber,
      payType: service?.payTypeCode,
      qty: partData.quantity,
      serviceType: service?.serviceTypeCode,
      isSuperseded: partData?.isSuperseded || false
    };
    // Arturo: we have to make the pricing call to kow if part is superseded
    try {
      dispatch({
        type: Actions.SET_API_CALL_PENDING,
        payload: true
      });
      const pricingData = await getEmergencyPartsPricing(formattedPartData);
      return pricingData;
    } catch (e) {
      dispatch({
        type: Actions.SET_API_CALL_PENDING,
        payload: false
      });
      return [];
    }
  };

  const partSupersession = (partData, supersededPartData) => {
    const keyId = generatePartId();
    const newData = {
      ...partData,
      oemPartNumber: supersededPartData.manufacturerPartNo,
      dtDmsPartCode: supersededPartData.manufacturerCode,
      isSuperseded: true,
      originalPartInfo: supersededPartData?.supersededPart,
      rowId: keyId,
      partId: keyId.toString(),
      extPartId: keyId
    };
    return newData;
  };

  const addSupersededParts = async selected => {
    ctxGtmEvent?.trackGAEvent("ga.dashboard.add_recommended_parts_click");
    // @todo: add recent selected parts to context.serviceParts
    const concatList = serviceParts.concat([]); // 3+1
    const setList = new Set(concatList);

    let mergedList = [...setList];
    setAddPartError("");
    dispatch({
      type: Actions.SET_API_CALL_PENDING,
      payload: true
    });
    await (async () => {
      for await (const part of selected) {
        const partsPricingAndInventory = await props.onPartPricingLookup(part);
        // update selected parts grid with dms api values
        if (!isEmpty(partsPricingAndInventory)) {
          // check if new part exists before adding it to the grid.
          const partExists = checkIfPartExists(
            partsPricingAndInventory && partsPricingAndInventory[0]
          );
          if (!partExists) {
            setMsg("");
            setAddPartError(
              "No part was found. Verify the part number is correct and added to the inventory."
            );
            dispatch({
              type: Actions.SET_API_CALL_PENDING,
              payload: false
            });
            return;
          }

          const supersededPartsData = await isPartSuperseded(part);
          const updatedSupersededParts = supersededPartsData?.map(
            newSupersededPart => {
              if (newSupersededPart?.supersededPart?.manufacturerPartNo) {
                const part = partSupersession(part, newSupersededPart);
                return part;
              }
            }
          );
          if (
            supersededPartsData?.length > 0 &&
            supersededPartsData[0]?.supersededPart?.manufacturerPartNo
          ) {
            addSupersededParts(updatedSupersededParts);
          }

          const newParts = transformPartsWithDMSParts(
            partsPricingAndInventory,
            [part],
            state.serviceType
          );
          newParts.forEach(p => {
            p.recordType = "INDIVIDUAL";
            if (p.dmsPrice) {
              p.unitPrice = p.dmsPrice;
            }
          });
          mergedList = mergedList.concat(newParts);
        } else {
          mergedList.push(part);
        }
      }
    })();
    toast.success(" Requested part number was replaced");
    dispatch({
      type: Actions.SET_API_CALL_PENDING,
      payload: false
    });

    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: mergedList
    });
  };

  // @note: Handler called inside recommended parts tab
  const addSelectedParts = async () => {
    ctxGtmEvent?.trackGAEvent("ga.dashboard.add_recommended_parts_click");
    // @todo: add recent selected parts to context.serviceParts
    const concatList = serviceParts.concat([]); // 3+1
    const setList = new Set(concatList);

    let mergedList = [...setList];
    setAddPartError("");
    let partAdded = true;
    await (async () => {
      for await (const part of selected) {
        const partsPricingAndInventory = await props.onPartPricingLookup(part);
        // update selected parts grid with dms api values
        if (!isEmpty(partsPricingAndInventory)) {
          // check if new part exists before adding it to the grid.
          const partExists = checkIfPartExists(
            partsPricingAndInventory && partsPricingAndInventory[0]
          );
          if (!partExists) {
            setMsg("");
            setAddPartError(
              "No part was found. Verify the part number is correct and added to the inventory."
            );
            dispatch({
              type: Actions.SET_API_CALL_PENDING,
              payload: false
            });
            partAdded = false;
            return;
          }
          const newParts = transformPartsWithDMSParts(
            partsPricingAndInventory,
            [part],
            state.serviceType
          );
          newParts.forEach(p => {
            p.recordType = "INDIVIDUAL";
            if (p.dmsPrice) {
              p.unitPrice = p.dmsPrice;
            }
          });
          mergedList = mergedList.concat(newParts);
        } else {
          mergedList.push(part);
        }
      }
    })();

    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: mergedList
    });
    if (partAdded) {
      // remove selected parts[] from context.lookupPartsList []
      const cloneParts = cloneDeep(lookupPartsList); // 5
      // Using Map to mark local state.selected[] all objects as true
      const map = {};
      for (const part of selected) {
        map[part.rowId] = true;
      }
      const lookupList = cloneParts.filter(part => !map[part.rowId]); // 4

      dispatch({
        type: Actions.SET_LOOKUP_PARTS_LIST,
        payload: lookupList
      });
      setSelected([]);
    }
    setMsg(`Part(s) added`);
    const timeout = setTimeout(() => setMsg(""), 3500);
    timeoutIdRef.current = timeout;
  };

  const checkIfPartExists = newPart => {
    const errorMsg = newPart?.message || "";
    if (errorMsg.indexOf("404") >= 0) {
      setNewPartError(404);
      return false;
    } else if (errorMsg.indexOf("500") >= 0 || errorMsg.indexOf("502") >= 0) {
      setNewPartError(500);
      return false;
    } else {
      setNewPartError(0);
      return true;
    }
  };

  const statusHtml = msg ? (
    <StatusBox
      htmlId="statusBox"
      type="success"
      autoClose={true}
      linkHtml={null}
      message={msg}
      errorInTooltip={false}
    />
  ) : (
    ""
  );
  const partError =
    addPartError === "" ? (
      ""
    ) : (
      <Alert htmlId="dangerAlert" type="danger">
        {addPartError}
      </Alert>
    );

  const recommendedPartsTab = showRecommendedTab ? (
    <Tab label="Recommended parts">
      <div ref={ref}>{recommendedPartsGrid}</div>
      <div className="lookup-modal-footer">
        <div className="error-msg">{partError ? partError : ""}</div>
        {statusHtml}
        <Button
          htmlId="partsAddtoQuoteModalBtn"
          type="button"
          buttonStyle="secondary"
          disabled={isEmpty(selected)}
          onClick={addSelectedParts}
        >
          Add service parts
        </Button>
      </div>
    </Tab>
  ) : null;
  const addPartTab = (
    <AddPartComponent
      addIndividualPart={addIndividualPart}
      newPartError={newPartError}
    />
  );

  const coreReturnModal = showCorePartReturnModal ? (
    <CorePartReturnModal
      show={showCorePartReturnModal}
      coreReturnPart={coreReturnPart}
      onCancel={onCancelCoreReturnPart}
      onSave={onSaveCoreReturnPart}
    />
  ) : (
    ""
  );

  // todo: convert "Add service parts" to translation string once locale strings are passed to this submodule
  return (
    <>
      <Tabs htmlId="partsLookupModelTabs">
        {recommendedPartsTab}
        <Tab
          label="Individual parts"
          active={!recommendedPartsTab ? active === "0" : active === "1"}
        >
          {addPartTab}
        </Tab>
        {/* <Tab label="Parts kits" active={active === "2"}>
          <div>This is not yet built</div>
        </Tab>*/}
      </Tabs>

      <div className="lookup-seperator" />
      {selectedPartsGrid}
      {coreReturnModal}
    </>
  );
});
export default ResultsPaneWrapper;
ResultsPaneWrapper.displayName = "ResultsPaneWrapper";
