export const UNDEFINED_VALUE = undefined;
export const AboutUsTemplate = {
  dealerName: "",
  dealerCode: "",
  application: "",
  url: "",
  buildVersion: "",
  browser: "",
  operatingSystem: "",
  userName: "",
  copyYear: "",
  size: { width: "", height: "" },
  locale: ""
};
// TODO: move this constant to schema or keep here before transform customer
export const NewCustomerTemplate = {
  personId: "",
  lifecycleState: true,
  firstName: "",
  lastName: "",
  loanerAllowed: 0,
  valetAllowed: 0,
  email: "",
  phoneNumbers: {
    home: "",
    work: "",
    mobile: ""
  },
  address: {
    city: "",
    state: "",
    postalCode: "",
    country: ""
  },
  vehicles: []
};
// TODO: Object used for skip customer/add/edit vehicle - this is used as master object before binding with vehicle form
export const VehicleSelectorTemplate = {
  vehicleId: "",
  metaVehicleId: "",
  metaVehicleDetailId: "",
  variant: "",
  mileage: "",
  mileageDt: "",
  vin: "",
  make: "",
  year: "",
  model: "",
  trim: "",
  engineType: "",
  engineSize: "",
  engineSizeType: "",
  driveType: "",
  transmissionType: "",
  drivingConditions: [],
  defaultDrivingCondition: "",
  showDrivingCondition: ""
};
/* This objects are freezed, used for cell renderer  */
export const YesNoMap = {
  0: "NO",
  1: "YES",
  null: "NO"
};

/* This objects are freezed, used for cell renderer  */
export const UnitOfMeasureMap = {
  Gallons: "gal",
  Grams: "g",
  Inches: "in",
  Liters: "l",
  Milliliters: "ml",
  Millimeters: "mm",
  Ounces: "oz",
  Pints: "pt",
  Quarts: "qt"
};

export const QuoteStatusMap = {
  ALL: "Any status",
  IN_PROGRESS: "In progress",
  REQUEST_ASSISTANCE: "Request",
  READY_TO_SEND: "Ready to send",
  SENT: "Sent",
  EXPIRED: "Expired",
  CONVERTED_TO_APPOINTMENT: "Scheduled",
  // @csr-logic
  WITH_ADVISOR: "With advisor",
  IN_PROCESS: "In process",
  WORK_FINISHED: "Work finished",
  PRE_INVOICE: "Pre invoice",
  FINALIZED: "Finalized",
  PRE_RO: "Pre RO"
};

export const SpecialOrderStatusMap = {
  TO_ORDER: "To order",
  IN_PROCESS: "In process",
  ORDERED: "Ordered",
  RECEIVED: "Received",
  CANCELED: "Canceled",
  CANCELLED: "Cancelled",
  PARTIAL_RECEIPT: "Partial receipt",
  REQUESTED: "Requested",
  BACKORDERED: "Backordered",
  OPENED: "Opened",
  APPROVED: "Approved"
};
