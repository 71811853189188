import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ParentRoBanner from "./parent-ro-banner.component";
import ChildrenRoBanner from "./children-ro-banner.component";
import { AppContext } from "../../../../../state/app-context";
import {
  useNewQuoteContext,
  Actions
} from "../../../../../state/NewQuoteContext";
import { loadDataForPreRoBanner } from "../../../../page-wrapper/services/quote-api.service";
import { RO_STATUSES } from "../../../constants/csr.constants";

const SpecialPartsBannerContainer = ({ containerClassName }) => {
  const [parentRO, setParentRO] = useState({});
  const [childrenRO, setChildrenRO] = useState({});
  const context = useContext(AppContext);
  const { state, dispatch } = useNewQuoteContext();
  const { quoteSummary } = state;
  const { originalRoConfirmationId, preRoConfirmationId } = quoteSummary;

  useEffect(() => {
    const shouldFetchOriginalRO =
      !!originalRoConfirmationId && Object.keys(parentRO).length === 0;
    const shouldFetchChildrenRO =
      !!preRoConfirmationId && Object.keys(childrenRO).length === 0;
    if (!shouldFetchOriginalRO && !shouldFetchChildrenRO) return;

    const fetchROByConfirmationId = confirmationId =>
      loadDataForPreRoBanner({
        confirmationId,
        localeStrings: context.localeStrings,
        dealerCode: context.dealer.dealerCode,
        appContext: context
      });

    const fetchBannerData = async () => {
      try {
        const [parentROData, childrenROData] = await Promise.all([
          shouldFetchOriginalRO &&
            fetchROByConfirmationId(originalRoConfirmationId),
          shouldFetchChildrenRO && fetchROByConfirmationId(preRoConfirmationId)
        ]);
        if (parentROData) {
          setParentRO(parentROData);
        }
        if (childrenROData) {
          setChildrenRO(childrenROData);
        }
      } catch (error) {
        console.log(`Error fetching data for banners: ${error}`);
      }
    };

    fetchBannerData();
  }, [
    context,
    originalRoConfirmationId,
    preRoConfirmationId,
    parentRO,
    childrenRO
  ]);

  const handleRORedirection = async (
    newQuote,
    isNavigatingToParent = false
  ) => {
    showPageLoading(true);

    setParentRO(isNavigatingToParent ? {} : quoteSummary);
    setChildrenRO(isNavigatingToParent ? quoteSummary : {});
    dispatch({
      type: Actions.SET_QUOTE,
      payload: newQuote
    });

    showPageLoading(false);
  };

  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };

  const renderParentRoBanner = () => {
    if (Object.keys(parentRO).length === 0) return;
    return (
      <ParentRoBanner
        parentRoNumber={parentRO.roNumber}
        onRedirection={() => handleRORedirection(parentRO, true)}
        isPreRo={quoteSummary.quoteStatus === RO_STATUSES.PRE_RO.value}
      />
    );
  };

  const renderChildrenRoBanner = () => {
    if (Object.keys(childrenRO).length === 0) return;
    return (
      <ChildrenRoBanner
        childrenRoNumber={childrenRO.roNumber}
        onRedirection={() => handleRORedirection(childrenRO)}
      />
    );
  };

  return (
    <div className={containerClassName}>
      {renderParentRoBanner()}
      {renderChildrenRoBanner()}
    </div>
  );
};

SpecialPartsBannerContainer.propTypes = {
  containerClassName: PropTypes.string
};

export default SpecialPartsBannerContainer;
