import React, { useState, memo, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";
import Badge from "@cx/ui/Badge";
import {
  isNumeric,
  priceValueFormatter,
  formatNumberWithThousands
} from "../../../utils/format.util";
import { convertMinutesToHours } from "../../../utils/quote.util";
import { getPreselectedServices } from "../../../utils/data-transformer.util";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import EditMenuServicePage from "./EditMenuServicePage";
import MenuServiceDetails from "./MenuServiceDetails";
import Banner from "../../../components/ui/banner/Banner";
import selectedMenuPackageReducer from "../../../state/selected-menu-package.reducer";
import MenuInspectServiceDetails from "./MenuInspectServiceDetails";
import selectServiceFormat from "../../../utils/select-service-format";
import ConfirmPopup from "../../../components/ui/modals/ConfirmPopup";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import {
  QuoteServiceTypeMap,
  saveActionTypes
} from "../../../constants/search.constants";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import {
  getWarningMessages,
  buildPayTypeOption,
  isDMSPlus,
  buildServiceTypeOption
} from "../../../utils/service.util";
import menuServiceUtil from "../../../utils/menu-service-calculation.util";
import { appSources, appTypes } from "../../../constants/config.constants";

const SelectedMenuPage = props => {
  const [showEditCmp, setShowEditCmp] = useState(false);
  const {
    state,
    dispatch,
    ctxOnEditService,
    ctxRequestPartsInventoryCommon,
    ctxGtmEvent
  } = useServiceSearchContext();
  const {
    debugQuote,
    localeStrings,
    appConfig,
    payTypes,
    calculatedMenuPackage: originalSelectedMenu, // menu has calculated fields
    currentQuickFilterPage,
    serviceTypes
  } = state;
  const [payTypChanged, setPayTypeChanged] = useState(false);
  const [serviceTypeChanged, setServiceTypeChanged] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const { updateLocalState, EditServiceModule } = props;
  const [selectedMenuPackageLocal, dispatchSelectedMenu] = useReducer(
    selectedMenuPackageReducer,
    {
      ...originalSelectedMenu
    }
  );
  const [showAll, setShowAll] = useState(false);
  const [inspectServices, setInspectServices] = useState(
    selectedMenuPackageLocal && selectedMenuPackageLocal.services
      ? selectedMenuPackageLocal.services.filter(service => {
          return selectServiceFormat.isIncludedInspection(service);
        })
      : []
  );

  const [menuServices, setMenuServices] = useState(
    selectedMenuPackageLocal && selectedMenuPackageLocal.services
      ? selectedMenuPackageLocal.services.filter(service => {
          service.price = service?.price || 0;
          return !selectServiceFormat.isIncludedInspection(service);
        })
      : []
  );

  const [errors, setErrors] = useState(false);

  const [changedServices, setChangedServices] = useState([]);

  const [selectedList, setSelectedList] = useState(
    getPreselectedServices(menuServices)
  );

  const [selectedService, setSelectedService] = useState(null);

  const [originalService, setOriginalService] = useState({});

  const [originalCheckedState, setOriginalCheckedState] = useState();

  const [checkedState, setCheckedState] = useState(() => {
    const checkedState =
      menuServices && menuServices.length !== 0
        ? menuServices.map(service => {
            if (!service.selectable && !service.selectByDefault) {
              return true;
            }
            return service.selectByDefault;
          })
        : [];

    setOriginalCheckedState(checkedState);
    return checkedState;
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalLaborPrice, setTotalLaborPrice] = useState(0);
  const [totalPartPrice, setTotalPartPrice] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [isMenuSelected, setIsMenuSelected] = useState(false);

  const {
    user: { userName },
    appSource,
    appType,
    dealer
  } = appConfig;

  const [serviceList] = useState();
  const [showDiscardMenuPopup, setShowDiscardMenuPopup] = useState(false);
  const [serviceHasBeenEdited, setServiceHasBeenEdited] = useState(false);

  const isPackagePriceOverriden = selectedMenuPackageLocal.totalPriceOverridden;
  const selectable = selectedMenuPackageLocal.selectable;
  const allowPriceOverride = isPackagePriceOverriden && !selectable;

  const processPayTypeChange = (
    selectedPayCode,
    selectedDescription,
    selectedPayTypeGroup
  ) => {
    /**
     * Local state which contains current parts should be considered and not only catalog parts on paytype change,
     * else it drop newly added parts and reset with original parts configured under Menu Package
     */
    const updatedMenuPackage = cloneDeep(selectedMenuPackageLocal);

    // Update each service object within the menu package
    updatedMenuPackage.services = updatedMenuPackage.services.map(service => ({
      ...service,
      payTypeCode: selectedPayCode,
      payTypeDescription: selectedDescription,
      payTypeGroup: selectedPayTypeGroup
    }));

    // Update the menu package attributes
    updatedMenuPackage.payTypeCode = selectedPayCode;
    updatedMenuPackage.payTypeDescription = selectedDescription;
    updatedMenuPackage.payTypeGroup = selectedPayTypeGroup;
    updatedMenuPackage.drivingCondition = isEmpty(
      state.menuSelectedDrivingCondition
    )
      ? "Normal"
      : state.menuSelectedDrivingCondition;

    // Dispatch the updated menu package to the reducer
    dispatch({
      type: Actions.SET_CALCULATED_MENU_PACKAGE,
      payload: updatedMenuPackage
    });

    const payload = {
      payTypeCode: selectedPayCode,
      payTypeDescription: selectedDescription,
      payTypeGroup: selectedPayTypeGroup,
      drivingCondition: updatedMenuPackage.drivingCondition,
      newServices: updatedMenuPackage.services
    };
    dispatchSelectedMenu({
      type: "UPDATE_PAYTYPE_MENU_SERVICE",
      payload
    });
  };

  const handlePayTypeChange = e => {
    setPayTypeChanged(true);

    const selection = payTypes.find(
      pt => pt.dealerPayTypeId.toString() === e.target.value
    );
    ctxGtmEvent.trackGAEventWithParam(
      "ga.newquote.edit_service_pay_type_click",
      {
        result: `${selectedMenuPackageLocal?.description} - ${selection?.description}`,
        location: "review-menu-page"
      }
    );
    processPayTypeChange(
      selection.payCode,
      selection.description,
      selection.payTypeGroup
    );
  };

  const handleServiceTypeChange = e => {
    setServiceTypeChanged(true);

    const sTypecode = serviceTypes.find(
      service => String(service.dealerServiceTypeId) === e.target.value
    )?.serviceTypeCode;

    const updatedMenuPackage = cloneDeep(originalSelectedMenu);

    // Update each service object within the menu package
    updatedMenuPackage.services = updatedMenuPackage.services.map(service => ({
      ...service,
      serviceTypeCode: sTypecode
    }));

    // Update the menu package attributes
    updatedMenuPackage.serviceTypeCode = sTypecode;

    // Dispatch the updated menu package to the reducer
    dispatch({
      type: Actions.SET_CALCULATED_MENU_PACKAGE,
      payload: updatedMenuPackage
    });

    const payload = {
      serviceTypeCode: sTypecode,
      newServices: updatedMenuPackage.services
    };
    dispatchSelectedMenu({
      type: "UPDATE_SERVICETYPECODE_MENU_SERVICE",
      payload
    });
  };

  const calculateMenuPackage = services => {
    const { totalPrice, totalLaborHour, subtotalLabor, subtotalPart } =
      menuServiceUtil.getCalculationValuesMenuPackage(
        services,
        isPackagePriceOverriden,
        selectedMenuPackageLocal
      );

    setTotalPrice(totalPrice);
    setTotalHours(totalLaborHour);
    setTotalLaborPrice(subtotalLabor);
    setTotalPartPrice(subtotalPart);
  };

  useComponentDidMount(() => {
    dispatchSelectedMenu({
      type: "SET_ORIGINAL_SERVICES",
      payload: originalSelectedMenu.services
    });
  });

  useEffect(() => {
    const isMenuSelected = checkedState.some(element => element);
    setIsMenuSelected(isMenuSelected);
  }, [checkedState, showAll]);

  useEffect(() => {
    setMenuServices(
      selectedMenuPackageLocal.services.filter(service => {
        return !selectServiceFormat.isIncludedInspection(service);
      })
    );
    setInspectServices(
      selectedMenuPackageLocal.services.filter(service => {
        return selectServiceFormat.isIncludedInspection(service);
      })
    );
    setChangedServices(selectedMenuPackageLocal.changedServices);
  }, [selectedMenuPackageLocal]);

  useEffect(() => {
    const checkedServices = menuServices.filter(
      (item, index) => checkedState[index] === true
    );
    setSelectedList(checkedServices);
    if (Array.isArray(checkedServices) && checkedServices.length > 0) {
      calculateMenuPackage(checkedServices);
    }
    if (Array.isArray(changedServices) && changedServices.length > 0) {
      const changedServicesIds = changedServices.map(item => item.id);
      setSelectedList(prevList => {
        const updatedSelectedList = prevList.filter(
          item => !changedServicesIds.includes(item.id)
        );
        changedServices.forEach(item => {
          updatedSelectedList.push(item);
        });
        return updatedSelectedList;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuServices, checkedState, changedServices]);
  // placeholder - not used
  const handleServicePriceChange = (newPrice, serviceIndex) => {
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM_PRICE",
      payload: {
        newPrice,
        serviceIndex
      }
    });
  };
  const handleServiceChange = (newService, serviceIndex) => {
    setIsSaveButtonDisabled(true);
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM",
      payload: {
        newService,
        serviceIndex
      }
    });
    setIsSaveButtonDisabled(false);
  };

  const handleBackToMenuPopup = () => {
    ctxOnEditService(false);
    const hasServicesSelectionChange = checkedState.reduce(
      (previousValue, currentValue, currentIndex) => {
        return (
          previousValue || currentValue !== originalCheckedState[currentIndex]
        );
      },
      false
    );

    const hasAnyPriceInPackagesChange =
      selectedMenuPackageLocal.services.reduce(
        (previousValue, currentValue, currentIndex) => {
          return (
            previousValue ||
            currentValue.price !==
              originalSelectedMenu.services[currentIndex].price
          );
        },
        false
      );

    if (hasServicesSelectionChange || hasAnyPriceInPackagesChange) {
      setShowDiscardMenuPopup(true);
    } else {
      handleBackToMenus();
    }
  };

  const handleBackToMenus = () => {
    // TODO: update context to hide search header

    dispatchSelectedMenu({
      type: "RESET_PACKAGE_STATE",
      payload: originalSelectedMenu
    });
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    updateLocalState(false);
  };

  const handleBackToPackage = showEdit => {
    setShowEditCmp(showEdit);
  };

  const addPackageToQuote = async (e, menuRecord, addAnother) => {
    e.preventDefault();

    if (!isMenuSelected) {
      return;
    }

    if (Array.isArray(payTypes) && payTypes.length === 0) {
      setErrors({ payTypes: true });
      return;
    }
    // Note - Selection and/or inspections conditions are valid when either one of the two following are true:
    // A. there is at least one selected or pre-selected service, or
    // B. package is non-selectable and there in at least one included inspection.
    const selectionAndInspectionConditionsAreValid =
      isMenuSelected || (!menuRecord.selectable && inspectServices.length > 0);
    if (
      menuRecord &&
      Object.keys(menuRecord).length > 0 &&
      selectionAndInspectionConditionsAreValid
    ) {
      const uniqueSelectedList = selectedList.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const uniqueInspectServices = inspectServices.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      menuRecord.services = uniqueSelectedList.concat(uniqueInspectServices);
      menuRecord.totalPrice = totalPrice;
      menuRecord.partsPrice = totalPartPrice;
      menuRecord.laborPrice = totalLaborPrice;
      menuRecord.duration = totalHours;
      menuRecord.payTypeGroup = selectedMenuPackageLocal.payTypeGroup;
      menuRecord.payTypeCode = selectedMenuPackageLocal.payTypeCode;
      menuRecord.payTypeDescription =
        selectedMenuPackageLocal.payTypeDescription;
      menuRecord.serviceTypeCode = selectedMenuPackageLocal.serviceTypeCode;
      // @todo remove menuRecord after make sure it doesn't affect any side effect
      const menuObj = {
        ...originalSelectedMenu,
        menuServices:
          originalSelectedMenu?.menuServices || originalSelectedMenu.services,
        services: uniqueSelectedList.concat(uniqueInspectServices),
        totalPrice,
        partsPrice: totalPartPrice,
        laborPrice: totalLaborPrice,
        duration: totalHours,
        payTypeGroup: selectedMenuPackageLocal.payTypeGroup,
        payTypeCode: selectedMenuPackageLocal.payTypeCode,
        payTypeDescription: selectedMenuPackageLocal.payTypeDescription,
        serviceTypeCode: selectedMenuPackageLocal.serviceTypeCode,
        drivingCondition: isEmpty(state.menuSelectedDrivingCondition)
          ? "Normal"
          : state.menuSelectedDrivingCondition
      };
      dispatch({
        type: Actions.SET_MENU_PACKAGE,
        payload: menuObj
      });
      dispatch({
        type: Actions.IS_MENU_UPDATED,
        payload: true
      });
      // TODO: update search context to show search header
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: true
      });
      if (addAnother) {
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: saveActionTypes.SAVEANOTHER
        });
      } else {
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: saveActionTypes.SAVE
        });
        dispatch({
          type: Actions.SET_PAGE_MASK,
          payload: true
        });
      }
    }
  };

  const editPage = showEditCmp ? (
    <EditMenuServicePage
      onBackToPackage={handleBackToPackage}
      service={selectedService}
      selectedMenuPackage={selectedMenuPackageLocal}
      originalService={originalService}
      onServicePriceChange={handleServicePriceChange}
      onServiceChange={handleServiceChange}
      username={userName}
      EditServiceModule={EditServiceModule}
      serviceHasBeenEdited={serviceHasBeenEdited}
    />
  ) : (
    ""
  );
  const getServiceName = menu => {
    if (menu) {
      const { mileage, units, description } = menu;
      const formattedMileage = formatNumberWithThousands(mileage);
      return `${formattedMileage} ${units} - ${description}`;
    }
    return menu.description;
  };
  /* checkbox change handler */
  const showDetailsClick = event => {
    setShowAll(event.target.checked);
  };
  const componentHeader = (
    <div className="pkg-header-block">
      <div className="menu-title">
        <div className="search-truncate-text">
          Review {getServiceName(selectedMenuPackageLocal)} package details
        </div>
      </div>
      <div className="action-box">
        <div className="xmm-checkbox-container" id="showAll">
          <input
            className="form-checkbox"
            name="showAll"
            id="showAllChk"
            type="checkbox"
            checked={showAll}
            onChange={showDetailsClick}
          />
          <span className="xmm-checkmark" />
          <span className="label-checkbox full-label">
            {localeStrings["sq.search.edit_service.summary.show_details_lbl"]}
          </span>
        </div>
      </div>
    </div>
  );

  // @todo-workaround: Here call open track api to get dms prices when edit clicked to pass service as props
  const handleEditService = async (e, service, showEdit) => {
    e.preventDefault();
    setShowEditCmp(showEdit);
    const originalSelectedService = originalSelectedMenu.services.find(
      item => item.id === service.id
    );
    // console.log(
    //   ">>> originalSelectedService, service",
    //   originalSelectedService,
    //   service
    // );
    // @important: to support for opentrack api bindings, we have to add priceSource field in MenuService object;
    // since MenuService.part has partPriceSource field
    const rawParts = originalSelectedService.part;
    rawParts.map(part => {
      part.priceSource = part.partPriceSource;
      return part;
    });
    setOriginalService(originalSelectedService);
    const serviceParts = service.part;
    serviceParts.map(part => {
      part.priceSource = part.partPriceSource;
      return part;
    });
    setOriginalService(originalSelectedService);
    setSelectedService(service);
    const changedServicesIds = selectedMenuPackageLocal.changedServices.map(
      service => service.id
    );
    const serviceHasBeenEdited = changedServicesIds.includes(service.id);
    setServiceHasBeenEdited(serviceHasBeenEdited);
    if (!serviceHasBeenEdited) {
      service.operationSource = QuoteServiceTypeMap.MENU;
      delete service.parts;
      ctxRequestPartsInventoryCommon(service);
    }
  };
  // TODO: update state with checked service and remove unchecked
  const handleServiceClick = (event, service, position) => {
    ctxGtmEvent?.trackGAEventWithParam("ga.newquote.select_service_click", {
      result: QuoteServiceTypeMap.MENU,
      location: "menu-quick-filter"
    });
    const { checked } = event.target;
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    let result = selectedList;
    if (checked) {
      result.push(service);
    } else {
      result = selectedList.filter(s => s.id !== service.id);
    }
    setSelectedList(result);
  };

  const createPriceChangedTooltip = serviceCurrent => {
    const serviceOriginal = originalSelectedMenu.services.find(
      item => item.id === serviceCurrent.id
    );
    if (serviceOriginal && serviceOriginal.price !== serviceCurrent.price) {
      return (
        <Tooltip
          htmlId="menuServiceLaborPriceEdited"
          tooltipContent={`Labor changed from ${priceValueFormatter(
            serviceOriginal.price
          )} to ${priceValueFormatter(serviceCurrent.price)} by ${userName}`}
          position="left"
        >
          <IconInfoOutline htmlId="iconFee" />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const menuServicesHtml = menuServices.map((service, index) => {
    const showPrice = isNumeric(service.price);
    const isPackagePriceOverriden =
      selectedMenuPackageLocal.totalPriceOverridden;
    const selectable = service.selectable;
    // const selectByDefault = selected[service.id];
    return (
      <div className={showAll ? "op-row modified" : "op-row"} key={service.id}>
        <div className="op-name">
          <div className="top-level">
            {selectable ? (
              <div
                className="xmm-checkbox-container"
                id={`service-${service.id}`}
              >
                <input
                  className="form-checkbox"
                  name={service.id}
                  value={service.id}
                  id={`service-chk-${index}`}
                  type="checkbox"
                  checked={checkedState[index]}
                  onChange={e => handleServiceClick(e, service, index)}
                />
                <span className="xmm-checkmark" />
                <span className="label-checkbox full-label" />
              </div>
            ) : (
              ""
            )}
            <div
              className={!selectable ? "op-title margin-left-18" : "op-title"}
            >
              {service.name}
            </div>
            {!debugQuote
              ? ""
              : service?.totalPriceOverridden && (
                  <span className="override-badge-container">
                    <Badge htmlId="overrideBadge" color="red">
                      {priceValueFormatter(service.totalPriceOverride)}
                    </Badge>
                  </span>
                )}
            <div className="op-price">
              {createPriceChangedTooltip(service, index)}
              {isPackagePriceOverriden
                ? ""
                : showPrice
                ? priceValueFormatter(service.price)
                : "Incl."}
            </div>
            {isPackagePriceOverriden ? (
              <div className="pkg-no-edit">&nbsp;</div>
            ) : showPrice ? (
              <div className="pkg-action-links">
                <span
                  className="hand-cursor"
                  id="editServiceLink"
                  onClick={e => handleEditService(e, service, true)}
                >
                  {localeStrings["sq.search.common.edit_button"]}
                </span>
              </div>
            ) : (
              <div className="pkg-no-edit">&nbsp;</div>
            )}
          </div>
        </div>
        <MenuServiceDetails
          key={`key-${service.id.toString()}`}
          expandDetails={showAll}
          service={service}
          isPackagePriceOverriden={isPackagePriceOverriden}
        />
      </div>
    );
  });

  const inspectServicesHtml = (
    <div
      className={showAll ? "op-row modified" : "op-row"}
      key="key-Inspect-services"
    >
      <div className="op-name">
        <div className="top-level">
          <div className="op-title margin-left-18">
            {
              localeStrings[
                "sq.search.edit_service.summary.included_inspection"
              ]
            }{" "}
            ({inspectServices.length})
          </div>
        </div>
      </div>
      <MenuInspectServiceDetails
        key="inspect-services-details"
        expandDetails={showAll}
        inspectServices={inspectServices}
      />
    </div>
  );
  const parentCls = showEditCmp ? "hide-ele" : "search-flex-grid-container";
  const getBannerMessage = () => {
    if (allowPriceOverride) {
      return localeStrings["sq.search.menu.package.customize"];
    } else if (selectable && !isPackagePriceOverriden) {
      return localeStrings["sq.search.menu.package.selectable"];
    } else if (!selectable && !isPackagePriceOverriden) {
      return localeStrings["sq.search.menu.package.modifiable"];
    }
  };

  const handleDiscardMenuPopupProceed = () => {
    handleBackToMenus();
  };

  const handleDiscardMenuPopupCancel = () => {
    setShowDiscardMenuPopup(false);
  };

  const discardMenuReviewPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_menu_package_edit"]}
      show={showDiscardMenuPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={handleDiscardMenuPopupProceed}
      cancelAction={handleDiscardMenuPopupCancel}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );
  const isEngageRO = appType === appTypes.CSR && appSource === appSources.ENG;
  const isMenu = isMenuSelected || currentQuickFilterPage === "MENU";
  const dmsType = dealer?.dmsType;
  const isDmsPlusDealer = isDMSPlus(dmsType);

  return (
    <>
      <div id="selectedMenuContainer" className={parentCls}>
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleBackToMenuPopup}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />{" "}
          Back to menu packages
        </span>

        {!isMenuSelected ? (
          <Banner type="warning">
            <span>
              {
                localeStrings[
                  "sq.newquote.edit_menu_service.no_service_selected_msg"
                ]
              }
            </span>
          </Banner>
        ) : null}

        <div className="pkg-service-main">
          <div className="pkg-services-box">
            {componentHeader}
            <div className="pkg-op-table pkg-alacarte-table">
              {menuServicesHtml}
              {inspectServices.length !== 0 ? inspectServicesHtml : ""}
            </div>
            <div className="pkg-op-table pkg-alacarte-table">{serviceList}</div>
            <div className="pkg-service-customize-info">
              <Banner type="notice">
                <span>{getBannerMessage()}</span>
              </Banner>
            </div>
            {/* @todo Take below form to a external component once dynamic calcs are available on API */}
            <div className="pkg-service-customize-controls">
              <div className="labor-form">
                <div className="pkg-opcode-field input-field">
                  <TextInput
                    htmlId="editMenuPackageOpCode"
                    label="Package op code"
                    name="edit-menu-package-op-code"
                    onChange={() => {}}
                    value={
                      has(selectedMenuPackageLocal, "dmsOpcode")
                        ? selectedMenuPackageLocal.dmsOpcode
                        : ""
                    }
                    disabled
                    appendChild={
                      <Button
                        buttonStyle="secondary"
                        htmlId="menuOpcodeLookupBtn"
                        type="submit"
                      >
                        <i className="fa fa-search" aria-hidden="true" />
                      </Button>
                    }
                  />
                  <p className="pkg-opcode-disclosure">
                    {localeStrings["sq.search.menu.package.opcode"]}
                  </p>
                </div>
                {isDmsPlusDealer ? (
                  <SelectInput
                    htmlId="serviceTypeSelect"
                    name="serviceType"
                    label="Service type"
                    maxHeight={150}
                    options={[...buildServiceTypeOption(serviceTypes)]}
                    value={
                      !serviceTypeChanged &&
                      String(props?.defaultServiceTypeCode) !== "-1"
                        ? String(props?.defaultServiceTypeCode)
                        : ""
                    }
                    displayDeselectOption={false}
                    onChange={e => handleServiceTypeChange(e)}
                    className="input-field service-type"
                    required
                  />
                ) : null}
                <SelectInput
                  htmlId="payTypeSelect"
                  name="payType"
                  label="Pay type"
                  maxHeight={150}
                  displayDeselectOption={false}
                  value={
                    !payTypChanged &&
                    String(props?.defaultPayTypeOption) !== "-1"
                      ? String(props.defaultPayTypeOption)
                      : ""
                  }
                  onChange={cxEvent => handlePayTypeChange(cxEvent)}
                  options={[
                    ...buildPayTypeOption(
                      payTypes,
                      appType,
                      isMenu,
                      isDmsPlusDealer
                    )
                  ]}
                  className="input-field select-field"
                  required
                />
                <div className="total-field">
                  <div className="total-label">Total hours</div>
                  <div className="total-value">
                    {convertMinutesToHours(totalHours)}
                  </div>
                </div>
                <div className="total-field">
                  <div className="total-label">Total labor</div>
                  <div className="total-value">
                    {priceValueFormatter(totalLaborPrice)}
                  </div>
                </div>
                <div className="total-field">
                  <div className="total-label">Total parts</div>
                  <div className="total-value">
                    {priceValueFormatter(totalPartPrice)}
                  </div>
                </div>
                <TextInput
                  htmlId="menuPackageTotal"
                  inputPrefix="$"
                  label="Package total"
                  name="menu-package-total"
                  onChange={() => {}}
                  className="input-field package-total"
                  disabled
                  value={parseFloat(totalPrice).toFixed(2)}
                />
              </div>
            </div>
            <div
              className={`pkg-flex-bgroup ${
                !isEngageRO && "sticky-footer p-a"
              }`}
            >
              <Button
                htmlId="save-and-add-another-menuBtn"
                buttonStyle="secondary"
                type="button"
                onClick={e =>
                  addPackageToQuote(e, selectedMenuPackageLocal, true)
                }
              >
                Save and add another
              </Button>
              <div className="menu-service-save-cancel">
                <span
                  id="cancelMenuBtn"
                  className="hand-cursor"
                  onClick={handleBackToMenuPopup}
                >
                  {localeStrings["sq.search.common.cancel_button"]}
                </span>

                <Button
                  htmlId={`save-menu-${selectedMenuPackageLocal.id}`}
                  buttonStyle="primary"
                  type="button"
                  size="sm"
                  disabled={isSaveButtonDisabled}
                  onClick={e =>
                    addPackageToQuote(e, selectedMenuPackageLocal, false)
                  }
                >
                  {localeStrings["sq.search.common.save_button"]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errors ? (
        <span className="missing-notes-message">
          {localeStrings["sq.editservice.save_error"]}(
          {getWarningMessages(errors, {
            dealer: appConfig?.dealer?.dealerCode,
            name: selectedMenuPackageLocal?.description,
            confirmationId: props?.confirmationId || appConfig?.confirmationId
          })}
          )
        </span>
      ) : null}
      {editPage}
      {discardMenuReviewPopup}
    </>
  );
};

export default memo(SelectedMenuPage);

SelectedMenuPage.defaultProps = {
  updateLocalState: () => {},
  defaultPayTypeOption: "-1",
  defaultServiceTypeCode: "-1",
  EditServiceModule: null,
  confirmationId: null
};

SelectedMenuPage.propTypes = {
  updateLocalState: PropTypes.func,
  defaultPayTypeOption: PropTypes.string,
  defaultServiceTypeCode: PropTypes.string,
  EditServiceModule: PropTypes.element,
  confirmationId: PropTypes.string
};
