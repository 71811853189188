import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { workTypeArr } from "../../utils/add-technicians.utils";

const WorkTypeSelectDropdown = props => {
  const [selectedType, setSelectedType] = useState(
    props?.params?.data?.workType || null
  );

  const { params, onCellValueChanged } = props;

  useEffect(() => {
    if (params?.data?.workType) {
      const selectedWorkType = workTypeArr?.find(
        type => type?.value === params?.data?.workType
      );
      setSelectedType(selectedWorkType);
    }
  }, [params?.data]);

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className="tech-select-container">
      <div>
        {workTypeArr?.length > 0 ? (
          workTypeArr?.map(type => {
            return (
              <div className="tech-select-item" key={type.value}>
                <span
                  className="tech-item-label"
                  onClick={() => {
                    hide();
                    setSelectedType(type);
                    params.data.workType = type?.value;
                    onCellValueChanged(params);
                  }}
                >
                  {type?.label}
                </span>
              </div>
            );
          })
        ) : (
          <div className="tech-select-item" key="null">
            <span className=" tech-item-label disabled">
              No worktype available
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom-start"
    >
      <span
        className="csr-tech-select-dropdown-span"
        onClick={visible ? hide : show}
      >
        {selectedType?.label}
      </span>
    </Tippy>
  );
};

export default WorkTypeSelectDropdown;

WorkTypeSelectDropdown.propTypes = {
  params: PropTypes.object,
  onCellValueChanged: PropTypes.func
};
