import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import isEmpty from "lodash/isEmpty";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import ConfirmPopup from "../../../components/ui/modals/ConfirmPopup";
import serviceDataMapper from "../../../utils/service-data-mapper";
import { saveActionTypes } from "../../../constants/search.constants";

/**
 * @param {Prop} props
 * @param {Element} EditServiceModule
 * @param {object} service
 * @param {function} updateParentState
 * @return {Element}
 */
const EditDealerPublished = props => {
  const { service, EditServiceModule, updateParentState } = props;
  const [localService, setLocalService] = useState(null);
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;
  useEffect(() => {
    setLocalService(service);
  }, [service]);

  const backToService = () => {
    updateParentState("DealerPublishedCatalog", false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setLocalService(null);
  };

  const handleServiceUpdate = updatedService => {
    const newService = serviceDataMapper.updateDealerPublished(
      localService,
      updatedService
    );
    console.log("dealer pub service to be saved", newService);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    if (serviceHasChanged && localService.operationSource !== "GlobalCatalog") {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const saveServiceAndGoBack = updatedService => {
    const newService = serviceDataMapper.updateDealerPublished(
      localService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    backToService();
  };
  // @todo-paytype: trigger parent handler to pass paytype value
  const asyncUpdatePartsByPaytype = async (payType, editedService) => {
    ctxRequestPartsInventoryCommon(
      {
        ...localService,
        payTypeCode: payType,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  // @todo-serviceType: trigger parent handler to pass servicetype value
  const asyncUpdatePartsByServiceType = async (serviceType, editedService) => {
    ctxRequestPartsInventoryCommon(
      {
        ...localService,
        serviceTypeCode: serviceType,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceContract: editedService?.serviceContract
      },
      editedService
    );
  };
  const asyncUpdatePartsByServiceContract = async (
    serviceContract,
    editedService
  ) => {
    ctxRequestPartsInventoryCommon(
      {
        ...localService,
        serviceContract,
        payTypeCode: editedService?.payTypeCode,
        payTypeDescription: editedService?.payTypeDescription,
        payTypeGroup: editedService?.payTypeGroup,
        serviceTypeCode: editedService?.serviceTypeCode,
        serviceTypeDescription: editedService?.serviceTypeDescription
      },
      editedService
    );
  };
  const clonedEditServiceModule = !isEmpty(localService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        rawOperationDetails: localService,
        service: serviceDataMapper.editModulePropsFromService(localService),
        localeStrings,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        // @todo: events trigger callback handlers of ServiceSearchWrapper
        onChangePaytype: asyncUpdatePartsByPaytype,
        onChangeServiceType: asyncUpdatePartsByServiceType,
        onChangeServiceContract: asyncUpdatePartsByServiceContract,
        onServiceChange: handleServiceChange
      })
    : null;
  const editPage = !isEmpty(localService) ? clonedEditServiceModule : "";

  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div id="editGlobalRepair" className="search-flex-grid-container">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        <div className="edit-page-wrapper">{editPage}</div>
      </div>
      {discardEditPopup}
    </>
  );
};

export default memo(EditDealerPublished);

EditDealerPublished.defaultProps = {
  service: null,
  updateParentState: () => {},
  EditServiceModule: null
};
EditDealerPublished.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
