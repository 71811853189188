import { checkIfIsCSR } from "../../../api/app.util";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import csrService from "../../quote-summary/services/csr.service";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import loadQuoteSchema from "../../quote/schemas/load-quote.schema";

/**
 * Adjusts the CSR model to account for missing/misleading data.
 * @param {object} data Quote data returned from the API.
 */
const fixCSR = data => {
  // Fill in the pickUpDateTime if it has not been set already.
  if (data && !data.pickUpDateTime) {
    const newValue = moment(new Date());
    newValue.set({ h: 17, m: 0, s: 0, ms: 0 });
    data.pickUpDateTime = newValue;
    csrService.patchRO(
      data.dealerCode,
      data.confirmationId,
      data.lastModByUserId,
      {
        pickUpDateTime: newValue
      }
    );
  }

  if (data?.quoteServices) {
    // The current APIs don't allow you to fully remove a sublet; you can only replace it with an empty object.
    // This code replaces them with null to simplify downstream checks for their existence.
    try {
      data.quoteServices.forEach(service => {
        if (isEmpty(service.sublets)) {
          service.sublets = [];
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
};
/**
 *
 * @param {string} confirmationId - always pass quote.confirmationId
 * @param {string} dealerCode
 * @param {object} appContext
 * @returns Quote object
 */
const loadQuote = ({
  confirmationId,
  localeStrings,
  dealerCode,
  appContext
}) => {
  const restUrl = `/quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: {}
      },
      response => {
        const responseFormatted = loadQuoteSchema.cast(response);
        const { appType } = appContext;
        if (checkIfIsCSR(appType)) {
          fixCSR(responseFormatted);
        }
        resolve(responseFormatted);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.api_failure_msg"];
        console.error(msg);
        reject(error);
      },
      `Unable to load CSR details.`
    );
  });
};

// TODO: remove this service once the problem with fetching pre-ro is solved (lastModByUserId not being present)
/**
 *
 * @param {string} confirmationId - always pass quote.confirmationId
 * @param {string} dealerCode
 * @param {object} appContext
 * @returns Quote object
 */
const loadDataForPreRoBanner = ({
  confirmationId,
  localeStrings,
  dealerCode,
  appContext
}) => {
  const restUrl = `/quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: {}
      },
      response => {
        const responseFormatted = loadQuoteSchema.cast(response);
        resolve(responseFormatted);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.api_failure_msg"];
        console.error(msg);
        reject(error);
      },
      `Unable to load CSR details.`
    );
  });
};

const deleteQuote = async (dealerCode, confirmationId) => {
  const restUrl = `/quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "DELETE"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      `Unable to delete CSR.`
    );
  });
};

const copyQuote = (quote, quoteUserId, dealerCode) => {
  const { confirmationId } = quote;
  const restEndPoint = `/quote/${dealerCode}/copyQuote/${confirmationId}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: {
          expirationDays: 30,
          createdByUserId: quoteUserId
        },
        params: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      `Unable to copy CSR.`
    );
  });
};

export { fixCSR, loadQuote, loadDataForPreRoBanner, deleteQuote, copyQuote };
