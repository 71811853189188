import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { getManufacturerCode } from "./data-util";

const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(1);

function convertArraytoString(str) {
  if (!str || str === "") {
    return "";
  }
  let strFinal = "";
  if (!isEmpty(str) && Array.isArray(str)) {
    strFinal = str.join(", ");
  } else if (!isEmpty(str)) {
    strFinal = str.toString();
  }
  return strFinal;
}
/**
 * Function called to return formatted parts object {same as dealer publish parts} filled from catalog menu API response object
 * @param {array} rawParts
 * @param {string} make
 * @returns
 */
const formatPartsFromRawMenuService = (rawParts, make = "") => {
  const formattedParts = rawParts.map(part => {
    // @workaround: copy {manufacturerCode} if returned in catalog menus API to field {dtDmsPartCode}
    // DMS opentrack API payload expecting this field {dtDmsPartCode} to return DMS parts
    const code = getManufacturerCode(make);
    const mfrCode = get(part, "manufacturerCode", code);
    return {
      ...part,
      partId: part.id,
      rowId: Number(part.id),
      partName: part.description,
      priceSource: part.partPriceSource,
      quantity: part.adjustedQuantity,
      dmsPending: true,
      selected: false,
      dtDmsPartCode: mfrCode,
      manufacturerCode: mfrCode,
      notes: null,
      position: null,
      unitOfMeasure: null,
      specification: null,
      status: null
    };
  });
  return formattedParts;
};

const isDealerTireService = rawOperationDetails => {
  let response = false;
  if (!isEmpty(rawOperationDetails)) {
    const { laborApps } = rawOperationDetails;
    if (!isEmpty(laborApps)) {
      const rawTireService = laborApps[0].rawTireService || null;
      if (!isEmpty(rawTireService)) {
        if (!isEmpty(rawTireService.services)) {
          response = true;
        }
      }
    }
  }
  return response;
};
// Delta logic for Parts
// method returns delta of not-added parts of catalog Menu service.part {} and saved parts in quote menu service to support Review menu page
const deltaPartsofQuoteMenuService = (catalogMenuService, quoteMenuService) => {
  const catalogParts = catalogMenuService.part;
  const serviceParts = quoteMenuService.parts;
  const lookupPartsIds = catalogParts.map(part => part.id.toString());
  const deltaParts = [];
  catalogParts.forEach(catalogPart => {
    const savedPart = serviceParts.find(
      part => catalogPart.id === part.extPartId
    );
    // case1: when parts not added in quote-menu-service, selected = false
    if (!savedPart) {
      deltaParts.push({
        ...catalogPart,
        extPartId: catalogPart.id,
        dmsPending: false,
        selected: false,
        dtDmsPartCode: get(
          catalogPart,
          "dtDmsPartCode",
          get(catalogPart, "manufacturerCode", null)
        )
      });
    } else {
      // case2: when parts added to quote menu service, to be preselected = true
      deltaParts.push({
        ...catalogPart,
        quoteServicePartId: savedPart?.quoteServicePartId || null,
        id: savedPart.extPartId,
        extPartId: savedPart.extPartId,
        dmsPending: false,
        selected: true,
        dtDmsPartCode: get(savedPart, "dtDmsPartCode", null)
      });
    }
  });
  // case3: extract custom parts from quote menu service
  const individualParts = serviceParts.reduce((acc, part) => {
    const partId = !part.extPartId ? "" : part.extPartId.toString();
    if (!lookupPartsIds.includes(partId)) {
      acc.push({
        ...part,
        partsPrice: part.partPrice,
        quoteServicePartId: part?.quoteServicePartId || null,
        id: part.extPartId,
        adjustedQuantity: part.adjustedQuantity,
        description: part.description,
        oemPartNumber: part.oemPartNumber,
        partPriceSource: part.priceSource,
        partType: part.partType,
        unitPrice: part.unitPrice,
        dmsPending: false,
        selected: true,
        dtDmsPartCode: part?.dtDmsPartCode || null
      });
    }
    return acc;
  }, []);
  const finalParts = [...deltaParts, ...individualParts];
  console.log(
    "delta parts logic for menu service-" + quoteMenuService?.serviceName,
    finalParts
  );
  return finalParts;
};

function partsPayload(partObj) {
  const parts = [];
  const partNumbers = [];
  if (!isEmpty(partObj)) {
    parts.push(partObj);
  }
  parts.forEach(p => {
    partNumbers.push({
      partNumber: p.oemPartNumber,
      manufacturerCode: p.dtDmsPartCode
    });
  });
  return partNumbers;
}
// util to prepare payload for individual part to fetch dms data
// payload params {commonConsumerId, payType, serviceType, operationSource, vin, operationId, serviceContractProductId, serviceContractVendorId, partNumbers}
function getPayloadForCustomPart(
  rawOperation,
  make,
  selectedPayType,
  selectedServiceType,
  commonConsumerId,
  opCode,
  vin,
  serviceContractProductId,
  serviceContractVendorId,
  newPart
) {
  const operationId = rawOperation.id || rawOperation.operationId;
  const payTypeCode = selectedPayType || "";
  const params = {
    make,
    appSource: "ServiceQuoting",
    operationId: operationId || "",
    operationSource: !rawOperation.operationSource
      ? ""
      : rawOperation.operationSource,
    vin,
    payType: payTypeCode,
    serviceType: selectedServiceType,
    serviceContractProductId,
    serviceContractVendorId,
    partNumbers: partsPayload(newPart),
    commonConsumerId: commonConsumerId || "",
    opCode
  };
  return params;
}
/**
 * FIX - Util will copy {manufacturerCode} if exists in catalog menus API to new field {dtDmsPartCode} for each part within menuservice
 * @param {array} menuServices
 * @returns menuServices with parts either with dtDmsPartCode or default manufacturerCode for matched make
 */
const copydtdmsPartCodeForMenuServicePart = (menuServices, make) => {
  let newMenuServices = [];
  if (!isEmpty(menuServices)) {
    const code = getManufacturerCode(make);
    newMenuServices = menuServices.map(service => {
      const newParts = service?.part.map(part => {
        const mfrCode = get(part, "manufacturerCode", code);
        return {
          ...part,
          dtDmsPartCode: mfrCode,
          manufacturerCode: mfrCode
        };
      });
      service.part = newParts;
      return service;
    });
  }
  return newMenuServices;
};

export {
  convertMinutesToHours,
  convertArraytoString,
  formatPartsFromRawMenuService,
  deltaPartsofQuoteMenuService,
  getPayloadForCustomPart,
  isDealerTireService,
  copydtdmsPartCodeForMenuServicePart
};
